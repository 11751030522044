function formatLink(url) {
  url = url
    .toLowerCase()
    .replace("https://", "")
    .replace("http://", "")
    .replace("www.", "")
    .trim();

  if (!url.includes("medium.com")) url = url.split("/")[0];
  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }
  if (url.length > 19) {
    url = url.slice(0, 19) + "...";
  }
  return url;
}

export { formatLink };
