import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { useRef } from "react";
import {
  sendEmailLink,
  signUpWithEmailAndPassword,
  loginWithEmailAndPassword,
} from "../AuthContext";
import { signInWithEmailAndPassword, useAuth } from "../AuthContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase-config";
import { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { sendPasswordResetLink } from "../dataLayer/stroage";

function ResetPassword() {
  const navigate = useNavigate();
  const [error, setError] = useState({ show: false, message: "" });
  const [emailLinkSent, setEmailLinkSent] = useState(false);

  // Prevent body from scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const Wrapper = styled.div`
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999;

    display: flex;
    flex-direction: column;

    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 600px) {
      background: gray;
    }

    .errorMessage {
      font-size: 0.6em;
      color: #ed4337;
      margin-top: 0.2em;
      margin-bottom: 0.5em;

      @media screen and (max-width: 600px) {
        font-size: 0.9em;
      }
    }

    .emailMessage {
      font-size: 0.8em;
      color: gray;
      margin-top: 0.2em;
      @media screen and (max-width: 600px) {
        font-size: 1em;
      }
    }

    .holder {
      background: white;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 16em;
      border-radius: 10px;
      height: 80vh;
      @media screen and (max-width: 600px) {
        border-radius: 30px;
        width: auto;
        height: 100%;
        border-radius: 5px;
        width: 100%;
      }

      .top {
        padding: 1.2em 1.5em;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.1em;
      .logo {
        //font-weight: bold;
        font-weight: 200;
        font-size: 1.4em;
        @media screen and (max-width: 600px) {
          font-size: 1.7em;
        }
      }
      .close {
        font-size: 0.9em;
        color: grey;
        @media screen and (max-width: 600px) {
          font-size: 1.4em;
        }
      }
    }

    .title {
      margin: 0.1em 0em;
      font-weight: bold;
      font-size: 1.3em;
      @media screen and (max-width: 600px) {
        font-size: 1.7em;
        margin-top: 1.2em;
      }
    }

    .formType {
      font-size: 0.7em;
      font-weight: 400;

      @media screen and (max-width: 600px) {
        font-size: 1em;
      }
      a {
        text-decoration: underline;
        font-weight: 200;
        color: steelblue;
      }
    }

    form {
      margin-top: 0.5em;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        margin-top: 0.5em;
      }
      .emailInput {
        display: flex;
        flex-direction: column;
        margin-top: 1em;

        span {
          font-size: 0.7em;
          font-weight: 500;
          margin-bottom: 0.5em;
          user-select: none;
          @media screen and (max-width: 600px) {
            font-size: 1em;
          }
        }

        input {
          border: 1px solid lightgray;
          font-size: 0.7em;

          border-radius: 5px;
          padding: 0.6em 0.7em;
          margin-bottom: 0.5em;
          outline: none;

          @media screen and (max-width: 600px) {
            font-size: 1em;
          }
        }
      }
      .password {
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          right: 0.5em;
          font-size: 0.9em;
          color: gray;
          cursor: pointer;

          @media screen and (max-width: 600px) {
            font-size: 1.2em;
            top: 51%;
          }
        }
      }

      .linkdir {
        color: grey;
        font-size: 0.7em;
        @media screen and (max-width: 600px) {
          font-size: 1em;
        }
      }

      .forgot-password {
        text-align: right;
        text-decoration: underline;
        margin-bottom: 0.5em;
        font-size: 0.6em;
        cursor: pointer;
        color: black;

        @media screen and (max-width: 600px) {
          font-size: 0.9em;
        }
      }
      input[type="submit"] {
        border-radius: 8px;

        margin-bottom: 0.5em;
        background: black;
        color: white;
        font-weight: bold;
        margin-top: ${error.show ? "0.3em" : "1em"};
        outline: none;
        border: none;
        cursor: pointer;

        font-size: 0.7em;
        padding: 0.7em 0.7em;
        @media screen and (max-width: 600px) {
          font-size: 1em;
        }
      }
    }
    .footer {
      border-top: 1px solid lightgray;
      padding: 0em 1.5em;
      padding-bottom: 1em;
      p {
        font-size: 0.65em;
        color: slategray;
        padding: 0;
        margin: 0;
        margin-top: 1.5em;

        font-size: 0.55em;
        @media screen and (max-width: 600px) {
          font-size: 0.85em;
        }
      }
    }
  `;
  let emailVal = "";
  const passRef = useRef("");
  const focus = useRef("");

  const emailRef = useRef(emailVal);

  const submit = async (e) => {
    e.preventDefault();

    let email = emailRef.current;
    let password = passRef.current;
    let res = await sendPasswordResetLink(email);
    if (res.success) {
      setEmailLinkSent(true);
    } else {
      focus.current = "";

      setError({
        show: true,
        message: res.message ?? "Error sending password reset email",
      });
    }
  };

  const Input = ({ refe, type, inputId = null }) => {
    const [value, setValue] = useState(refe.current);
    return (
      <input
        type={type}
        autoFocus={focus.current == type}
        onFocus={() => {
          if (error.show) {
            focus.current = type;
            setError({ ...error, show: false });
          }
        }}
        onChange={(e) => {
          refe.current = e.target.value;
          setValue(e.target.value);
          setEmailLinkSent(false);
          focus.current = type;
        }}
        value={value}
        placeholder={type == "email" && "name@email.com"}
        id={inputId}
      ></input>
    );
  };
  function showInput() {
    try {
      var passwordInput = document.getElementById("password-input");
      var showIcon = document.getElementById("show");
      var hideIcon = document.getElementById("invis");
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        showIcon.style.display = "none"; // Hide the show icon
        hideIcon.style.display = "inline"; // Show the hide icon
      } else {
        passwordInput.type = "password";
        showIcon.style.display = "inline"; // Show the show icon
        hideIcon.style.display = "none"; // Hide the hide icon
      }
    } catch (error) {
      console.log("Error toggling password visibility:", error);
    }
  }
  return (
    <Wrapper>
      <div className="holder">
        <div className="top">
          <div className="header">
            <a className="logo">linktradr</a>
            <a className="close" href="/">
              <IoMdClose />
            </a>
          </div>
          <div className="title">Growth starts here.</div>
          <span className="formType">
            Reset your password or <a href="/login">login</a>
          </span>
          <form onSubmit={submit}>
            <div className="emailInput">
              <span>Email</span>
              <Input refe={emailRef} type="email"></Input>
            </div>
            {error.show && (
              <span className="errorMessage">{error.message}</span>
            )}
            <input type="submit" value={"Send reset link"}></input>
            {/* <span>Already </span> */}
            {emailLinkSent && (
              <span className="emailMessage">Be sure to check your spam!</span>
            )}
          </form>
        </div>
        {/* <div className="footer">
          <p>
            By joining, you agree to the Linktradr Terms of Service and to
            occasionally receive emails from us. Please read our Privacy Policy
            to learn how we use your personal data.
          </p>
        </div> */}
      </div>
    </Wrapper>
  );
}
export default ResetPassword;
