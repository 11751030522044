import styled, { keyframes } from "styled-components";
import { useEffect, useState, useRef } from "react";
import { map, tail, times, uniq, set } from "lodash";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

function SlideOut({ close = () => {}, component: Component, data = {} }) {
  const [isOpen, setIsOpen] = useState(true);

  // Prevent body from scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const Wrapper = styled.div`
    position: fixed;
    height: 100%;
    min-width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    z-index: 99999999999;
    top: 0;
    left: 0;
    transition: 0.3s;

    .frame {
      z-index: 9999999999999;
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 40vw;
      max-width: 550px;
      border-left: 1px solid lightgrey;
      //min-width: fit-content;
      background: white;
      animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.3s
        cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform: translateX(${({ isOpen }) => (isOpen ? "0" : "100%")});

      min-width: 17em;
      @media screen and (max-width: 900px) {
        max-width: 100vw;
        width: 100vw;
        min-width: auto;
        animation: none;
        transform: none;
      }
    }
  `;

  useEffect(() => {
    const closeModal = (e) => {
      let self = document.querySelector(`#slideOut`);
      let modal = document.querySelector(`.slide-out-modal`);
      let closeButton = document.querySelector(`.slide-out-close`);
      if (
        (closeButton && closeButton.contains(e.target)) ||
        (self &&
          !self.contains(e.target) &&
          self &&
          modal &&
          modal.contains(e.target))
      ) {
        document.removeEventListener("mouseup", closeModal);
        setIsOpen(false);

        setTimeout(() => {
          // console.log("REF STATE", dataRef.current.data, dataRef.current.path);
          // if (dataRef.current.data && dataRef.current.path) {
          //   let newU = JSON.parse(JSON.stringify(user));
          //   set(newU, dataRef.current.path.join("."), dataRef.current.data);
          //   setUser(newU);
          // }
          close();
        }, 300);
      }
    };

    document.addEventListener("mouseup", closeModal);

    return () => {
      document.removeEventListener("mouseup", closeModal);
    };
  }, []);

  return (
    // <div
    //   style={{
    //     position: "fixed",
    //     height: "100vh",
    //     width: "100%",
    //     background: "rgba(0,0,0,0.2)",
    //     zIndex: 99999999999,
    //     top: 0,
    //     left: 0,
    //   }}
    //   id="slide-out-modal"
    // >
    <Wrapper className="slide-out-modal" isOpen={isOpen}>
      <div className="frame" id="slideOut">
        <Component data={data} />
      </div>
    </Wrapper>
    // </div>
  );
}

export default SlideOut;
