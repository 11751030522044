import logo from "./logo.svg";
import "./App.css";
import Hero from "./Hero/Hero";
import Market from "./Market/Market";
import Search from "./Search/Search";
import CreateOffering from "./Forms/CreateOffering";
import SeeYouSoon from "./Forms/SeeYouSoon";
import MySite from "./MySite/MySite";
import { marketLoader } from "./Market/Market";
import { mySiteLoader } from "./MySite/MySite";
import { searchLoader } from "./Search/Search";
import {
  BrowserRouter,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
function App() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          index
          element={<Hero></Hero>}
          // errorElement={<Navigate to="/signup" />}
        ></Route>
        <Route
          path="/market"
          element={<ProtectedRoute component={Market} />}
          loader={marketLoader}
          // errorElement={<Navigate to="/mysite" />}
        />
        <Route
          exact
          path="/login"
          element={<Hero></Hero>}
          // errorElement={<Navigate to="/" />}
        ></Route>
        <Route
          exact
          path="/signup"
          element={<Hero></Hero>}
          // errorElement={<Navigate to="/" />}
        ></Route>
        <Route
          path="/reset-password"
          element={<Hero></Hero>}
          // errorElement={<Navigate to="/login" />}
        ></Route>
        <Route
          exact
          path="/finish-signup"
          element={<Hero></Hero>}
          // errorElement={<Navigate to="/" />}
        ></Route>
        <Route
          exact
          path="/send-email-link"
          element={<Hero></Hero>}
          // errorElement={<Navigate to="/" />}
        ></Route>
        <Route
          path="/search/:term"
          loader={searchLoader}
          element={<ProtectedRoute component={Search} />}
          // errorElement={<Navigate to="/market" />}
        ></Route>
        <Route
          path="/search"
          loader={searchLoader}
          element={<ProtectedRoute component={Search} />}
          // errorElement={<Navigate to="/market" />}
        ></Route>

        {/* <Route exact path="/mysite" element={<MySite></MySite>}></Route>
        <Route exact path="/mysite/:page" element={<MySite></MySite>}></Route> */}

        <Route
          path="/mysite"
          element={<ProtectedRoute component={MySite} />}
          loader={mySiteLoader}
          // errorElement={<Navigate to="/market" />}
        />

        <Route
          path="/mysite/:page"
          element={<ProtectedRoute component={MySite} />}
          loader={mySiteLoader}
          // errorElement={<Navigate to="/mysite" />}
        />

        <Route
          exact
          path="/briefs"
          element={<CreateOffering></CreateOffering>}
          // errorElement={<Navigate to="/" />}
        ></Route>
        <Route
          exact
          path="/newbeta"
          element={<SeeYouSoon></SeeYouSoon>}
          // errorElement={<Navigate to="/" />}
        ></Route>
      </Route>
    )
  );

  return (
    <AuthProvider>
      <div className="App">
        <header className="App-header">
          <RouterProvider router={router} />
        </header>
      </div>
    </AuthProvider>
  );
}

export default App;
