import styled from "styled-components";
import { FiSearch } from "react-icons/fi";
import { FaRegBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { useState, useRef, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserDataContext } from "../ProtectedRoute";
function BHeader({ market = true, menu = false }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const searchInputRef = useRef("");
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let term = searchParams.get("term");
  const Wrapper = styled.div`
    width: 100%;
    color: rgb(12, 65, 39);
    border-bottom: 0.5px solid lightgrey;

    a {
      text-decoration: none;
      color: inherit;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.8em 3.5%;

      @media screen and (max-width: 950px) {
        flex-direction: column-reverse;
        width: 93%;
      }
    }

    .logo {
      font-size: 1.2em;
      //font-weight: bold;
      font-weight: 200;
      color: rgb(12, 65, 39);
      text-decoration: none;
      display: flex;
      align-items: center;

      @media screen and (max-width: 950px) {
        display: none;
      }

      .beta-tag {
        font-size: 0.5em;
        margin-left: 0.5em;
        color: white;
        background: rgb(28, 191, 115);
        padding: 0.1em 0.6em;
        border-radius: 25px;
        font-weight: 500;
        margin-top: 0.5em;
      }
    }

    .leftHeader {
      display: flex;
      width: 80%;

      @media screen and (max-width: 950px) {
        width: 100%;
        margin-left: 0;
      }
      align-items: center;
      .inputSection {
        width: 60%;
        border: 1px solid grey;
        margin-left: 1.5em;
        border-radius: 3px;

        @media screen and (max-width: 950px) {
          width: 100%;
          margin-left: 0;
          margin-top: 1em;
        }

        div {
          position: relative;
          display: flex;
          align-items: center;
          input {
            width: 100%;
            font-size: 0.7em;
            padding: 0.5em 1em;
            border-radius: 50px;
            outline: none;
            border: none;
            @media screen and (max-width: ${"500px"}) {
              font-size: 16px;
            }
          }
          input::placeholder {
            color: lightgrey;
          }
          .search {
            cursor: pointer;
            position: absolute;
            background: rgb(28, 191, 115);
            height: 100%;
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 0.75em;
            top: 0em;
            right: 0em;
            display: grid;
            color: white;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
      }
    }
    .rightHeader {
      display: flex;
      align-items: center;
      justify-content: end;
      white-space: nowrap;
      width: 20%;

      @media screen and (max-width: 950px) {
        justify-content: space-between;
        width: 100%;
      }

      .sub-left {
        display: none;
        width: fit-content;

        .sub-logo {
          display: block;
        }

        @media screen and (max-width: 950px) {
          display: block;
        }
      }

      .sub-right {
        display: flex;
        align-items: center;
        justify-content: end;
        white-space: nowrap;
        width: 20%;
        @media screen and (max-width: 950px) {
          flex-direction: row-reverse;
        }

        .menuHolder {
          position: relative;

          .menu {
            min-height: 1.2em;
            min-width: 1.2em;
            font-size: 1.2em;
            margin-left: 1em;
            display: none;
            cursor: pointer;
            @media screen and (max-width: 950px) {
              display: block;
            }
          }

          .optionsHolder {
            position: absolute;
            background: white;
            top: calc(100% + 0.2em);
            width: fit-content;
            right: 0;
            z-index: 999999;
            border: 1px solid grey;

            border-radius: 5px;

            display: flex;
            flex-direction: column;

            .nav {
              justify-content: center;
              padding: 0.3em 0;
              margin: 0 0.4em;
              /* width: 100%; */

              cursor: pointer;
              display: flex;
              align-items: center;
              color: black;
              font-weight: 400;
              font-size: 0.7em;

              @media screen and (max-width: 500px) {
                font-size: 0.9em;
              }
            }
            .option:hover {
              background: rgba(28, 191, 115, 0.4);
            }
          }
        }
      }

      .notifications {
        margin-right: 1em;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .signIn {
        font-size: medium;
        font-weight: medium;
        margin-right: 1.5em;
        @media screen and (max-width: 950px) {
          display: none;
        }
      }

      .join {
        font-size: medium;
        border: 1px solid rgb(12, 65, 39);
        padding: 0.5em 1em;
        border-radius: 3px;
        font-weight: bold;
      }
    }
  `;
  return (
    <Wrapper>
      <div className="header">
        <div className="leftHeader">
          <a href="/market" className="logo">
            linktradr
            <span className="beta-tag">Beta</span>
          </a>
          <div className="inputSection" id="inputSection">
            <div>
              <input
                placeholder="What are you looking for today?"
                onChange={(e) => {
                  searchInputRef.current = e.target.value;
                  console.log(searchInputRef.current);
                }}
                defaultValue={term ?? ""}
              />
              <a
                // href={`/search?term=${encodeURIComponent(
                //   searchInputRef.current
                // )}`}
                onClick={() =>
                  navigate(
                    `/search?term=${encodeURIComponent(searchInputRef.current)}`
                  )
                }
                className="search"
              >
                <FiSearch />
              </a>
            </div>
          </div>
        </div>
        <div className="rightHeader" id="rightHeader">
          <div className="sub-left">
            <a href="/market" className="logo sub-logo">
              linktradr
              <span className="beta-tag">Beta</span>
            </a>
          </div>
          <div className="sub-right">
            {/* <a className="notifications">
              <FaRegBell />
            </a> */}

            <a className="signIn" href="/mysite/offers">
              Offers
            </a>

            <div className="menuHolder" onClick={() => setMenuOpen(!menuOpen)}>
              {menu && <IoMenuOutline className="menu" />}
              {menuOpen && (
                <div className="optionsHolder menu-options">
                  <a className="nav" href="/mysite">
                    Home
                  </a>
                  <a className="nav" href="/mysite/offers">
                    Offers
                  </a>
                  <a className="nav" href="/mysite/settings">
                    Settings
                  </a>
                  {/* <a
              className="nav"
              href="/mysite/billing"
              style={{ color: page == "billing" && "black" }}
            >
              Billing
            </a> */}
                  <a className="nav" href="/mysite/messages">
                    Messages
                  </a>
                  <a className="nav" href="/mysite/connects">
                    Connects
                  </a>
                  <a className="nav" href="/mysite/your-score">
                    Your Score
                  </a>
                </div>
              )}
            </div>
            <a href={market ? "/market" : "/mysite"} className="join">
              {market ? "Market" : "My Site"}
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
export default BHeader;
