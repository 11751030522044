import styled from "styled-components";
import { useState, useContext } from "react";
import { FaCheck } from "react-icons/fa";
import { respondToOffer } from "../dataLayer/stroage";
import { IoIosMore, IoMdClose, IoMdSwap } from "react-icons/io";

import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import { TbProgressCheck } from "react-icons/tb";
import { AiOutlineStop } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import { formatLink } from "../Global/utils";

import {
  Status,
  StatusDisplayRef,
  allContentTypes,
  getLabel,
} from "../dataLayer/stroage";

import SlideOut from "../Global/SlideOut";
import Details from "./Details";
import { UserDataContext } from "../ProtectedRoute";
const Trades = ({ setSlideOut }) => {
  const { user, activeURL } = useContext(UserDataContext);
  const site = user.websites[activeURL];
  const Wrapper = styled.div`
    text-align: left;
    /* padding: 1em 0em; */
    /* font-size: 0.75em; */

    h1 {
      text-align: left;
      margin: 0;
      padding: 0;
      font-size: 1.1em;
      font-weight: 600;
    }

    .tradeHolder {
      font-size: 0.75em;
      border-top: 1px solid lightgray;
      margin-top: 0.15em;
      /* padding: 0 1em; */
    }

    .no-offers-yet {
      margin-top: 0.5em;
      span {
        color: gray;
        font-size: 0.8em;
      }
    }
  `;

  const Offer = ({ trade }) => {
    const [slideOut, setSlideOut] = useState({ show: false, data: trade });
    let isSender = trade.sender == user.userId;
    let sender = trade[trade.sender];
    let reciver = trade[trade.reciver];
    let self_data = isSender ? sender : reciver;
    let other_data = isSender ? reciver : sender;

    const OfferCard = styled.div`
      font-size: 1.4em;
      cursor: default;
      .trade {
        width: 100%;
        margin-top: 0.6em;
        border-radius: 5px;
        .holder {
          padding: 0.2em 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .url {
            font-size: 0.7em;
            margin: 0em;
            padding: 0;
            font-weight: 400;
            width: 25%;
            overflow-x: hidden;

            @media screen and (max-width: 550px) {
              width: 25%;
            }
          }

          .type {
            width: 10%;
            white-space: nowrap;
            margin-right: 0.6em;
            margin-left: 1.5em;

            font-weight: 500;
            font-size: 0.6em;
            color: grey;
          }

          .date {
            width: 10%;
            font-size: 0.7em;
            margin: 0em;
            padding: 0;
            font-weight: 400;
            min-width: fit-content;
          }

          .swap {
            width: 10%;
            display: flex;
            justify-content: center;
          }

          .status {
            width: 10%;
            display: flex;
            justify-content: center;
            div {
              white-space: nowrap;
              padding: 0.2em 0.6em;
              color: white;
              /* background: rgb(254, 199, 51); //rgba(28, 191, 115, 1); */
              border-radius: 15px;
              margin-right: 0;
              min-width: fit-content;
              display: flex;
              justify-content: center;
              @media screen and (max-width: 550px) {
                width: 1em;
                height: 1em;
                padding: 0;
              }
              svg {
                font-size: 0.9em;

                @media screen and (max-width: 550px) {
                  font-size: 0.7em;
                }
              }
            }

            .big {
              font-size: 1.05em;
              @media screen and (max-width: 550px) {
                font-size: 0.85em;
              }
            }
            span {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              /* @media screen and (max-width: 550px) {
                  display: none;
                } */
            }
          }

          .more {
            width: 5%;
            cursor: pointer;
          }
        }
      }
    `;

    return (
      <>
        {slideOut.show && (
          <SlideOut
            component={Details}
            close={async () => {
              setSlideOut({ show: false });
            }}
            data={{
              trade: { ...slideOut.data },
              site: site,
              close: () => {
                setSlideOut({ show: false });
              },
            }}
          />
        )}
        <OfferCard>
          <div className="trade">
            <div className="holder">
              <h1 className="url">{formatLink(other_data.url)}</h1>
              <span className="type">
                {getLabel(allContentTypes, other_data.create_content_type)}
              </span>
              {/* <span className="date">
                {new Date(
                  info.time._seconds * 1000 + info.time._nanoseconds / 1e6
                )
                  .toDateString()
                  .slice(4, -4)}
              </span> */}

              <span className="status">
                <div>
                  <span>{StatusDisplayRef[other_data.status].icon}</span>
                </div>
              </span>
              <div className="swap">
                <div>
                  <IoMdSwap />
                </div>
              </div>
              <span className="type">
                {getLabel(allContentTypes, self_data.create_content_type)}
              </span>
              <span className="status">
                <div>
                  <span>{StatusDisplayRef[self_data.status].icon}</span>
                </div>
              </span>

              <IoIosMore
                className="more"
                onClick={() => setSlideOut({ show: true, data: trade })}
              />
            </div>
          </div>
        </OfferCard>
      </>
    );
  };

  let tradeList = [];

  const toDateObj = (time) =>
    new Date(time._seconds * 1000 + time._nanoseconds / 1e6);

  if (user.offers) {
    tradeList = Object.keys(user.offers)
      .sort(
        (a, b) =>
          toDateObj(user.offers[b].time) - toDateObj(user.offers[a].time)
      )
      .filter((key) => user.offers[key].status == Status.Accepted)
      .filter((key) => user.offers[key].status != Status.Trash)
      .filter((key) => user.offers[key][user.userId].url == activeURL);
  }

  return (
    <Wrapper>
      <h1>Trades</h1>

      <div className="tradeHolder">
        {user.offers &&
          tradeList.map(
            (id) => user.offers[id].status && <Offer trade={user.offers[id]} />
          )}
      </div>

      {(!user.offers || tradeList.length === 0) && (
        <div className="no-offers-yet">
          <span>No trades yet, get the ball rolling by sending an offer!</span>
        </div>
      )}
    </Wrapper>
  );
};

export default Trades;
