import styled, { css } from "styled-components";
import { useMemo, useState, useRef, isValidElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { addBetaUser } from "../dataLayer/stroage";
import { transform } from "framer-motion";

import { motion } from "framer-motion";
function SeeYouSoon() {
  const Wrapper = styled.div`
    background: white;
    color: rgb(64, 65, 69);

    .bottom {
      display: flex;
      align-items: start;
      // padding: 0 1.3em;
      width: 100%;

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100vh - 6.5em);
      justify-content: center;
      .leftSide {
        cursor: default;
        width: 100%;
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0.7em;
        h1 {
          font-size: 1.75em;
          @media screen and (max-width: 800px) {
            font-size: 1.7em;
            margin-bottom: 0.3em;
          }
        }

        span {
          font-size: 0.9em;
          color: grey;
          margin-bottom: 0.7em;
          @media screen and (max-width: 800px) {
            font-size: 1em;
          }
        }

        div {
          text-decoration: underline;
          //color: green;
          margin-top: 0.2em;
          font-size: 0.8em;
        }
      }
    }
  `;

  const Header = styled.div`
    margin: 0 2.5%;
    .header {
      display: flex;
      justify-content: space-between;
      padding: 0.9em 0.2em;
      border-bottom: 1px solid lightgrey;
      align-items: center;
      .title {
        font-weight: 200;
        font-size: 1.2em;
      }
      .exit {
        font-size: 0.7em;
        color: rgb(64, 65, 69);
        text-decoration: none;
      }
    }
  `;

  const Footer = styled.div`
    margin: 0 2.5%;
    .footer {
      display: flex;
      justify-content: end;
      padding: 0.47em 0.2em;
      border-top: 1px solid lightgrey;
      align-items: center;
      cursor: pointer;
      .continue {
        text-decoration: none;
        font-size: 0.7em;
        color: white;
        background: rgb(34, 35, 37);
        padding: 0.5em 1em;
        border-radius: 3px;
      }
    }
  `;

  const Image = styled.div`
    position: relative;
    max-width: 700px;
    scale: 0.9;
    @media screen and (max-width: 880px) {
      display: none;
    }
  `;

  const UIArticle = ({ color, left, top, scale, step, nextLeft, nextTop }) => {
    const [reload, setReload] = useState(true);
    const UI = styled(motion.div)`
      /* scale: ${0.2}; */
      /* filter: blur(0.5px); */
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
      position: absolute;

      left: 50%;
      top: 50%;

      left: ${left};
      top: ${top};

      @media screen and (max-width: 1200px) {
        left: ${nextLeft};
        top: ${nextTop};
      }
      /* width: 175px; */
      width: 200px;
      height: fit-content;
      background: white;
      border-radius: 10px;
      /* scale: 0.7; */
      color: ${(props) => props.theme.text};
      padding: 0;
      margin: 0;

      #overlay {
        width: 90%;
        height: 100%;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ${(props) =>
          props.color &&
          css`
            background-color: rgba(
              ${props.color[0]},
              ${props.color[1]},
              ${props.color[2]},
              0.3
            );
            border-color: gray;
            cursor: not-allowed;
          `}
      }

      .photo {
        height: 100px;
        width: 100%;
        background: white;
        border-radius: 10px;
        margin-bottom: 5px;

        ${(props) =>
          props.color &&
          css`
            background-color: rgba(
              ${props.color[0]},
              ${props.color[1]},
              ${props.color[2]},
              0.9
            );
            border-color: gray;
            cursor: not-allowed;
          `}
      }

      .line {
        height: 15px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 25px;
        margin: 5px;
      }

      .text {
        width: 100%;

        .pubHolder {
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;
          #pubPhoto {
            width: 20px;
            ${(props) =>
              props.color &&
              css`
                background-color: rgba(
                  ${props.color[0]},
                  ${props.color[1]},
                  ${props.color[2]},
                  0.5
                );
                border-color: gray;
                cursor: not-allowed;
              `}
          }
          #publisher {
            width: 100%;
            ${(props) =>
              props.color &&
              css`
                background-color: rgba(
                  ${props.color[0]},
                  ${props.color[1]},
                  ${props.color[2]},
                  0.5
                );
                border-color: gray;
                cursor: not-allowed;
              `}
          }
        }
        #line1 {
          width: 90%;
        }
        #line2 {
          width: 80%;
        }
        #line3 {
          width: 60%;
        }
      }
    `;
    /* useEffect(() => {
    setTimeout(() => {
      setReload(!reload);
    }, 2000 * step);
  }); */

    return (
      <UI
        color={color}
        initial={{ scale: scale - 0.2 }} //left: "40%", top: "30%" }}
        animate={{ scale }}
        transition={{
          repeat: Infinity,
          duration: 2 + step,
          ease: "easeOut",
          type: "spring",
          repeatType: "mirror",
          delay: 2,
        }}

        /* initial={{ opacity: 0, scale: scale - 0.2 }}
      animate={{ opacity: 1, scale }}
      transition={{
        duration: 0.1 + step,
        ease: [0, 0.71, 0.2, 1.01],
        scale: {
          type: "spring",
          damping: 5,
          stiffness: 100,
          restDelta: 0.001,
          delay: 3,
          repeatType: "mirror",
          ease: "easeOut",
          repeat: Infinity,
        },
      }} */
        /* transition={{
        
        ease: "easeOut",
        type: "spring",
        duration: 3,
      }} */
      >
        <div id={"overlay"}>
          {/* <span>{step}</span> */}
          <div className="photo"></div>
          <div className="text">
            <div className="pubHolder">
              <div id={"pubPhoto"} className="line"></div>
              <div id={"publisher"} className="line"></div>
            </div>
            <div id={"line1"} className="line"></div>
            <div id={"line2"} className="line"></div>
            <div id={"line3"} className="line"></div>
          </div>
        </div>
      </UI>
    );
  };

  return (
    <Wrapper>
      <Header>
        <div className="header">
          <span className="title">linktradr</span>
          <a className="exit" href={"/"}>
            Exit
          </a>
        </div>
      </Header>
      <div className="bottom">
        <div className="leftSide">
          <h1>
            See You Soon! <br></br> Launching in March 2024
          </h1>
          <span>We will contact you with matches</span>
        </div>
      </div>
      <Footer>
        <div className="footer">
          <a className="continue" href="/">
            Home
          </a>
        </div>
      </Footer>
    </Wrapper>
  );
}

export default SeeYouSoon;
