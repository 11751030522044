// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./firebase-config";
import {
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  browserSessionPersistence,
  setPersistence,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { byEmail, createUser } from "./dataLayer/stroage";
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export async function sendEmailLink(email) {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: "https://linktradr.com/auth?email=true'",
    handleCodeInApp: true,
  };
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
    return { status: "success", message: "" };
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function signUpWithEmailAndPassword(email, password) {
  try {
    let res = await createUser(email, password);
    return res;
  } catch (error) {
    return { status: false, message: "Error creating user" };
  }
}
export async function loginWithEmailAndPassword(email, password) {
  try {
    let user = await signInWithEmailAndPassword(auth, email, password);
    sessionStorage.setItem("uid", user.user.uid);
    return { status: true, message: "" };
  } catch (error) {
    console.error(error);
    return { status: false, message: error.message };
  }
}
export async function userExist(email) {
  try {
    return (await fetchSignInMethodsForEmail(auth, email)).length > 0;
  } catch (error) {
    console.error(error);
    return false;
  }
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        sessionStorage.setItem("uid", user.uid);
      }
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
}
