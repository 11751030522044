import { styled } from "styled-components";
import { RiCopperCoinFill } from "react-icons/ri";
import defaultLogo from "./logo.svg";
import TradeTerms from "./Trade/TradeTerms";
import { useState } from "react";
import SlideOut from "./Global/SlideOut";
const Post = ({ postId, info, pointer = true }) => {
  let postData = info;
  const [showTerms, setShowTerms] = useState(false);
  let Wrapper = styled.div`
    pointer-events: ${!pointer && "none"};
    .post {
      display: flex;
      flex-direction: column;
      padding: 5%;
      background: white;
      color: rgb(12, 65, 39);
      border-radius: 10px;
      text-align: left;
      position: relative;
      border: 0.5px solid lightgrey;
      cursor: default;
      .type {
        font-size: medium;
        margin-bottom: 0.1em;
      }

      .scoreHolder {
        display: flex;
        align-items: end;
        margin-bottom: 0em;
        .score {
          font-size: xxx-large;
          font-weight: bold;
          margin: 0;
          padding: 0;
        }
        .label {
          font-size: small;
          margin-left: 0.3em;
          padding-bottom: 1em;
        }
      }
      .link {
        white-space: nowrap;
        font-size: large;
        font-weight: bold;
        color: rgb(12, 65, 39);
        margin-bottom: 0.5em;
        display: block;
      }
      .trade {
        border: 1px solid rgba(12, 65, 39, 0.6);
        font-size: small;
        text-align: center;
        border-radius: 20px;
        padding: 2%;
        font-weight: bold;
        display: none;
      }

      .description {
        font-size: medium;
        margin-bottom: 1em;
        min-height: 2.8em;
        max-height: 2.8em;
        overflow-y: hidden;
        /* max-height: 2.8em; */
        /* overflow-y: scroll; */
      }
      .connects {
        right: 0.5em;
        font-size: medium;
        border: 1px solid rgba(28, 191, 115, 0.8);
        padding: 0.3em 0.4em;
        border-radius: 8px;
        font-weight: bold;
        color: rgba(28, 191, 115, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
          display: grid;
          margin-left: 0.3em;
          font-size: medium;
        }
      }

      .logo {
        position: absolute;
        right: 5%;
        height: 35px;
        width: auto;
        object-fit: contain;
      }
    }
  `;

  let score = Math.ceil(postData.score) ?? 0;
  let color = "black";
  if (score < 20) {
    color = "#E32636";
  } else if (score < 50) {
    color = "#FF7518"; //"#F28C28";
  } else if (score < 70) {
    color = "#F4C430";
  } else {
    color = "#1CAC78";
  }

  function formatLink(url) {
    try {
      url = url
        .toLowerCase()
        .replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .trim();

      if (!url.includes("medium.com")) url = url.split("/")[0];
      if (url.endsWith("/")) {
        url = url.slice(0, -1);
      }
      if (url.length > 19) {
        url = url.slice(0, 19) + "...";
      }
      return url;
    } catch {
      return url;
    }
  }

  //console.log(postData);
  return (
    <Wrapper>
      {showTerms && (
        <SlideOut
          component={TradeTerms}
          close={() => {
            setShowTerms(false);
          }}
          data={{
            ...postData,
            close: () => {
              setShowTerms(false);
            },
          }}
        />
      )}
      <div className="post" id={postId}>
        <span className="type">{postData.category}</span>
        <div className="scoreHolder">
          <span className="score" style={{ color }}>
            {score ?? 40 + Math.floor(Math.random() * 60)}
          </span>
          <span className="label">Authority</span>
        </div>
        <span className="link">{formatLink(postData.url).toLowerCase()}</span>
        {/* <>{postData.userId}</> */}
        <span className="trade">Trade</span>
        <span className="description">
          {postData.description
            ? postData.description.slice(0, 100) +
              (postData.description.length > 100 ? "..." : "")
            : "No description"}
        </span>
        <img
          className="logo"
          src={
            postData.icon
              ? !postData.icon.startsWith("https://")
                ? "/logos/" + postData.icon + ".png"
                : postData.icon
              : "https://linktradr.com/favicon.ico"
          }
        />
        <span
          className="connects"
          onClick={() => {
            setShowTerms(true);
          }}
        >
          Trade for {postData.connects}
          <div className="icon">
            <RiCopperCoinFill />
          </div>
        </span>
      </div>
    </Wrapper>
  );
};

export default Post;
