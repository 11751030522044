import React, { useEffect, useState, createContext } from "react";
import { Navigate, useLoaderData } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { auth } from "./firebase-config";
import { getUser } from "./dataLayer/stroage";
import VerifySite from "./Global/VerifySite";

export const UserDataContext = createContext({
  user: null,
  setUser: (user) => {},
  activeURL: null,
  setActiveUrl: (url) => {},
});

function ProtectedRoute({ component: Component }) {
  const loaderData = useLoaderData();
  console.log("loaderData", loaderData);
  const [user, setUser] = useState(null);
  const [authResolved, setAuthResolved] = useState(false);
  const [userData, setUserData] = useState(loaderData.userInfo);
  const [activeURL, setActiveUrlState] = useState(null);

  const setActiveUrl = (url) => {
    sessionStorage.setItem("activeURL", url);
    setActiveUrlState(url);
  };
  if (!activeURL && userData) {
    if (sessionStorage.getItem("activeURL")) {
      setActiveUrl(sessionStorage.getItem("activeURL"));
    } else {
      setActiveUrlState(Object.keys(userData.websites)[0]);
    }
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setAuthResolved(true);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  console.log("loader data1", loaderData);

  if (!activeURL) {
    return null;
  }
  if (!authResolved) {
    return null;
  }
  if (!user) {
    return <Navigate to="/login" />;
  }
  if (!loaderData.userInfo) {
    return <Navigate to="/login" />;
  }

  console.log("loader data2", loaderData);
  return (
    <UserDataContext.Provider
      value={{
        user: userData,
        setUser: setUserData,
        activeURL: activeURL,
        setActiveUrl,
      }}
    >
      <Component data={loaderData} />
    </UserDataContext.Provider>
  );
}

export default ProtectedRoute;
