import styled from "styled-components";
import { MdOutlineClose } from "react-icons/md";
function VerifySite() {
  const Wrapper = styled.div`
    width: 100%;
    //background: rgb(28, 191, 115);
    background: white;
    .holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.2em 0.5em;
      padding-right: 0.2em;
      margin: 0 3%;
      margin-top: 0.5em;
      border: 1px solid lightgray;
      border-radius: 5px;
    }

    span,
    a {
      font-size: 0.65em;
      color: black;
    }

    .left-holder {
      display: flex;
      align-items: center;
    }

    .button {
      /* background: green;
      font-size: 0.6em;
      border-radius: 25px;
      color: white;
      padding: 0.1em 0.6em;
      margin-left: 1em; */
      text-decoration: underline;
      color: green;
      margin-left: 0.5em;
    }
    svg {
      font-size: 0.8em;
      color: grey;
      margin-left: 0.5em;
    }
  `;

  return (
    <Wrapper>
      <div className="holder">
        <div className="left-holder">
          <span className="text">
            Verify your website before you can trade.
          </span>
          <a className="button" href="/mysite/verify">
            Get verified
          </a>
        </div>
        <MdOutlineClose />
      </div>
    </Wrapper>
  );
}
export default VerifySite;
