import styled from "styled-components";
import { useState, useContext } from "react";
import { FaCheck } from "react-icons/fa6";
import { respondToOffer } from "../dataLayer/stroage";
import { IoIosMore, IoMdClose } from "react-icons/io";
import { formatLink } from "../Global/utils";
import { UserDataContext } from "../ProtectedRoute";
import {
  Status,
  StatusDisplayRef,
  allContentTypes,
  getLabel,
} from "../dataLayer/stroage";
import Details from "./Details";
import SlideOut from "../Global/SlideOut";
const Offers = () => {
  const { user, activeURL } = useContext(UserDataContext);
  const site = user.websites[activeURL];
  let offerList = [];

  const toDateObj = (time) =>
    new Date(time._seconds * 1000 + time._nanoseconds / 1e6);

  if (user.offers) {
    offerList = Object.keys(user.offers)
      .sort(
        (a, b) =>
          toDateObj(user.offers[a].time) - toDateObj(user.offers[b].time)
      )
      .filter((key) => user.offers[key].status != Status.Accepted)
      .filter((key) => user.offers[key].status != Status.Trash)
      .filter((key) => user.offers[key][user.userId].url == activeURL);
  }
  const Wrapper = styled.div`
    text-align: left;
    /* padding: 1em 0em; */
    /* font-size: 0.75em; */

    h1 {
      text-align: left;
      margin: 0;
      padding: 0;
      font-size: 1.1em;
      font-weight: 600;
    }

    .tradeHolder {
      font-size: 0.75em;
      border-top: 1px solid lightgray;
      margin-top: 0.15em;
      /* padding: 0 1em; */
    }

    .no-offers-yet {
      margin-top: 0.5em;
      span {
        color: gray;
        font-size: 0.8em;
      }
    }
  `;
  const Offer = ({ trade, id }) => {
    const [status, setStatus] = useState(trade.status);
    const [slideOut, setSlideOut] = useState({ show: false, data: trade });
    const OfferCard = styled.div`
      font-size: 1.4em;
      .trade {
        cursor: default;
        width: 100%;
        margin-top: 0.6em;
        border-radius: 5px;
        .holder {
          padding: 0.2em 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 550px) {
          }

          .date {
            width: 10%;
            font-size: 0.7em;
            margin: 0em;
            padding: 0;
            font-weight: 400;
            min-width: fit-content;
            display: block;
            @media screen and (max-width: 550px) {
              display: none;
              font-size: 0.8em;
            }
          }

          .url {
            font-size: 0.7em;
            margin: 0em;
            padding: 0;
            font-weight: 400;
            width: 30%;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            //border: 1px solid red;

            @media screen and (max-width: 550px) {
              width: 40%;
              font-size: 0.9em;
            }

            .date {
              display: none;
              //border: 1px solid brown;
              @media screen and (max-width: 550px) {
                display: block;
              }
            }
          }

          .type {
            //border: 1px solid blue;
            width: 15%;
            white-space: nowrap;
            margin-right: 0.6em;
            margin-left: 1.5em;

            font-weight: 500;
            font-size: 0.6em;
            color: grey;

            @media screen and (max-width: 550px) {
              width: 20%;
              font-size: 0.8em;
              margin: 0;
              font-weight: 400;
            }
          }

          .status {
            width: 15%;
            display: flex;
            justify-content: center;
            //border: 1px solid green;
            /* border: 1px solid lightgray; */

            @media screen and (max-width: 550px) {
              width: fit-content;
            }
            .status-span {
              cursor: default;
              display: flex;
              align-items: center;
              font-size: 0.65em;
              border-radius: 25px;
              // margin: 0.15em 0.5em;
              width: fit-content;
              /* min-width: 60px;
              max-width: 80px; */
              text-align: center;
              color: black;
              font-weight: 400;
              justify-content: center;
              /* 
              @media screen and (max-width: 550px) {
                max-width: 45px;
                min-width: 45px;
              } */
              padding: 0.3em 0.7em;
              color: white;
              @media screen and (max-width: 900px) {
                padding: 0.35em;
              }
              svg {
                font-size: 1.2em;
                margin-right: 0.5em;
                @media screen and (max-width: 900px) {
                  margin: 0;
                }

                @media screen and (max-width: 550px) {
                  font-size: 1.5em;
                }
              }
              .small {
                font-size: 1em;
                @media screen and (max-width: 550px) {
                  font-size: 1.4em;
                }
              }

              span {
                font-size: 1em;
                font-weight: 500;
                @media screen and (max-width: 900px) {
                  display: none;
                }
              }
            }

            .double {
              justify-content: space-between;
              svg {
                font-size: 1.5em;
              }
            }
            div {
              display: flex;
              font-size: 0.6em;
              white-space: nowrap;
              min-width: fit-content;
              /* min-width: 72px; */
              font-weight: 200;
              width: 80px;
              // min-width: 80px;

              justify-content: ${status == Status.Pending
                ? "space-between"
                : "center"};

              max-width: 80px;
            }

            .accept {
              font-weight: 200;
              background: ${StatusDisplayRef[Status.Accepted].color};
            }
            .decline {
              margin-left: ${status == Status.Declined ? "0px" : "0.5em"};
              background: #ed4337;
            }
          }

          .more {
            width: 5%;
            cursor: pointer;
            @media screen and (max-width: 550px) {
              width: 10%;
              font-size: 1.3em;
            }
          }
        }
      }
    `;

    let isSender = trade.sender == user.userId;
    let sender = trade[trade.sender];
    let reciver = trade[trade.reciver];
    let self_data = isSender ? sender : reciver;
    let other_data = isSender ? reciver : sender;

    return (
      <>
        {slideOut.show && (
          <SlideOut
            component={Details}
            close={async () => {
              setSlideOut({ show: false });
            }}
            data={{
              trade: { ...slideOut.data },
              site: site,
              close: () => {
                setSlideOut({ show: false });
              },
            }}
          />
        )}
        {trade[user.userId].status == Status.Trash ? (
          <></>
        ) : (
          <OfferCard>
            <div className="trade">
              <div className="holder">
                <h1 className="url">{formatLink(other_data.url)}</h1>
                <span className="type">
                  {getLabel(allContentTypes, other_data.create_content_type)}
                </span>
                <span className="date">
                  {new Date(
                    trade.time._seconds * 1000 + trade.time._nanoseconds / 1e6
                  )
                    .toDateString()
                    .slice(4, -4)}
                </span>

                <span className="status">
                  {(() => {
                    let s =
                      !isSender && trade.status == Status.Pending
                        ? Status.NoResponse
                        : trade.status;
                    return (
                      <span
                        className="status-span"
                        style={{
                          background: StatusDisplayRef[s].color,
                        }}
                      >
                        {StatusDisplayRef[s].icon_no_color}
                        <span>{StatusDisplayRef[s].label_past_tense}</span>
                      </span>
                    );
                  })()}
                </span>
                <IoIosMore
                  className="more"
                  onClick={() =>
                    setSlideOut({ show: true, data: { ...trade, id } })
                  }
                />
              </div>
            </div>
          </OfferCard>
        )}{" "}
      </>
    );
  };

  return (
    <Wrapper>
      <h1>Offers</h1>

      <div className="tradeHolder">
        {user.offers &&
          offerList.map((key) => <Offer trade={user.offers[key]} id={key} />)}
      </div>

      {(!user.offers || offerList.length === 0) && (
        <div className="no-offers-yet">
          <span>No offers yet, throw your hat in the ring!</span>
        </div>
      )}
    </Wrapper>
  );
};

export default Offers;
