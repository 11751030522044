// firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth, browserSessionPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCgmWG0zwDREuT9MdsE-OJbydyjH7Buv6E",
  authDomain: "linktradr.firebaseapp.com",
  projectId: "linktradr",
  storageBucket: "linktradr.appspot.com",
  messagingSenderId: "569040262053",
  appId: "1:569040262053:web:958fc3f9a950db2873ce4c",
  measurementId: "G-F3Z8LBJ2KF",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.setPersistence(browserSessionPersistence);
const db = getFirestore(app);
export { auth, db };
