import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import { RiCopperCoinFill } from "react-icons/ri";
import { FaAngleDown } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { UserDataContext } from "../ProtectedRoute";
const Wrapper = styled.div`
  margin-right: 2.5%;
  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
  .section {
    display: flex;
    flex-direction: column;
    margin: 1.3em 0;

    .title {
      font-size: 0.8em;
      margin-bottom: 0.4em;
      font-weight: 500;

      @media screen and (max-width: 600px) {
        font-size: 1.1em;
      }
    }

    .sub-section {
      font-size: 0.7em;
      display: flex;
      align-items: center;

      svg {
        margin-left: 0.25em;
        color: rgba(28, 191, 115, 1);
      }
      @media screen and (max-width: 600px) {
        font-size: 1em;
      }
    }

    .number {
      font-size: 0.8em;

      @media screen and (max-width: 600px) {
        font-size: 1.1em;
      }
    }
    .bottom-message {
      @media screen and (max-width: 600px) {
        font-size: 0.8em;
      }
    }

    .selector {
      border: 1.25px solid grey;
      padding: 0.4em 0.6em;
      border-radius: 5px;
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;

      @media screen and (max-width: 600px) {
        width: 92.5%;
      }

      svg {
        color: grey;
        font-size: 1.1em;
      }

      .options {
        position: absolute;
        width: 100%;
        border: 1px solid grey;
        border-radius: 5px;
        top: 100%;
        margin-top: 0.5em;
        left: 0;
        background: white;
        height: fit-content;
        padding: 0.3em 0;

        .option {
          padding: 0.5em 0.7em;
          display: flex;

          align-items: center;

          svg {
            font-size: 0.9em;
            color: black;
            margin-right: 0.8em;
          }
        }

        .option:hover {
          background: rgba(28, 191, 115, 0.4);
        }
      }
    }
  }

  .button {
    text-align: right;
    padding-top: 1em;
    width: 100%;
    @media screen and (max-width: 600px) {
      padding-top: 0;
      display: flex;
      text-align: center;
    }
    a {
      background: rgba(28, 191, 115, 1);
      padding: 0.5em 3em;
      color: white;
      text-decoration: none;
      border-radius: 5px;
      font-weight: 500;
      font-size: 0.75em;

      @media screen and (max-width: 600px) {
        padding: 0.4em 0.8em;
        font-size: 1em;
        width: 100%;
      }
    }
  }
`;

// https://buy.stripe.com/test_cN2cNEfsU2QxgkU5km?prefilled_email=test%40kjfn.vom&client_reference_id=test
const StripePricingTable = () => {
  const { user } = useContext(UserDataContext);
  const [selector, setSelector] = useState({ open: false, amount: 30 });
  // const links = {
  //   30: {
  //     url: `https://buy.stripe.com/14kg1Gfzzdx86NadQS?prefilled_email=${encodeURIComponent(
  //       user.email
  //     )}&client_reference_id=${encodeURIComponent(user.userId)}`,
  //     price: 20,
  //   },
  //   60: {
  //     url: `https://buy.stripe.com/fZeeXCfzz78K9Zm4gh?prefilled_email=${encodeURIComponent(
  //       user.email
  //     )}&client_reference_id=${encodeURIComponent(user.userId)}`,
  //     price: 32,
  //   },
  //   100: {
  //     url: `https://buy.stripe.com/4gw6r68772Su4F2288?prefilled_email=${encodeURIComponent(
  //       user.email
  //     )}&client_reference_id=${encodeURIComponent(user.userId)}`,
  //     price: 53,
  //   },
  // };
  const links = {
    30: {
      url: `https://buy.stripe.com/14kg1Gfzzdx86NadQS?prefilled_email=${encodeURIComponent(
        user.email
      )}&client_reference_id=${encodeURIComponent(user.userId)}`,
      price: 20,
    },
    60: {
      url: `https://buy.stripe.com/fZeeXCfzz78K9Zm4gh?prefilled_email=${encodeURIComponent(
        user.email
      )}&client_reference_id=${encodeURIComponent(user.userId)}`,
      price: 32,
    },
    100: {
      url: `https://buy.stripe.com/4gw6r68772Su4F2288?prefilled_email=${encodeURIComponent(
        user.email
      )}&client_reference_id=${encodeURIComponent(user.userId)}`,
      price: 53,
    },
  };
  const stripeTableRef = useRef(null);

  useEffect(() => {
    // const test = document.querySelector(".PricingTable");
    // console.log(test);
    //test.forEach((t) => console.log(t));

    // Function to dynamically load the Stripe script
    const loadStripeScript = () => {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        // Script has loaded
        console.log("Stripe script loaded successfully.");
      };
    };

    // Check if the Stripe script is already loaded
    if (
      !document.querySelector(
        'script[src="https://js.stripe.com/v3/pricing-table.js"]'
      )
    ) {
      loadStripeScript();
    }

    // Setting up the stripe-pricing-table attributes after the component mounts
    if (stripeTableRef.current) {
      stripeTableRef.current.setAttribute(
        "pricing-table-id",
        "prctbl_1Om0rOGhIEdwFfSaDXv8v4Xl"
      );
      stripeTableRef.current.setAttribute(
        "publishable-key",
        "pk_live_51Om0WpGhIEdwFfSaPh34346LP67nAcQqfLHGQSsZIWpI6vkhy7TulfJGrp3eAzcdwA9AYd8Pja9t59zUhMoEc4XF00WsO5ExdA"
      );
    }
  }, []);

  return (
    <Wrapper>
      <h1>Buy Connects</h1>
      {/* <stripe-pricing-table ref={stripeTableRef}></stripe-pricing-table> */}
      <div className="section">
        <span className="title">Your Connects</span>
        <span className="sub-section number">
          {user.connects} <RiCopperCoinFill />
        </span>
      </div>
      <div className="section ">
        <span className="title">Select the amount to buy</span>
        <span
          className="sub-section selector"
          onClick={(e) => {
            let inTarget = false;
            let opps = document.querySelectorAll(".option");
            opps.forEach((t) => {
              if (t.contains(e.target)) inTarget = true;
            });

            if (!inTarget) setSelector({ ...selector, open: !selector.open });
          }}
        >
          <span>
            {selector.amount} connects for ${links[selector.amount].price}
          </span>
          <FaAngleDown />
          {selector.open && (
            <div className="options">
              {Object.keys(links).map((s) => (
                <div
                  className="option"
                  onClick={(e) => setSelector({ open: false, amount: s })}
                >
                  <FaCheck color={s == selector.amount ? "black" : "white"} />
                  {s} connects for ${links[s].price}
                </div>
              ))}
            </div>
          )}
        </span>
      </div>
      <div className="section">
        <span className="title">You will be charged</span>
        <span className="sub-section">
          ${links[selector.amount].price} + Tax
        </span>
      </div>
      <div className="section">
        <span className="title">Your new Connects balance will be </span>
        <span className="sub-section number">
          {Number(user.connects) + Number(selector.amount)} <RiCopperCoinFill />
        </span>
      </div>
      <div className="section">
        <span className="title">These Connects will expire</span>
        <span className="sub-section">
          {new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </span>
      </div>

      <div className="section">
        <span className="sub-section bottom-message">
          Unused Connects will rollover to the next month. This bundle of
          Connects will expire 1 year from today.
        </span>
      </div>

      <div className="button">
        <a
          href={links[selector.amount].url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy Connects
        </a>
      </div>

      {/* 
      <div className="price">
        <h1>30 Connects</h1>
        <span>One-time purchase of 30 connects. Enjoy trading!</span>
        <strong>$20 USD</strong>
      </div> */}
    </Wrapper>
  );
};

export default StripePricingTable;
