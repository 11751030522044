import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import { db, auth } from "../firebase-config";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  orderBy,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { arrayUnion, arrayRemove } from "firebase/firestore";
import { IoIosSend } from "react-icons/io";
import { UserDataContext } from "../ProtectedRoute";
import { formatLink } from "../Global/utils";
import { IoIosArrowRoundBack } from "react-icons/io";
import { getUser, sendMessageEmail } from "../dataLayer/stroage";
import { useSearchParams } from "react-router-dom";
// Function to start a new chat with user IDs and URLs

const Chat = () => {
  const { user, activeURL } = useContext(UserDataContext);

  let [searchParams, setSearchParams] = useSearchParams();
  let chatId = searchParams.get("chatId");

  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(chatId);
  const [chatData, setChatData] = useState(null);
  const [messages, setMessages] = useState([]);
  const newMessage = useRef("");

  const markUserAsActive = async (chatId, userId) => {
    try {
      const chatRef = doc(db, "Chats", chatId);
      await updateDoc(chatRef, {
        activeUsers: arrayUnion(userId),
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
    markUserAsActive(chatId, auth.currentUser.uid); // Mark the user as active
  };

  const markUserAsInactive = async (chatId, userId) => {
    try {
      const chatRef = doc(db, "Chats", chatId);
      await updateDoc(chatRef, {
        activeUsers: arrayRemove(userId),
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Prevent body from scrolling
  useEffect(() => {
    let inp = document.querySelector("#inputSection");
    if (selectedChatId) {
      inp.style.display = "none";
    }
    return () => {
      inp.style.display = "block";
      // rt.style.marginBottom = "0px";
    };
  }, [selectedChatId]);

  // Prevent body from scrolling
  useEffect(() => {
    if (selectedChatId) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  // Fetch chats where the current user is a participant
  useEffect(() => {
    const chatsRef = collection(db, "Chats");
    const q = query(
      chatsRef,
      where("participantIds", "array-contains", auth.currentUser.uid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chatsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChats(chatsData);
    });

    return () => {
      unsubscribe();
      markUserAsInactive(selectedChatId, auth.currentUser.uid);
    };
  }, []);

  // Listen for messages in the selected chat
  useEffect(() => {
    if (!selectedChatId) return;

    const messagesRef = collection(db, `Chats/${selectedChatId}/Messages`);
    const q = query(messagesRef, orderBy("createdAt"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messagesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, [selectedChatId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    let inp = document.querySelector("#chatInput");

    if (!inp) return;
    newMessage.current = inp.textContent;

    if (newMessage.current.trim() === "") return;

    // Reference to the messages subcollection
    const messagesRef = collection(db, `Chats/${selectedChatId}/Messages`);
    // Add the new message
    const newMessageRef = await addDoc(messagesRef, {
      text: newMessage.current,
      sender: auth.currentUser.uid,
      createdAt: serverTimestamp(),
    });

    if (newMessageRef.id) {
      // Successfully added new message, now update the chat's last message

      sendMessageEmail(
        newMessage.current,
        auth.currentUser.uid,
        selectedChatId
      );
      const chatRef = doc(db, "Chats", selectedChatId);
      await updateDoc(chatRef, {
        lastMessage: newMessage.current,
        lastUpdated: serverTimestamp(),
      });
    }

    newMessage.current = "";
  };

  function getChatDisplay(chat) {
    try {
      let other_user_data = chat.participantDetails.filter(
        (d) => d.userId !== auth.currentUser.uid
      )[0];

      return {
        url: formatLink(other_user_data.url) ?? "user",
        icon: other_user_data.icon ?? "https://linktradr.com/favicon.ico",
      };
    } catch {
      return {
        url: "user",
        icon: "https://linktradr.com/favicon.ico",
      };
    }
  }

  const Wrapper = styled.div`
    .conversations {
      margin-top: 0.5em;
      .no-messages {
        color: grey;
        font-size: 0.8em;
      }
      .chat {
        cursor: pointer;
        display: flex;
        //flex-direction: column
        padding: 0.5em 0;
        align-items: center;

        .right {
          margin: 0;
          margin-left: 1em;
          padding: 0;
          display: flex;
          flex-direction: column;
          .right-top {
            display: flex;
            justify-content: space-between;
            .chat-title {
              font-size: 0.8em;
            }
            .time {
              font-size: 0.7em;
              color: gray;
            }
          }

          .last-message {
            font-size: 0.7em;
            color: gray;
          }
        }

        img {
          height: 2em;
          //aspect-ratio: 1/1;
        }
      }
    }
  `;

  function toTime(timestamp) {
    try {
      console.log(timestamp);
      const milliseconds =
        timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);

      // Create Date object
      const date = new Date(milliseconds);

      const today = new Date();
      const isToday =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

      if (isToday) {
        // If timestamp is today, return the time
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // 12-hour format
        return `${hours}:${minutes} ${ampm}`;
      } else {
        // If timestamp is not today, return the date
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year =
          date.getFullYear() !== today.getFullYear()
            ? `/${date.getFullYear()}`
            : "";
        return `${day}/${month}${year}`;
      }
    } catch {
      return "";
    }
  }
  const smallSize = "900px";

  const ChatMessages = () => {
    const containerRef = useRef(null);

    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight; // Scroll to 500 pixels from the top
    }

    useEffect(() => {
      // Scroll to the bottom when component mounts
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, []);

    useLayoutEffect(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, []); // Run only once after the initial render

    const ChatWrapper = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: calc(100vh - 150px);
      min-height: calc(100vh - 150px);

      @media screen and (max-width: ${"500px"}) {
        max-height: calc(100vh - 210px);
        min-height: calc(100vh - 210px);
      }

      .top {
        //max-height: 70vh;
        overflow-y: scroll;
        height: 100%;
      }

      .message {
        display: flex;
        align-items: center;
        margin: 0.5em 0;

        .holder {
          display: flex;
          flex-direction: column;

          .top {
            display: flex;
            align-items: center;

            .text {
              background: rgb(242, 245, 250); //lightgray;
              padding: 0.2em 0.5em;
              border-radius: 5px;
              font-size: 0.8em;
            }
          }
        }

        img {
          width: 1.5em;
        }
      }
      .me-message {
        justify-content: flex-start;
        .time {
          line-height: 2em;
          margin-left: 2.8em;
          font-size: 0.6em;
          color: grey;
        }

        img {
          margin-right: 0.5em;
        }
      }

      .them-message {
        justify-content: flex-end;
        .time {
          line-height: 2em;
          display: flex;
          justify-content: flex-end;
          margin-right: 2.8em;
          font-size: 0.6em;
          color: grey;
        }
        img {
          margin-left: 0.5em;
        }
      }
      .header {
        display: flex;
        align-items: center;

        svg {
          margin-right: 1em;
          cursor: pointer;
        }

        span {
          //font-weight: 500;

          strong {
            font-weight: 500;
            color: rgba(28, 191, 115, 1);
          }
        }
        //justify-content: space-between;
      }

      ul {
        padding: 0;
        li {
          list-style: none;
        }
      }

      form {
        display: flex;
        align-items: flex-end;
        // border-top: 1px solid lightgrey;
        //padding-top: 0.5em;
        min-height: 2.3em;
        //max-height: 2.3em;
        height: fit-content;
        min-height: fit-content;
        margin-top: 0.5em;
        p {
          outline: none;
          width: 100%;
          font-size: 0.6em;
          padding: 0.5em 0.5em;
          border-radius: 10px;
          border: 1px solid lightgrey;
          resize: vertical;
          min-height: 2em;
          background: rgb(242, 245, 250);
          margin: 0;

          @media screen and (max-width: ${smallSize}) {
            border-radius: 5px;
          }

          @media screen and (max-width: ${"500px"}) {
            font-size: 16px;
          }
        }

        button {
          margin-left: 0.5em;
          outline: none;
          max-height: 3em;
          height: 4em;
          aspect-ratio: 1/1;
          border-radius: 50%;
          border: none;

          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(28, 191, 115, 1);
          @media screen and (max-width: ${smallSize}) {
            height: 30px;
            width: 30px;
          }
          svg {
            font-size: 2em;
            color: white;

            @media screen and (max-width: ${smallSize}) {
              font-size: 3em;
            }
            @media screen and (max-width: ${"500px"}) {
              font-size: 30px;
              min-height: 20px;
              min-width: 20px;
            }
          }
        }
      }
    `;
    const removeChatIdParam = () => {
      if (searchParams.has("chatId")) {
        searchParams.delete("chatId");
        setSearchParams(searchParams);
      }
    };

    const getDisplay = () => {
      try {
        return getChatDisplay(chats.filter((c) => c.id === selectedChatId)[0]);
      } catch {
        return { url: "user", icon: null };
      }
    };
    function moveCaretAtEnd(e) {
      var temp_value = e.target.value;
      e.target.value = "";
      e.target.value = temp_value;
    }

    const Input = () => {
      const [newMessagState, setNewMessage] = useState(newMessage.current);
      return (
        <p
          id="chatInput"
          // onFocus={(e) => moveCaretAtEnd(e)}
          // autoFocus
          contentEditable
          autoFocus
          onChange={(e) => {
            setNewMessage(e.target.textContent);
            newMessage.current = e.target.textContent;
          }}
        >
          {newMessagState}
        </p>
      );
    };
    return (
      <ChatWrapper>
        <div className="header">
          <IoIosArrowRoundBack
            onClick={() => {
              setSelectedChatId(null);
              markUserAsInactive(selectedChatId, auth.currentUser.uid);
              removeChatIdParam();
            }}
          />
          <span>
            Chat with <strong>{getDisplay().url}</strong>
          </span>
        </div>
        <div className="top" ref={containerRef}>
          {messages.map((message) => (
            <ul>
              {message.sender === auth.currentUser.uid ? (
                <li key={message.id} className="me-message message">
                  <div className="holder">
                    <div className="top">
                      <img
                        src={
                          user.websites[activeURL].icon ??
                          "https://linktradr.com/favicon.ico"
                        }
                        alt={"Me:"}
                      />{" "}
                      <span className="text">{message.text}</span>
                    </div>
                    <span className="time"> {toTime(message.createdAt)}</span>
                  </div>
                </li>
              ) : (
                <li key={message.id} className="them-message message">
                  <div className="holder">
                    <div className="top">
                      <span className="text">{message.text}</span>
                      <img
                        src={
                          getDisplay().icon ??
                          "https://linktradr.com/favicon.ico"
                        }
                        alt={":Them"}
                      />{" "}
                    </div>
                    <span className="time"> {toTime(message.createdAt)}</span>
                  </div>
                </li>
              )}
            </ul>
          ))}
        </div>
        <form onSubmit={handleSendMessage}>
          <Input />
          <button type="submit" onClick={(e) => handleSendMessage(e)}>
            <IoIosSend />
          </button>
        </form>
      </ChatWrapper>
    );
  };
  const Conversations = () => {
    return (
      <div className="conversations">
        {chats.length === 0 ? (
          <span className="no-messages">
            You do not have any messages yet start a chat through offers or
            trades.
          </span>
        ) : (
          <>
            {chats.map((chat) => (
              <div
                className="chat"
                key={chat.id}
                onClick={() => handleSelectChat(chat.id)}
              >
                <img
                  src={getChatDisplay(chat).icon}
                  alt={getChatDisplay(chat).url.substring(0, 2)}
                ></img>
                <div className="right">
                  <div className="right-top">
                    <span className="chat-title">
                      {getChatDisplay(chat).url}
                    </span>
                    <span className="time">
                      {toTime(
                        chat.lastUpdated ?? { seconds: 0, nanoseconds: 0 }
                      )}
                    </span>
                  </div>
                  <span className="last-message">
                    {chat.lastMessage === ""
                      ? "Start chatting"
                      : chat.lastMessage}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };
  return (
    <Wrapper>
      {!selectedChatId && <h1>Messages</h1>}
      {selectedChatId ? <ChatMessages /> : <Conversations />}
    </Wrapper>
  );
};

export default Chat;
