import styled, { keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
const fadeIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-150%);
  }
`;

// Define reverse animations
const fadeInReverse = keyframes`
  from {
    transform: translateY(100%); // Example reverse direction
  }
  to {
    transform: translateY(0);
  }
`;

const fadeOutReverse = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(150%); // Example reverse direction
  }
`;

const Wrapper = styled.div`
  width: 95vw;
  color: rgb(12, 65, 39);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: fixed;
  background: white;
  top: 10px;
  right: 5%;
  border-radius: 5px;
  border: 1px solid rgba(237, 67, 55, 0.4);
  animation: ${(props) => (props.fadeOut ? fadeOut : fadeIn)} 0.5s forwards;
  display: ${(props) => props.display};
  z-index: 9999999999999999;

  @media screen and (max-width: 800px) {
    top: auto;
    bottom: 20px;
    animation: ${(props) => (props.fadeOut ? fadeOutReverse : fadeInReverse)}
      0.5s forwards; // Apply reverse animations

    left: 2.5%;
  }

  .holder {
    height: 100%;
    max-width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    padding-right: 0.5em;
    margin: 0.4em 0;
    margin-bottom: 0.2em;

    span {
      font-size: 0.7em;
      color: black; //rgba(237, 67, 55, 1);
      cursor: default;
    }
    .left {
    }

    a {
      cursor: pointer;
      font-size: 0.7em;
      /* border: 1px solid lightgrey; */
      color: grey;
      /* font-weight: 200; */
      /* padding: 0.2em 0.4em; */
      border-radius: 5px;
      text-decoration: underline;
      color: green;
      margin-left: 0.4em;
    }

    .close {
      color: black;
      font-size: 0.8em;

      display: flex;
      align-items: center;
    }
  }
`;

const ProgressBar = styled.div`
  margin-top: 5px;
  height: 6px;
  background-color: rgba(237, 67, 55, 0.6);
  width: ${(props) => `${props.progress}%`};
  transition: width 1s linear;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
`;

function Error({ close = () => {}, message }) {
  const messageRef = {
    "Please enter the content url.": { text: "", right: <></> },
    "Please select a content type.": { text: "", right: <></> },
    "There was an error sending your offer.": { text: "", right: <></> },
    "Please verify your website.": {
      text: "",
      right: <a href="/mysite/verify">Get verified</a>,
    },
    "You do not have enough connects for this trade.": {
      text: "",
      right: <a href="/mysite/connects">Get connects</a>,
    },
    "Offer sent": { text: "", right: <></> },
    "Could not send offer.": { text: "", right: <></> },
  };

  const [visible, setVisible] = useState(true);
  const [progress, setProgress] = useState(0); // Start with progress at 0%
  const [display, setDisplay] = useState("block");

  // Configurable total animation time in milliseconds
  const totalAnimationTime = 6000; // Total animation time in milliseconds
  const progressUpdateTime = 50; // Time in milliseconds between progress updates
  const maxProgressIncrement = 100; // Maximum progress increment to reach 100%
  const incrementPerUpdate =
    (maxProgressIncrement / totalAnimationTime) * progressUpdateTime;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        let newProgress = prevProgress + incrementPerUpdate;
        if (newProgress >= 100) {
          newProgress = 100; // Ensure progress does not exceed 100%
          clearInterval(interval);
          setTimeout(() => setVisible(false), 1000); // Start fade out after progress completes
        }
        return newProgress;
      });
    }, progressUpdateTime); // Update progress every progressUpdateTime ms

    // Adjust fadeOutTimeout based on totalAnimationTime
    const fadeOutTimeout = setTimeout(() => {
      close();
      setDisplay("none"); // Hide after the fade-out animation
    }, totalAnimationTime - 2000); // Set timeout based on total animation time

    return () => {
      clearInterval(interval);
      clearTimeout(fadeOutTimeout);
    };
  }, [progress, totalAnimationTime, progressUpdateTime, incrementPerUpdate]);

  return (
    <Wrapper visible={visible} fadeOut={!visible} display={display}>
      <div className="holder">
        <div className="left">
          <span>{message}</span>
          {message.trim() in messageRef && messageRef[message.trim()].right}
        </div>
        <span className="close" onClick={() => close()}>
          <AiOutlineClose />
        </span>
      </div>
      <ProgressBar progress={progress} />
    </Wrapper>
  );
}

export default Error;
