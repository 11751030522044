import styled from "styled-components";
import { useState, useRef, useContext, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { respondToOffer } from "../dataLayer/stroage";
import { IoIosMore, IoMdClose } from "react-icons/io";
import { formatLink } from "../Global/utils";
import { BsPersonCircle } from "react-icons/bs";
import { updateOffer } from "../dataLayer/stroage";
import { UserDataContext } from "../ProtectedRoute";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import {
  Status,
  StatusDisplayRef,
  getLabel,
  allContentTypes,
  getWebsite,
  getChatWithUser,
} from "../dataLayer/stroage";
import Error from "../Global/Error";
import { GoPlus } from "react-icons/go";
import { IoIosArrowRoundBack } from "react-icons/io";
import { startNewChatWithUrls } from "../dataLayer/stroage";
import { useNavigate } from "react-router-dom";
const Details = ({ data }) => {
  const { user, setUser, activeURL } = useContext(UserDataContext);
  let inputRef = useRef("");
  let materals_selection_ref = useRef([]);

  const navigate = useNavigate();

  //const [trade, setTrade] = useState(data.trade);
  const trade = user.offers[data.trade.id];
  const [message, setMessage] = useState({ show: false, message: "" });
  let isSender = trade.sender == user.userId;
  let sender = trade[trade.sender];
  let reciver = trade[trade.reciver];
  let self_data = isSender ? sender : reciver;
  let other_data = isSender ? reciver : sender;

  const [other_site_data, setOtherSiteData] = useState(null);
  const [profilePage, setProfilePage] = useState(false);
  useEffect(() => {
    if (!other_site_data) {
      getWebsite(isSender ? trade.reciver : trade.sender, other_data.url).then(
        (d) => {
          setOtherSiteData(d);
        }
      );
    }

    return () => {};
  }, []);

  let score = 0;
  if (other_site_data) score = Math.ceil(other_site_data.score) ?? 0;
  let color = "black";
  if (score < 20) {
    color = "#E32636";
  } else if (score < 50) {
    color = "#FF7518"; //"#F28C28";
  } else if (score < 70) {
    color = "#F4C430";
  } else {
    color = "#1CAC78";
  }
  const them_url = formatLink(other_data.url);
  // True false is to the isSender (false = receiver)
  const Options = {
    [Status.Pending]: {
      options: {
        [false]: [Status.Accepted, Status.Declined],
        [true]: [Status.Rescind],
      },
      sentence: {
        [false]: "You have not responded to this offer.",
        [true]: `${them_url} has not responded to your offer.`,
      },
    },

    [Status.Declined]: {
      options: {
        [false]: [Status.Trash],
        [true]: [Status.Trash],
      },
      sentence: {
        [false]: "You have declined this offer.",
        [true]: `${them_url} has declined your offer.`,
      },
    },
    [Status.Rescind]: {
      options: {
        [false]: [Status.Trash],
        [true]: [Status.Trash],
      },
      sentence: {
        [false]: "You have rescinded this offer.",
        [true]: `${them_url} has rescinded this offer.`,
      },
    },

    [Status.Accepted]: {
      options: {
        [false]: [],
        [true]: [],
      },
      sentence: {
        [false]: "",
        [true]: "",
      },
    },

    [Status.Trash]: {
      options: {
        [false]: [],
        [true]: [],
      },
      sentence: {
        [false]: "",
        [true]: "",
      },
    },
    // TRADE OPTIONS
    [Status.NotStarted]: {
      options: {
        [false]: [Status.InProgress],
        [true]: [Status.InProgress],
      },
      sentence: {
        [false]: "You have not started working on this trade.",
        [true]: `${them_url} has not started working on this trade.`,
      },
    },
    [Status.InProgress]: {
      options: {
        [false]: [Status.Completed, Status.Stopped],
        [true]: [Status.Completed, Status.Stopped],
      },
      sentence: {
        [false]: "You are in progress on this trade.",
        [true]: `${them_url} is in progress on this trade.`,
      },
    },
    [Status.Stopped]: {
      options: {
        [false]: [Status.InProgress],
        [true]: [Status.InProgress],
      },
      sentence: {
        [false]: "You have paused progress on this trade.",
        [true]: `${them_url} has paused progress on this trade.`,
      },
    },
    [Status.Completed]: {
      options: {
        [false]: [],
        [true]: [],
      },
      sentence: {
        [false]: "You have completed this trade.",
        [true]: `${them_url} has completed this trade.`,
      },
    },
  };

  const smallSize = "900px";
  const Wrapper = styled.div`
    height: calc(100% - 2.2em);
    font-size: 1.4em;
    min-width: fit-content;
    width: 100%;

    @media screen and (max-width: ${smallSize}) {
      font-size: 1.2em;
    }

    .holder {
      text-align: left;
      display: flex;
      flex-direction: column;
      margin: 1em 1.5em;
      height: 100%;
      justify-content: space-between;
      overflow-y: scroll;

      .profile-header {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        margin-bottom: 1em;
        margin-top: 0.5em;

        img {
          height: 2em;
          width: 2em;
          margin-right: 1.2em;
        }

        h1 {
          // color: rgba(28, 191, 115, 1);
          font-weight: 600;
          font-size: 1.4em;
        }
      }
      .top {
        .title {
          font-size: 0.8em;

          @media screen and (max-width: ${smallSize}) {
            font-size: 1.2em;
          }
        }

        .controls {
          margin-bottom: 0.5em;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .right-controls {
            display: flex;
            align-items: center;

            .profile {
              margin-left: 0.5em;
              cursor: pointer;
            }

            .chat {
              cursor: pointer;
              font-size: 1.2em;
              @media screen and (max-width: ${smallSize}) {
                font-size: 1.45em;
              }
            }
          }

          .slide-out-close {
            font-size: 0.9em;
            cursor: pointer;
            @media screen and (max-width: ${smallSize}) {
              font-size: 1.3em;
            }
          }

          .profile {
            cursor: pointer;
            @media screen and (max-width: ${smallSize}) {
              font-size: 1.3em;
            }
          }

          .back {
            cursor: pointer;
            font-size: 1.3em;
            @media screen and (max-width: ${smallSize}) {
              font-size: 1.8em;
            }
          }

          h1 {
            font-size: 1em;
            font-weight: 700;
            color: rgba(28, 191, 115, 1);
          }
        }

        .trade-statement {
          font-size: 1.1em;
          font-weight: bold;
          margin: 0;
          padding: 0;

          @media screen and (max-width: ${smallSize}) {
            font-size: 1.4em;
          }

          .them-url {
            color: rgba(28, 191, 115, 1);
          }

          .give {
            color: rgba(28, 191, 115, 1);
          }

          .them-give {
            color: rgba(28, 191, 115, 1);
          }
        }

        .authority {
          margin-top: 0.5em;
          display: flex;
          align-items: end;
          height: fit-content;
          @media screen and (max-width: ${smallSize}) {
            margin-top: 1em;
          }

          h1 {
            margin: 0;
            font-weight: 700;
            font-size: 1.8em;
            color: ${color};
            @media screen and (max-width: ${smallSize}) {
              font-size: 2.5em;
            }
          }
          span {
            margin-bottom: 0.4em;
            margin-left: 0.5em;
            font-size: 0.8em;
            @media screen and (max-width: ${smallSize}) {
              font-size: 1.2em;
              margin-bottom: 0.4em;
            }
          }
        }

        .description {
          margin-top: 0.5em;
          //max-height: 15vh; // TODO maybe, add back to hover to expand
          overflow-y: hidden;
          cursor: pointer;

          .sub-title {
            display: none; // TODO maybe, remove if hover to expand
            font-size: 0.5em;
            color: gray;
            margin-left: 1em;
            @media screen and (max-width: ${"500px"}) {
              font-size: 16px;
            }

            .click {
              display: none;
              text-decoration: none;
              font-style: normal;
            }

            .hover {
              display: inline;
              text-decoration: none;
              font-style: normal;
            }
            @media screen and (max-width: ${smallSize}) {
              font-size: 0.8em;

              .click {
                display: inline;
              }

              .hover {
                display: none;
              }
            }
          }
          @media screen and (max-width: ${smallSize}) {
            margin-top: 1em;
          }
          p {
            margin: 0;
            margin-top: 0.5em;
            font-size: 0.6em;
            color: grey;
            border-radius: 3px;
            @media screen and (max-width: ${smallSize}) {
              font-size: 0.9em;
            }
          }
        }

        .description:hover {
          max-height: min-content;
          overflow-y: hidden;
        }

        .requested-materials {
          margin-top: 1em;
          display: flex;
          flex-direction: column;
          .sub-title {
            color: gray;
            font-size: 0.6em;
            @media screen and (max-width: ${"500px"}) {
              font-size: 16px;
            }
            @media screen and (max-width: ${smallSize}) {
              font-size: 0.8em;
            }
            .them-url {
              font-style: normal;
              color: rgba(28, 191, 115, 1);
            }
          }
          .loading {
            margin-top: 0.5em;
            color: black;
            font-size: 0.6em;
            @media screen and (max-width: ${smallSize}) {
              font-size: 0.8em;
            }
          }
          .materials {
            margin-top: 0.3em;
            display: flex;
            flex-wrap: wrap;
            @media screen and (max-width: ${smallSize}) {
              margin-top: 0.5em;
            }

            span {
              font-size: 0.6em;
              color: gray;
              padding: 0.3em 0.5em;
              border: 1px solid grey;
              margin-right: 0.5em;
              border-radius: 5px;
              white-space: nowrap;
              margin-bottom: 0.5em;
              cursor: pointer;
              display: flex;
              align-items: center;

              svg {
                margin-right: 0.3em;
              }
              @media screen and (max-width: ${smallSize}) {
                font-size: 0.9em;
              }
            }
          }
        }
        .status {
          margin-top: 0.5em;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: ${smallSize}) {
            margin-top: 1em;
          }
          .status-sentence {
            margin: 0;
            margin-top: 0.5em;
            font-size: 0.7em;
            color: grey;

            @media screen and (max-width: ${smallSize}) {
              font-size: 1em;
            }
          }
        }
      }

      .input-section {
        margin-top: 1em;
        display: flex;
        flex-direction: column;

        .sub-title {
          margin: 0;
          margin-top: 0.5em;
          font-size: 0.7em;
          color: grey;
          @media screen and (max-width: ${"500px"}) {
            font-size: 16px;
          }
          a {
            color: rgba(28, 191, 115, 1);
          }
        }

        .link {
          color: grey;
          font-size: 0.7em;
        }

        .input-holder {
          margin-top: 0.5em;
          position: relative;
          display: flex;
          align-items: center;

          input {
            width: 100%;
            font-size: 0.7em;
            padding: 0.5em 1em;
            border-radius: 50px;
            outline: none;
            border: none;
            border: 1px solid grey;
            border-radius: 5px;
            @media screen and (max-width: ${"500px"}) {
              font-size: 16px;
            }
          }
          input::placeholder {
            color: lightgrey;
          }
        }
      }

      .button-holder {
        display: flex;
        flex-direction: column;

        .button {
          margin-top: 1em;
          text-align: center;
          width: 100%;
          background-color: lightgrey;
          border-radius: 5px;
          padding: 0.4em 0.2em;
          cursor: pointer;
          font-weight: 500;
          color: white;
        }

        .status-span {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 0.55em;
          border-radius: 25px;
          margin-top: 0.8em;
          // margin: 0.15em 0.5em;
          width: 100%;
          /* min-width: 60px;
              max-width: 80px; */
          text-align: center;
          color: black;
          font-weight: 400;
          justify-content: center;
          /* 
              @media screen and (max-width: 550px) {
                max-width: 45px;
                min-width: 45px;
              } */
          padding: 0.5em 0;
          color: white;

          @media screen and (max-width: ${smallSize}) {
            font-size: 0.9em;
            margin-top: 1em;
          }

          svg {
            font-size: 1.5em;
            margin-right: 0.6em;
          }
          .small {
            font-size: 1.2em;
          }

          span {
            font-size: 1.3em;
            font-weight: 500;
          }
        }

        .bottom-control-holder {
          display: flex;

          .profile {
            margin-left: 0.8em;
            white-space: nowrap;
          }
        }
      }
    }
  `;

  function statusChange(isTrade, value) {
    let newTrade = JSON.parse(JSON.stringify(trade));
    if (isTrade && value == Status.Completed) {
      if (inputRef.current.trim() === "") {
        setMessage({
          show: true,
          message: "Please enter the content url.",
        });
        return;
      } else {
        newTrade[user.userId].content_url = inputRef.current;
      }
    }
    if (isTrade) {
      newTrade[user.userId].status = value;
    } else {
      newTrade.status = value;
    }

    if (!isTrade && value == Status.Accepted && !isSender) {
      newTrade[trade.sender].provide_materials = materals_selection_ref.current;
    }
    updateOffer(newTrade);

    let newUser = JSON.parse(JSON.stringify(user));
    newUser.offers[trade.id] = newTrade;
    setUser(newUser);
  }

  const RequestMaterials = ({ isTrade, select = false }) => {
    const [materials_selection, set_materials_selection_state] = useState([]);

    const set_materials_selection = (data) => {
      materals_selection_ref.current = data;
      set_materials_selection_state(data);
    };
    materals_selection_ref.current = materials_selection;
    if (isTrade) {
      return (
        <>
          <div className="requested-materials">
            <span className="title">Requested Materials</span>

            <span className="sub-title">
              <em className="them-url">{formatLink(other_data.url)}</em> is
              requesting these materials from you.
            </span>

            <div className="materials">
              {(self_data.provide_materials ?? []).map((m) => (
                <span>{m}</span>
              ))}
              {(self_data.provide_materials ?? []).length == 0 && (
                <span>None</span>
              )}
            </div>
          </div>
          <div className="requested-materials">
            <span className="title">Requested Materials</span>

            <span className="sub-title">
              You are requesting these materials from{" "}
              <em className="them-url">{formatLink(other_data.url)}</em>
            </span>

            <div className="materials">
              {(other_data.provide_materials ?? []).map((m) => (
                <span>{m}</span>
              ))}
              {(other_data.provide_materials ?? []).length == 0 && (
                <span>None</span>
              )}
            </div>
          </div>
        </>
      );
    }

    if (select) {
      return (
        <div className="requested-materials">
          <span className="title">Request materials</span>

          <span className="sub-title">
            What materials do you need from{" "}
            <em className="them-url">{formatLink(other_data.url)}</em>?
          </span>

          {other_site_data ? (
            <div className="materials">
              {(
                other_site_data.materials ?? [
                  "long-description",
                  "blog-content",
                  "short-description",
                  "logo",
                ]
              ).map((m) => (
                <span
                  onClick={() =>
                    !materials_selection.includes(m)
                      ? set_materials_selection(
                          [...materials_selection].concat([m])
                        )
                      : set_materials_selection(
                          materials_selection.filter((t) => t != m)
                        )
                  }
                  style={{
                    color: materials_selection.includes(m) && "white",
                    background:
                      materials_selection.includes(m) &&
                      "rgba(28, 191, 115, 0.8)",
                    borderColor:
                      materials_selection.includes(m) &&
                      "rgba(28, 191, 115, 1)",
                  }}
                >
                  {!materials_selection.includes(m) && <GoPlus />} {m}
                </span>
              ))}
              {(other_site_data.materials ?? []).length == 0 && (
                <span>None</span>
              )}
            </div>
          ) : (
            <span className="loading">loading options...</span>
          )}
        </div>
      );
    }
    return (
      <div className="requested-materials">
        <span className="title">Requested Materials</span>

        {isSender ? (
          <span className="sub-title">
            You are requesting these materials from{" "}
            <em className="them-url">{formatLink(reciver.url)}</em>.
          </span>
        ) : (
          <span className="sub-title">
            <em className="them-url">{formatLink(reciver.url)}</em> is
            requesting these materials from you.
          </span>
        )}

        <div className="materials">
          {(reciver.provide_materials ?? []).map((m) => (
            <span>{m}</span>
          ))}
          {(reciver.provide_materials ?? []).length == 0 && <span>None</span>}
        </div>
      </div>
    );
  };
  const Profile = () => {};
  return (
    <>
      <Wrapper>
        {profilePage ? (
          <div className="holder">
            <div className="top">
              <div className="controls">
                <IoMdClose className="slide-out-close" />
                <IoIosArrowRoundBack
                  className="back"
                  onClick={() => setProfilePage(false)}
                />
              </div>
              <h1>{them_url}</h1>
              <div className="profile-header">
                {other_site_data && (
                  <img
                    src={
                      other_site_data.icon ??
                      "https://linktradr.com/favicon.ico"
                    }
                  ></img>
                )}
                {other_site_data && (
                  <div className="authority">
                    <h1>{Math.ceil(other_site_data.score) ?? 0}</h1>
                    <span>authority</span>
                  </div>
                )}
              </div>
              {other_site_data && (
                <div className="description">
                  <span className="title">Description</span>
                  <span className="sub-title">
                    <em className="click">Click</em>
                    <em className="hover">Hover</em> to expand
                  </span>
                  <p>{other_site_data.description}</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="holder">
            <div className="top">
              <div className="controls">
                <IoMdClose className="slide-out-close" />
                <div className="right-controls">
                  <IoChatbubbleEllipsesOutline
                    className="chat"
                    onClick={async () => {
                      try {
                        let chatId = await getChatWithUser(
                          user,
                          activeURL,
                          isSender ? trade.reciver : trade.sender,
                          other_data.url
                        );
                        if (chatId) {
                          navigate("/mysite/messages?chatId=" + chatId);
                        } else {
                          setMessage({
                            show: true,
                            message: "Could not open chat. Please try again.",
                          });
                        }
                      } catch {
                        setMessage({
                          show: true,
                          message: "Could not open chat. Please try again.",
                        });
                      }
                    }}
                  />
                  <BsPersonCircle
                    className="profile"
                    onClick={() => setProfilePage(true)}
                  />
                </div>
              </div>
              <p className="trade-statement">
                {isSender ? (
                  <>
                    <span>You are offering a</span>{" "}
                    <span className="give">
                      {getLabel(allContentTypes, self_data.create_content_type)}
                    </span>{" "}
                    in exchange for a{" "}
                    <span className="give">
                      {getLabel(
                        allContentTypes,
                        other_data.create_content_type
                      )}
                    </span>{" "}
                    from{" "}
                    <span className="them-url">
                      {formatLink(other_data.url)}
                    </span>
                    .
                  </>
                ) : (
                  <>
                    <span className="them-url">
                      {formatLink(other_data.url) + " "}
                    </span>{" "}
                    is offering a{" "}
                    <span className="give">
                      {getLabel(
                        allContentTypes,
                        other_data.create_content_type
                      )}
                    </span>{" "}
                    in exchange for a{" "}
                    <span className="give">
                      {getLabel(allContentTypes, self_data.create_content_type)}
                    </span>{" "}
                    from you.
                  </>
                )}
              </p>
              <div className="status">
                <span className="title">Status</span>
                {trade.status == Status.Accepted ? (
                  <>
                    <span className="status-sentence">
                      {Options[other_data.status].sentence[isSender]}
                    </span>
                    <span className="status-sentence">
                      {Options[self_data.status].sentence[!isSender]}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="status-sentence">
                      {Options[trade.status].sentence[isSender]}
                    </span>
                  </>
                )}
              </div>

              <RequestMaterials isTrade={trade.status === Status.Accepted} />
              {!isSender && trade.status === Status.Pending && (
                <RequestMaterials select={true} />
              )}

              <div className="input-section">
                {self_data.status === Status.Completed && (
                  <span className="title">Content Links</span>
                )}
                {self_data.status === Status.Completed && (
                  <span className="sub-title">
                    Your content:{" "}
                    <a href={self_data.content_url}>{self_data.content_url}</a>
                  </span>
                )}
                {other_data.status === Status.Completed && (
                  <span className="sub-title">
                    {formatLink(other_data.url)}'s content:{" "}
                    <a href={other_data.content_url}>
                      {other_data.content_url}
                    </a>
                  </span>
                )}

                {self_data.status == Status.InProgress && (
                  <>
                    <span className="sub-title">
                      Please provide a link to the content
                    </span>
                    <div className="input-holder">
                      <input
                        placeholder={
                          self_data.content_url ?? "www.linktradr.com/blog-post"
                        }
                        onChange={(e) => {
                          inputRef.current = e.target.value;
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="button-holder">
              <>
                <div className="bottom-control-holder">
                  <span
                    style={{
                      background: "grey",
                    }}
                    onClick={async () => {
                      try {
                        let chatId = await getChatWithUser(
                          user,
                          activeURL,
                          isSender ? trade.reciver : trade.sender,
                          other_data.url
                        );
                        if (chatId) {
                          navigate("/mysite/messages?chatId=" + chatId);
                        } else {
                          setMessage({
                            show: true,
                            message: "Could not open chat. Please try again.",
                          });
                        }
                      } catch {
                        setMessage({
                          show: true,
                          message: "Could not open chat. Please try again.",
                        });
                      }
                    }}
                    className="status-span chat"
                  >
                    {<IoChatbubbleEllipsesOutline />}
                    <span>Chat</span>
                  </span>

                  <span
                    onClick={() => setProfilePage(true)}
                    className="status-span profile"
                    style={{
                      background: "grey",
                    }}
                  >
                    {<BsPersonCircle />}
                    <span>View profile</span>
                  </span>
                </div>
                {(
                  Options[
                    trade.status == Status.Accepted
                      ? self_data.status
                      : trade.status
                  ].options[isSender] ?? []
                ).map((s) => (
                  <span
                    onClick={() =>
                      statusChange(trade.status == Status.Accepted, s)
                    }
                    className="status-span"
                    style={{
                      background: StatusDisplayRef[s].color,
                    }}
                  >
                    {StatusDisplayRef[s].icon_no_color}
                    <span>
                      {trade.status === Status.Accepted
                        ? "Set to " + StatusDisplayRef[s].label
                        : StatusDisplayRef[s].label}
                    </span>
                  </span>
                ))}
              </>
            </div>
          </div>
        )}
      </Wrapper>
      {message.show && (
        <Error
          close={() => {
            setMessage({ show: false });
          }}
          message={message.message}
        />
      )}
    </>
  );
};

export default Details;
