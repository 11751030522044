import { MdOutlineDoNotDisturbOn } from "react-icons/md";
import { TbProgressCheck } from "react-icons/tb";
import { AiOutlineStop } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import { AiFillStop } from "react-icons/ai";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa6";
import { IoPerson } from "react-icons/io5";
import { auth, db } from "../firebase-config";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  orderBy,
  serverTimestamp,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
const devEnv = false;
const serverUrl = devEnv
  ? "http://localhost:8080"
  : "https://seo-backend.onrender.com";
async function addBetaUser(data) {
  try {
    let response = await fetch(`${serverUrl}/createBetaUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        info: data,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    try {
      return { success: true, message: "", ...(await response.json()) };
    } catch {
      return { success: true, message: "" };
    }
  } catch (error) {
    console.error(error);
    return { success: false, message: error.message };
  }
}

async function getWebsites() {
  var posts = sessionStorage.getItem("posts");

  if (posts) return JSON.parse(posts).data;

  try {
    const response = await fetch(`${serverUrl}/betaPost`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    sessionStorage.setItem("posts", JSON.stringify({ data: data.data }));
    return data.data;
  } catch (error) {
    console.error("Error fetching betapost:", error);
    return [];
  }
}

async function sendSignInLink(email) {
  return await fetch(`${serverUrl}/sendSignInLink`, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function completeSignIn(url) {
  return fetch(`${serverUrl}/completeSignIn`, {
    method: "POST",
    body: JSON.stringify({ url }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const getUser = async (uid = null) => {
  if (!uid) {
    uid = sessionStorage.getItem("uid");
  }
  console.log(uid);
  if (!uid) return;
  try {
    const response = await fetch(`${serverUrl}/getUser/${uid}`);

    if (!response.ok) {
      if (response.status === 404) {
        // Handle 404 Not Found
        return null;
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    return null;
  }
};

const updateSiteSettings = async (
  url,
  tags,
  materials,
  contentTypes,
  description
) => {
  let userId = sessionStorage.getItem("uid");
  if (!userId) return;
  try {
    const response = await fetch(`${serverUrl}/setSiteSettings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        url,
        tags,
        materials,
        contentTypes,
        description,
      }),
    });

    if (!response.ok) {
      if (response.status === 404) {
        // Handle 404 Not Found
        return null;
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error("Error setting site settings:", error);
    return null;
  }
};
const updateIcon = async () => {
  let userId = sessionStorage.getItem("uid");
  if (!userId) return;
  try {
    const response = await fetch(`${serverUrl}/updateIcon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      if (response.status === 404) {
        // Handle 404 Not Found
        return null;
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    //const data = await response.json();
    return (await response.json()).data;
  } catch (error) {
    console.error("Error setting site settings:", error);
    return null;
  }
};

const proposeTrade = async (offerDetails) => {
  let userId = sessionStorage.getItem("uid");
  if (!userId) return;
  try {
    const response = await fetch(`${serverUrl}/proposeTrade`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userId}`,
      },
      body: JSON.stringify(offerDetails),
    });
    console.log(response);
    if (!response.ok) {
      console.error("Error adding offer to user:");
      return { status: false, message: await response.text() };
    }

    return { status: true, message: "" };
  } catch (error) {
    console.error("Error adding offer to user:", error);
    return { status: false, message: "There was an error sending your offer." };
  }
};

const respondToOffer = async (offerId, ans) => {
  let userId = sessionStorage.getItem("uid");
  if (!userId) return;
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, offerId, response: ans }),
    };

    const response = await fetch(`${serverUrl}/respondToOffer`, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data.message);
    return true;
  } catch (error) {
    console.error("Error responding to offer:", error);
    return false;
  }
};
const updateOffer = async (trade) => {
  try {
    let userId = sessionStorage.getItem("uid");
    if (!userId) return;

    // Make the fetch request to the updateOffer endpoint
    const response = await fetch(`${serverUrl}/updateOffer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userId}`,
      },
      body: JSON.stringify(trade),
    });

    if (!response.ok) {
      return { status: false, message: await response.text() };
    }

    let res = await response.json();
    return { status: true, message: res.message, data: res.data };
  } catch (error) {
    console.error("Error updating offer:", error);
    return { status: false, message: "Error updating offer" };
  }
};

const byEmail = async (email) => {
  try {
    const response = await fetch(`${serverUrl}/byEmail?email=${email}`);

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    return data.uid;
  } catch (error) {
    console.error("Error searching user by email:", error);
    return null;
  }
};

async function createUser(email, password) {
  try {
    const response = await fetch(`${serverUrl}/createUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    if (!response.ok) {
      const data = await response.json();
      return { status: false, message: data.message };
    }

    const data = await response.json();
    return { status: true, uid: data.uid, message: "" };
  } catch (error) {
    console.error("Error creating user:", error);
    return { status: false, message: "could not create account" };
  }
}
const giveFeedback = async (feedback) => {
  try {
    let userId = sessionStorage.getItem("uid");
    if (!userId) return;

    // Make the fetch request to the updateOffer endpoint
    const response = await fetch(`${serverUrl}/give-feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userId}`,
      },
      body: JSON.stringify({
        feedback,
        userId,
      }),
    });

    if (!response.ok) {
      return { status: false };
    }
    return { status: true };
  } catch (error) {
    console.error("Error sending feedback:", error);
    return { status: false };
  }
};

const allTags = [
  { label: "Technology", id: "technology" },
  { label: "Business", id: "business" },
  { label: "Blog", id: "blog" },
  { label: "SEO", id: "seo" },
  { label: "Marketing", id: "marketing" },
  { label: "E-commerce and Trading", id: "e-commerce" },
  { label: "Dropshipping", id: "dropshipping" },
  { label: "Product/Service Providers", id: "product-service" },
  { label: "News and Journalism", id: "news-journalism" },
  { label: "Cybersecurity", id: "cybersecurity" },
  { label: "AI", id: "ai" },
  { label: "Travel", id: "travel" },
  { label: "Financial Technology", id: "fin-tech" },
];
const allMaterials = [
  { label: "blog content", id: "blog-content" },
  { label: "short product description", id: "short-description" },
  { label: "detailed product explication", id: "long-description" },
  { label: "logo imaging", id: "logo" },
];
const allContentTypes = [
  { label: "Blog post", id: "blog-post" },
  { label: "Feature", id: "feature" },
  { label: "Sub-link", id: "sub-link" },
  { label: "Other", id: "other" },
];

const getLabel = (list, id) => {
  let label = null;
  label = list.filter((o) => o.id == id)[0].label;
  return label;
};

const StatusDisplayRef = Object.freeze({
  ACC: {
    label: "Accept",
    label_past_tense: "Accepted",
    color: "rgba(28, 191, 115, 1)",
    icon: <FaRegCircleCheck color={"rgba(28, 191, 115, 1)"} />,
    icon_no_color: <FaRegCircleCheck />,
  },
  INP: {
    label: "In Progress",
    label_past_tense: "In Progress",
    color: "rgb(254, 199, 51)",
    icon: <TbProgressCheck color={"rgb(254, 199, 51)"} className="big" />,
    icon_no_color: <TbProgressCheck className="big" />,
  },
  DEC: {
    label: "Decline",
    label_past_tense: "Declined",
    color: "#ed4337",
    icon: <AiOutlineStop color={"#ed4337"} />,
    icon_no_color: <AiOutlineStop />,
  },
  RES: {
    label: "Rescind",
    label_past_tense: "Rescinded",
    color: "#ed4337",
    icon: <AiFillStop color={"#ed4337"} />,
    icon_no_color: <AiFillStop />,
  },
  PEN: {
    label: "Pending",
    label_past_tense: "Pending",
    color: "rgb(254, 199, 51)",
    icon: <FaRegHourglassHalf color={"rgb(254, 199, 51)"} className="small" />,
    icon_no_color: <FaRegHourglassHalf className="small" />,
  },
  DNS: {
    label: "Not Started",
    label_past_tense: "Not Started",
    color: "grey",
    icon: <MdOutlineDoNotDisturbOn color={"grey"} className="big" />,
    icon_no_color: <MdOutlineDoNotDisturbOn className="big" />,
  },
  TRS: {
    label: "Trash",
    label_past_tense: "Trash",
    color: "#ed4337",
    icon: <FaTrash color={"#ed4337"} className="small" />,
    icon_no_color: <FaTrash className="small" />,
  },
  STP: {
    label: "Stop",
    label_past_tense: "Stopped",
    color: "#ed4337",
    icon: <AiOutlineStop color={"#ed4337"} />,
    icon_no_color: <AiOutlineStop />,
  },
  COM: {
    label: "Complete",
    label_past_tense: "Completed",
    color: "rgba(28, 191, 115, 1)",
    icon: <FaRegCircleCheck color={"rgba(28, 191, 115, 1)"} />,
    icon_no_color: <FaRegCircleCheck />,
  },
  NORES: {
    label: "Incomplete",
    label_past_tense: "Incomplete",
    color: "grey",
    icon: <IoPerson color={"grey"} />,
    icon_no_color: <IoPerson />,
  },
});

const Status = Object.freeze({
  Pending: "PEN",
  InProgress: "INP",
  Accepted: "ACC",
  Declined: "DEC",
  NotStarted: "DNS",
  Rescind: "RES",
  Trash: "TRS",
  Stopped: "STP",
  Completed: "COM",
  NoResponse: "NORES",
});

const getWebsite = async (userId, url) => {
  try {
    const response = await fetch(
      `${serverUrl}/getWebsite?userId=${userId}&&url=${url}`
    );

    if (!response.ok) {
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error("Error searching user by email:", error);
    return null;
  }
};
const generateMetaTag = async (url) => {
  try {
    let uid = sessionStorage.getItem("uid");
    if (!uid) return;
    const response = await fetch(`${serverUrl}/generateMetaTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid, url }),
    });

    if (!response.ok) {
      // Handle non-2xx HTTP responses
      return {
        success: false,
        message: `HTTP error! status: ${response.status}`,
      };
    }

    const data = await response.json();
    // Assuming the server responds with a JSON object that includes the metaTag
    return { success: true, metaTag: data.metaTag };
  } catch (error) {
    console.error("Error generating meta tag:", error);
    return { success: false, message: error.message };
  }
};

const checkForMetaTag = async (url) => {
  try {
    let uid = sessionStorage.getItem("uid");
    if (!uid) return;
    // Make the fetch request to the checkForMetaTag endpoint
    const response = await fetch(`${serverUrl}/checkForMetaTag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid, url }),
    });

    if (!response.ok) {
      // Handle HTTP errors
      return {
        success: false,
        message: `HTTP error! status: ${response.status}`,
      };
    }

    // Assuming the endpoint returns a JSON object indicating success and possibly additional data
    const data = await response.json();
    return { success: true, ...data };
  } catch (error) {
    console.error("Error checking meta tag:", error);
    return { success: false, message: error.message };
  }
};

const getChatData = async () => {
  try {
    let uid = sessionStorage.getItem("uid");
    if (!uid) return [];
  } catch {
    console.error("Could not get chats");
    return [];
  }
};

async function startNewChatWithUrls(
  user,
  activeURL,
  other_user_id,
  other_user_url
) {
  try {
    let other_user_data = await getUser(other_user_id);
    let chat_data = {
      participantIds: [auth.currentUser.uid, other_user_id],
      participantDetails: [
        {
          userId: auth.currentUser.uid,
          url: activeURL,
          icon: user.websites[activeURL].icon,
        },
        {
          userId: other_user_id,
          url: other_user_url,
          icon: other_user_data.websites[other_user_url].icon,
        },
      ],
      lastMessage: "",
      lastUpdated: serverTimestamp(),
    };
    console.log(chat_data);
    const chatRef = await addDoc(collection(db, "Chats"), chat_data);

    console.log("GOT THAT");
    console.log("New chat started with ID: ", chatRef.id);
    return chatRef.id; // Return the new chat's document ID
  } catch (error) {
    console.error("Error starting a new chat: ", error);
    return null;
  }
}

async function getChatWithUser(user, activeURL, other_user_id, other_user_url) {
  const chatsRef = collection(db, "Chats");
  const q = query(
    chatsRef,
    where("participantIds", "array-contains", auth.currentUser.uid)
  );

  let chats = (await getDocs(q)).docs;

  chats = chats.map((docu) => {
    return { ...docu.data(), id: docu.id };
  });
  chats = chats.filter((chat) => chat.participantIds.includes(other_user_id));

  let chatId = null;
  if (chats.length === 0) {
    chatId = await startNewChatWithUrls(
      user,
      activeURL,
      other_user_id,
      other_user_url
    );
  } else {
    chatId = chats[0].id;
  }
  console.log(chatId);
  return chatId;
}

async function sendPasswordResetLink(email) {
  try {
    const response = await fetch(`${serverUrl}/send-password-reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      // throw new Error(`HTTP error! status: ${response.status}`);
      return {
        success: false,
        message: (await response.text()) || "Error sending reset link.",
      };
    }

    // Assuming the server sends back a JSON response
    const data = await response.text();
    return {
      success: true,
      message: data || "Reset link sent successfully.",
    };
  } catch (error) {
    console.error("Error sending password reset link:", error);
    return {
      success: false,
      message: error.message || "Error sending reset link.",
    };
  }
}

async function sendMessageEmail(content, sender_uid, chatId) {
  try {
    // Prepare the request payload
    const payload = {
      sender_uid,
      message: content,
      chatId,
    };

    // Execute the fetch request to the notify-inactive-participants endpoint
    const response = await fetch(`${serverUrl}/notify-inactive-participants`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      return {
        success: false,
        message: "Failed to send message email",
      };
    }

    // Assuming the server might return a JSON with a message or other data
    const data = await response.json();
    console.log("Emails sent successfully:", data);
    return {
      success: true,
      message: "Emails sent successfully",
    };
  } catch (error) {
    console.error("Error sending message email:", error);
    return {
      success: false,
      message: error.message || "Failed to send message email",
    };
  }
}

export {
  addBetaUser,
  getWebsites,
  sendSignInLink,
  completeSignIn,
  getUser,
  updateSiteSettings,
  allTags,
  allMaterials,
  allContentTypes,
  proposeTrade,
  respondToOffer,
  byEmail,
  createUser,
  updateOffer,
  StatusDisplayRef,
  Status,
  getLabel,
  getWebsite,
  updateIcon,
  generateMetaTag,
  checkForMetaTag,
  giveFeedback,
  startNewChatWithUrls,
  getChatWithUser,
  sendPasswordResetLink,
  sendMessageEmail,
};
