import styled from "styled-components";
import { UserDataContext } from "../ProtectedRoute";
import { useContext, useEffect, useState } from "react";
import { generateMetaTag, checkForMetaTag } from "../dataLayer/stroage";
import { formatLink } from "../Global/utils";
import { FaRegCircleCheck } from "react-icons/fa6";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
const Verify = () => {
  const { user, setUser, activeURL } = useContext(UserDataContext);
  const site = user.websites[activeURL];
  const [loadingTag, setLoadingTag] = useState(false);
  const [loadCheck, setLoadCheck] = useState(false);
  const [error, setError] = useState(false);
  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    .top {
      margin-top: 0.8em;
      strong {
        color: rgba(28, 191, 115, 1);
        font-weight: 500;
      }
    }
    span {
      font-size: 0.7em;
      margin-bottom: 0.4em;
    }
    .tag {
      font-size: 0.7em;
    }

    .tag-snip {
    }

    .code-snip {
      border: 1px solid lightgrey;
      width: fit-content;
      border-radius: 5px;
      margin-top: 0.8em;
      margin-bottom: 1em;

      span {
        margin-bottom: 0;
      }

      .header {
        display: flex;
        align-items: center;
        span {
          font-size: 0.7em;
          padding: 0.2em 0.5em;
        }
      }

      .code-holder {
        border: 1px solid lightgrey;
        background: lightgray;

        code {
          font-size: 0.7em;
          margin: 0.6em 0.5em;

          width: fit-content;
          border-radius: 5px;
        }
      }
    }

    .check-holder {
      margin-top: 2em;
      display: flex;
      align-items: center;

      .error {
        margin-left: 1em;
        color: #ed4337;
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .check {
      cursor: pointer;
      background-color: rgba(28, 191, 115, 1);
      color: white;
      font-weight: 500;
      width: fit-content;
      padding: 0.4em 0.8em;
      border-radius: 5px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 0.5em;
      }

      #load {
        margin: 0;
        margin: 0 2em;
        animation: rotate 0.7s linear infinite;
      }
    }
  `;

  useEffect(() => {
    (async () => {
      if (!site.metaTag) {
        setLoadingTag(true);
        let res = await generateMetaTag(site.url);
        if (res.success) {
          let newUser = JSON.parse(JSON.stringify(user));
          newUser.websites[site.url].metaTag = res.metaTag;
          setUser(newUser);
        }
        setLoadingTag(false);
      }
    })();

    return () => {};
  }, []);

  return (
    <>
      <Wrapper>
        <h1>Verify Site</h1>
        <span className="top">
          Verify ownership of{" "}
          <strong>{formatLink(user.websites[activeURL].url)} </strong>
          by adding a meta tag
        </span>

        {loadingTag ? (
          <span>generating tag ...</span>
        ) : (
          <>
            {/* <div className="tag-snip">
              <span>Your tag is </span>
              <span className="tag">
                {site.metaTag ?? "Error generating tag, try again later."}
              </span>
            </div> */}
            <div className="code-snip">
              <div className="header">
                <span>HTML</span>
              </div>
              <div className="code-holder">
                <code typeof="html">{`<meta name="linktradr-tag" content="${site.metaTag}"/>`}</code>
              </div>
            </div>

            <span>
              The <b>name</b> of the tag should be <b>linktradr-tag</b>
            </span>
            <span>
              The <b>content</b> of the tag should be <b>{site.metaTag}</b>
            </span>

            <div className="check-holder">
              <span
                className="check"
                onClick={async () => {
                  if (site.verified) {
                    return;
                  }
                  setError(false);
                  setLoadCheck(true);
                  let res = await checkForMetaTag(activeURL);
                  if (!res.success) {
                    setError(true);
                  } else {
                    let newUser = JSON.parse(JSON.stringify(user));
                    newUser.websites[activeURL].verified = true;
                    setUser(newUser);
                  }
                  console.log("NEW EWS", res);
                  setLoadCheck(false);
                }}
              >
                {site.verified ? (
                  <>
                    Verified <FaRegCircleCheck />
                  </>
                ) : (
                  <>
                    {" "}
                    {loadCheck ? (
                      <AiOutlineLoading3Quarters id="load" />
                    ) : error ? (
                      "Check again"
                    ) : (
                      "Check site"
                    )}
                  </>
                )}
              </span>
              {error && <span className="error">Could not find your tag</span>}
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Verify;
