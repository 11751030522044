import styled from "styled-components";
import { Link } from "react-router-dom";
function Header() {
  const Wrapper = styled.div`
    width: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1em 5%;
    }

    .logo {
      //font-weight: bold;
      font-weight: 200;
      font-size: 1.2em;
    }

    .rightHeader {
      display: flex;
      align-items: center;
      justify-content: end;
      white-space: nowrap;
      width: 20%;

      a {
        text-decoration: none;
        color: inherit;
      }
      .signIn {
        font-size: medium;
        font-weight: medium;
        margin-right: 1.5em;
      }

      .join {
        font-size: medium;
        border: 1px solid rgb(34, 35, 37);
        padding: 0.5em 1em;
        border-radius: 3px;
        font-weight: bold;
      }
    }
  `;
  return (
    <Wrapper>
      <div className="header">
        <a className="logo">linktradr</a>
        <div className="rightHeader">
          <a className="signIn" href="/login">
            Login
          </a>
          <a className="join" href="/signup">
            Join
          </a>
        </div>
      </div>
    </Wrapper>
  );
}
export default Header;
