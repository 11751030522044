import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { useRef } from "react";
import {
  sendEmailLink,
  signUpWithEmailAndPassword,
  loginWithEmailAndPassword,
  userExist,
} from "../AuthContext";
import { signInWithEmailAndPassword, useAuth } from "../AuthContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase-config";
import { useEffect, useState, useContext } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { GoArrowLeft } from "react-icons/go";
import { addBetaUser } from "../dataLayer/stroage";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { UserDataContext } from "../ProtectedRoute";
const beta = true;
function NewSite({ close = () => {} }) {
  const { user, setActiveUrl, setUser } = useContext(UserDataContext);
  const navigate = useNavigate();
  const [error, setError] = useState({ show: false, message: "" });
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const checks = useRef({
    "email-input": false,
    "password-input": false,
    "url-input": false,
  });

  // Prevent body from scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const Wrapper = styled.div`
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999;

    display: flex;
    flex-direction: column;

    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 600px) {
      background: gray;
    }

    .textSection {
      display: flex;
      flex-direction: column;
      h1 {
        margin: 0.2em 0;

        @media screen and (max-width: 600px) {
          font-size: 2.7em;
          margin-top: 20%;
        }
      }

      strong {
        margin-bottom: 2em;
        font-weight: 300;
        //text-decoration: underline;
        font-size: 0.9em;
        @media screen and (max-width: 600px) {
          font-size: 1.2em;
        }
      }

      span {
        font-size: 0.6em;
        text-decoration: underline;
        @media screen and (max-width: 600px) {
          font-size: 1em;
        }
      }
    }

    .errorMessage {
      font-size: 0.6em;
      color: #ed4337;
      margin-top: 0.2em;
      margin-bottom: 0.5em;

      @media screen and (max-width: 600px) {
        font-size: 0.9em;
      }
    }

    .emailMessage {
      font-size: 0.8em;
      color: gray;
      margin-top: 0.2em;
      @media screen and (max-width: 600px) {
        font-size: 1em;
      }
    }

    .holder {
      overflow-y: scroll;

      background: white;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 16em;
      border-radius: 10px;
      max-height: 90vh;
      min-height: fit-content;
      overflow-y: scroll;
      @media screen and (max-width: 600px) {
        border-radius: 30px;
        width: auto;
        height: 100%;
        border-radius: 5px;
        width: 100%;
      }

      .top {
        padding: 1.2em 1.5em;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.1em;
      .logo {
        //font-weight: bold;
        font-weight: 200;
        font-size: 1.4em;
        @media screen and (max-width: 600px) {
          font-size: 1.7em;
        }
      }
      .close {
        font-size: 0.9em;
        color: grey;
        @media screen and (max-width: 600px) {
          font-size: 1.4em;
        }
      }
    }

    .title {
      margin: 0.1em 0em;
      font-weight: bold;
      font-size: 1.3em;
      @media screen and (max-width: 600px) {
        font-size: 1.7em;
        margin-top: 1.2em;
      }
    }

    .formType {
      font-size: 0.7em;
      font-weight: 400;

      @media screen and (max-width: 600px) {
        font-size: 1em;
      }
      a {
        text-decoration: underline;
        font-weight: 200;
        color: steelblue;
      }
    }

    form {
      margin-top: 0.5em;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        margin-top: 0.5em;
      }
      .emailInput {
        display: flex;
        flex-direction: column;
        margin-top: 1em;

        .input-check {
          font-size: 0.55em;
          margin: 0.1em 0;
          padding: 0;

          @media screen and (max-width: 600px) {
            font-size: 0.8em;
          }
        }

        span {
          font-size: 0.7em;
          font-weight: 500;
          margin-bottom: 0.5em;
          user-select: none;
          @media screen and (max-width: 600px) {
            font-size: 1em;
          }
        }

        input,
        textarea {
          width: calc(100% - 1.4em);
          border: 1px solid lightgray;
          font-size: 0.7em;

          border-radius: 5px;
          padding: 0.6em 0.7em;
          margin-bottom: 0.5em;
          outline: none;
          resize: none;
          @media screen and (max-width: 600px) {
            font-size: 1em;
          }
        }
      }
      .password {
        .inputHolder {
          position: relative;
          svg {
            position: absolute;
            top: 20%;
            right: 0.5em;
            font-size: 0.9em;
            color: gray;
            cursor: pointer;
            @media screen and (max-width: 600px) {
              font-size: 1.2em;
              top: 23%;
            }
          }
        }
      }

      .linkdir {
        color: grey;
        font-size: 0.7em;
        @media screen and (max-width: 600px) {
          font-size: 1em;
        }
      }

      .forgot-password {
        text-align: right;
        text-decoration: underline;
        margin-bottom: 0.5em;
        font-size: 0.6em;
        cursor: pointer;
        color: black;

        @media screen and (max-width: 600px) {
          font-size: 0.9em;
        }
      }

      #description-input {
        height: 4em;
        font-size: 0.6em;

        @media screen and (max-width: 600px) {
          font-size: 0.9em;
        }
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      input[type="submit"],
      button[type="submit"] {
        border-radius: 8px;

        margin-bottom: 0.5em;
        background: black;
        color: white;
        font-weight: bold;
        margin-top: ${error.show ? "0.3em" : "1em"};
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        justify-items: center;

        font-size: 0.7em;
        padding: ${loading ? "0.55em 0.7em" : "0.7em 0.7em"};
        @media screen and (max-width: 600px) {
          font-size: 1em;
        }

        svg {
          font-size: 1.3em;
          animation: rotate 1s linear infinite;
        }
      }
    }
    .footer {
      border-top: 1px solid lightgray;
      padding: 0em 1.5em;
      padding-bottom: 1em;
      p {
        font-size: 0.65em;
        color: slategray;
        padding: 0;
        margin: 0;
        margin-top: 1.5em;

        font-size: 0.55em;
        @media screen and (max-width: 600px) {
          font-size: 0.85em;
        }
      }
    }
  `;
  const passRef = useRef("");
  const focus = useRef("");
  const emailRef = useRef("");
  const urlRef = useRef("");
  const descriptionRef = useRef("");

  const submit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }
    setLoading(true);
    setError({ show: false });
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    await sleep(2000);

    let url = urlRef.current;
    let res = await addBetaUser({
      url,
      email: user.email,
      category: "blog",
      description: descriptionRef.current,
    });
    if (!res.success) {
      console.log("message", res.message);
      setError({
        show: true,
        message: "There was an error adding your website",
      });
      return;
    }
    setLoading(false);

    console.log("RES", res);
    if (res.user) {
      setUser(res.user);
      setActiveUrl(url);
      close();
    } else {
      navigate("/mysite");
      close();
    }
  };

  function showInput() {
    try {
      var passwordInput = document.getElementById("password-input");
      var showIcon = document.getElementById("show");
      var hideIcon = document.getElementById("invis");
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        showIcon.style.display = "none"; // Hide the show icon
        hideIcon.style.display = "inline"; // Show the hide icon
      } else {
        passwordInput.type = "password";
        showIcon.style.display = "inline"; // Show the show icon
        hideIcon.style.display = "none"; // Hide the hide icon
      }
    } catch (error) {
      console.log("Error toggling password visibility:", error);
    }
  }

  const Input = ({ refe, type, inputId, initConstraints = [] }) => {
    const [value, setValue] = useState(refe.current);
    const [constraints, setConstraints] = useState(initConstraints);

    useEffect(() => {
      checks.current[inputId] = true;
      constraints.forEach((c) => {
        if (!c.check(value)) {
          checks.current[inputId] = false;
        }
      });

      let submits = document.querySelectorAll(
        'input[type="submit"], button[type="submit"]'
      );
      submits.forEach((s) => {
        let valid = true;
        for (let input of JSON.parse(s.getAttribute("checks"))) {
          if (!checks.current[input]) {
            valid = false;
          }
        }

        if (!loading) {
          if (!valid) {
            s.style.background = "grey";
            s.disabled = true;
          } else {
            s.style.background = "black";
            s.disabled = false;
          }
        }
      });
    }, [value]);

    return (
      <>
        <div className="inputHolder">
          {inputId != "description-input" ? (
            <input
              type={type}
              autoFocus={focus.current == type}
              onFocus={() => {
                if (error.show) {
                  focus.current = type;
                  setError({ ...error, show: false });
                }
              }}
              onChange={(e) => {
                refe.current = e.target.value;
                setValue(e.target.value);
              }}
              value={value}
              placeholder={type == "email" && "name@email.com"}
              id={inputId}
            ></input>
          ) : (
            <textarea
              rows="5"
              columns="20"
              value={value}
              id={inputId}
              autoFocus={focus.current == type}
              onFocus={() => {
                if (error.show) {
                  focus.current = type;
                  setError({ ...error, show: false });
                }
              }}
              onChange={(e) => {
                refe.current = e.target.value;
                setValue(e.target.value);
              }}
            ></textarea>
          )}

          {inputId == "password-input" && (
            <>
              <AiOutlineEye
                id="show"
                onClick={showInput}
                style={{ display: "none" }}
              />
              <AiOutlineEyeInvisible id="invis" onClick={showInput} />
            </>
          )}
        </div>
        {constraints.map((c) => (
          <>
            {(!c.check(value) || (c.check(value) && c.showOnComplete)) && (
              <span
                className="input-check"
                style={{
                  color: c.check(value) ? "rgb(28,191,115)" : "grey",
                  textDecoration: c.check(value) && "line-through",
                }}
              >
                {c.text}
              </span>
            )}
          </>
        ))}
      </>
    );
  };

  //   const formRef = useRef({
  //     url: preUrl ? preUrl : "",
  //     email: "",
  //     category: null,
  //     description: "",
  //   });

  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }
  const isValidEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  return (
    <Wrapper
      onClick={(e) => {
        let holder = document.querySelector("#new-site-holder");
        if (holder && !holder.contains(e.target)) {
          close();
        }
      }}
    >
      <div className="holder" id="new-site-holder">
        <div className="top">
          <div className="header">
            <a className="logo">linktradr</a>

            <a className="close" onClick={() => close()}>
              <IoMdClose />
            </a>
          </div>
          <div className="title">Growth starts here.</div>
          <span className="formType">Let's start with your website!</span>

          <form onSubmit={submit}>
            <div className="emailInput">
              <span>Website url</span>
              <Input
                refe={urlRef}
                type="text"
                inputId="url-input"
                initConstraints={[
                  {
                    text: "Looks like this link is incomplete",
                    check: isValidURL,
                    showOnComplete: false,
                  },
                ]}
              ></Input>
            </div>
            <div className="emailInput">
              <span>Description</span>
              <Input
                refe={descriptionRef}
                type="text"
                inputId="description-input"
                initConstraints={[
                  {
                    text: "Minimum 30 characters",
                    check: (str) => str.trim().length >= 30,
                    showOnComplete: false,
                  },
                ]}
              ></Input>
            </div>
            {/* <span className="linkdir">You will receive a sign-in link</span> */}
            {error.show && (
              <span className="errorMessage">{error.message}</span>
            )}
            <button
              type="submit"
              disabled={loading && true}
              style={{ background: loading && "grey" }}
              checks={JSON.stringify(["url-input", "description-input"])}
            >
              {loading ? <AiOutlineLoading3Quarters /> : "Add website"}
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
}
export default NewSite;
