import { styled } from "styled-components";
import { RiCheckFill, RiCloseFill, RiCopperCoinFill } from "react-icons/ri";
import { useEffect, useState, useContext, useRef } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import {
  allContentTypes,
  allMaterials,
  proposeTrade,
  getUser,
} from "../dataLayer/stroage";
import Error from "../Global/Error";
import { formatLink } from "../Global/utils";

import { UserDataContext } from "../ProtectedRoute";
import { Status } from "../dataLayer/stroage";
const TradeTerms = ({ data }) => {
  const { user, activeURL } = useContext(UserDataContext);
  let close = data.close;
  let postData = data;
  console.log("TERMS", postData, user);
  const [message, setMessage] = useState({ show: false, message: "" });
  const trade = useRef({
    reciver: postData.userId,
    sender: user.userId,
    [postData.userId]: {
      create_content_type: null,
      provide_materials: [],
      status: Status.NotStarted,
      url: postData.url,
    },
    [user.userId]: {
      create_content_type: null,
      provide_materials: [],
      status: Status.NotStarted,
      url: activeURL,
    },
    status: Status.Pending,
    connects: postData.connects,
  });

  const [heart, setHeart] = useState(false);
  const Wrapper = styled.div`
    padding: 1.2em;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100vh;
    overflow-y: scroll;

    @media screen and (max-width: 600px) {
      padding: 1.5em 1.7em;
    }
    .content {
      overflow-y: scroll;
    }

    .controls {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;

      .back {
        font-size: 0.9em;
        cursor: pointer;

        @media screen and (max-width: 600px) {
          font-size: 1.2em;
        }
      }

      .heart {
        font-size: 0.9em;
        cursor: pointer;
        transition: 0.5s;
        @media screen and (max-width: 600px) {
          font-size: 1.2em;
        }
      }
      .heart:hover {
        transform: translateY(-3px);
        color: rgba(28, 191, 115, 1);
      }
    }
    .site {
      color: rgba(28, 191, 115, 1);
      padding: 0;
    }
    .title {
      font-weight: bold;
      font-size: 1.1em;
      margin-bottom: 0.4em;
      white-space: pre-wrap;
      padding: 0;
      @media screen and (max-width: 600px) {
        font-size: 1.6em;
        margin-top: 0;
        margin-bottom: 0.7em;
        display: flex;
        flex-direction: column;
      }
    }

    .section {
      margin: 0.5em 0;

      @media screen and (max-width: 600px) {
        margin: 1.5em 0;
      }

      .sectionTitle {
        font-size: 0.8em;
        /* font-weight: bold; */
        //color: #4caf50;
        @media screen and (max-width: 600px) {
          font-size: 1.1em;
        }
      }

      .subTitle {
        font-size: 0.65em;
        color: grey;
        display: block;
        margin-top: 0.3em;

        @media screen and (max-width: 600px) {
          font-size: 1em;
        }
      }

      .optionHolder {
        width: 100%;
        display: flex;
        grid-gap: 0.5em;
        margin-top: 0.7em;
        flex-wrap: wrap;
        .option {
          font-size: 0.7em;
          border: 1px solid grey;
          padding: 0.2em 0.5em;
          border-radius: 3px;
          width: fit-content;
          white-space: nowrap;
          transition: 0.5s;
          cursor: pointer;

          @media screen and (max-width: 600px) {
            font-size: 1em;
          }
        }
        .option:hover {
          transform: translateY(-3px);
          color: white;
          border: 1px solid rgba(28, 191, 115, 1);
          background: rgba(28, 191, 115, 0.6);
        }
      }

      p {
        margin: 0;
        margin-top: 0.5em;
        font-size: 0.7em;
        //border: 1px solid grey;
        //padding: 0.2em 0.5em;
        color: grey;
        border-radius: 3px;

        @media screen and (max-width: 600px) {
          font-size: 1em;
        }
      }
    }

    .otherLinks {
      display: flex;
      align-items: center;
      margin-top: 0.85em;

      span {
        font-size: 0.7em;
      }
      svg {
        font-size: 0.8em;
        color: #4caf50;
        border: 1px solid grey;
        padding: 0.05em;
        border-radius: 3px;
        width: fit-content;
        margin-right: 0.5em;
      }
    }

    .trade {
      margin-top: 0.85em;
      font-size: medium;
      background: rgba(28, 191, 115, 1);
      padding: 0.3em 0;
      border-radius: 8px;
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.85em;
      margin-bottom: 2.5em;

      cursor: pointer;

      @media screen and (max-width: 600px) {
        font-size: 1.1em;
      }

      svg {
        display: grid;
        margin-left: 0.3em;
        font-size: 1em;

        @media screen and (max-width: 600px) {
          font-size: 1.1em;
        }
      }
    }

    .note {
      height: 5em;
      border: 1px solid black;
      display: none;
    }
  `;

  const Options = ({ arr, list, user, type }) => {
    const [reloadSate, setReload] = useState(true);

    return (
      <div className="optionHolder">
        {arr.map((t) => (
          <Option
            list={list}
            user={user}
            type={type}
            id={t}
            reload={() => setReload(!reloadSate)}
          ></Option>
        ))}
      </div>
    );
  };
  const Option = ({ id = "", list, user, type, reload = () => {} }) => {
    let isSelected =
      trade.current[user][type] == id ||
      (Array.isArray(trade.current[user][type]) &&
        trade.current[user][type].includes(id));

    let label = list.filter((t) => t.id == id)[0].label;
    console.log(isSelected, id);
    return (
      <div
        className="option"
        style={{
          background: isSelected && "rgba(28, 191, 115, 1)",
          border: isSelected && "1px solid rgba(28, 191, 115, 1)",
          color: isSelected && "white",
        }}
        onClick={() => {
          let newTrade = JSON.parse(JSON.stringify(trade.current));
          if (!isSelected) {
            Array.isArray(trade.current[user][type])
              ? newTrade[user][type].push(id)
              : (newTrade[user][type] = id);
          } else {
            Array.isArray(trade.current[user][type])
              ? (newTrade[user][type] = newTrade[user][type].filter(
                  (t) => t != id
                ))
              : (newTrade[user][type] = null);
          }
          trade.current = newTrade;
          reload();
        }}
      >
        <span>{label}</span>
      </div>
    );
  };

  return (
    <>
      <Wrapper className="tradeTerms">
        <div className="controls">
          <div className="slide-out-close">
            <FaAngleLeft />
          </div>
          {/* <div className="heart" onClick={() => setHeart(!heart)}>
            {heart ? (
              <FaHeart style={{ color: "rgba(28, 191, 115, 1)" }} />
            ) : (
              <FaRegHeart />
            )}
          </div> */}
        </div>
        <div className="content">
          <p className="title">
            <span style={{ color: "rgba(28, 191, 115, 1)" }}>
              {formatLink(postData.url)}{" "}
            </span>
            is looking for...
          </p>

          <div className="section">
            <span className="sectionTitle">Content type</span>
            <span className="subTitle">Select what media you will create</span>
            <Options
              list={allContentTypes}
              user={user.userId}
              type={"create_content_type"}
              arr={postData.contentTypes}
            ></Options>
          </div>
          <div className="section">
            <span className="sectionTitle">Content type</span>
            <span className="subTitle">
              Select what media{" "}
              <span className="site">{formatLink(postData.url)}</span> will
              create
            </span>

            <Options
              list={allContentTypes}
              user={postData.userId}
              type={"create_content_type"}
              arr={postData.contentTypes}
            ></Options>
          </div>
          <div className="section">
            <span className="sectionTitle">Provided materials</span>
            <span
              className="subTitle"
              style={{ whiteSpace: "nowrap", overflowX: "hidden" }}
            >
              Select what you need from{" "}
              <span className="site">{formatLink(postData.url)}</span>
            </span>
            <Options
              list={allMaterials}
              user={postData.userId}
              type={"provide_materials"}
              arr={postData.materials}
            ></Options>
          </div>

          <div className="section">
            <span className="sectionTitle">Description</span>
            <p>{postData.description}</p>
          </div>

          <div className="section">
            <p className="note"></p>
          </div>
          <div
            className="trade"
            onClick={async () => {
              if (
                !trade.current[postData.userId].create_content_type ||
                !trade.current[user.userId].create_content_type
              ) {
                setMessage({
                  show: true,
                  message: "Please select a content type.",
                });
                return;
              }
              let res = await proposeTrade(trade.current);
              if (res.status) {
                close();
              } else {
                setMessage({
                  show: true,
                  message:
                    res.message ?? "There was an error sending your offer.",
                });
              }
            }}
          >
            <span>Trade for {postData.connects}</span>
            <RiCopperCoinFill />
          </div>
        </div>
      </Wrapper>
      {message.show && (
        <Error
          close={() => {
            setMessage({ show: false });
          }}
          message={message.message}
        />
      )}
    </>
  );
};
export default TradeTerms;
