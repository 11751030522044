import BHeader from "../Global/BHeader";
import styled from "styled-components";
import { IoReloadCircleOutline } from "react-icons/io5";
import { IoArrowUp } from "react-icons/io5";
import { RiCopperCoinFill } from "react-icons/ri";
import { LuPencil } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";
import { IoMdSwap } from "react-icons/io";
import { useParams } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa6";
import { useAuth } from "../AuthContext";
import { useState } from "react";
import { formatLink } from "../Global/utils";
import { IoMdAdd } from "react-icons/io";
import {
  getUser,
  updateSiteSettings,
  allContentTypes,
  allMaterials,
  respondToOffer,
  updateOffer,
  getWebsite,
  updateIcon,
  getWebsites,
  Status,
} from "../dataLayer/stroage";
import { FaPlus } from "react-icons/fa6";
import Footer from "../Market/Footer";
import { IoIosMore } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import SlideOut from "../Global/SlideOut";
import { useEffect, useRef, useContext } from "react";
import Offers from "./Offers";
import Trades from "./Trades";
import Details from "./Details";
import { useNavigate, Navigate } from "react-router-dom";
import StripePricingTable from "../Connects/StripePricingTable";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiLoader5Line } from "react-icons/ri";
import Verify from "./Verify";
import { UserDataContext } from "../ProtectedRoute";
import NewSite from "./NewSite";
import VerifySite from "../Global/VerifySite";
import Chat from "./Chat";
const MySite = ({ data }) => {
  let navigate = useNavigate();
  const [showNewSite, setShowNewSite] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [siteSelectorOpen, setSiteSelectorOpen] = useState(false);
  // const [slideOut, setSlideOut] = useState({
  //   show: false,
  //   data: null,
  // });

  const { page } = useParams();
  //const [user, setUser] = useState(data.userInfo);
  const { user, setUser, activeURL, setActiveUrl } =
    useContext(UserDataContext);

  let site = user.websites[activeURL];

  const descriptionRef = useRef({ url: site.url, des: site.description });
  if (activeURL !== descriptionRef.current.url) {
    descriptionRef.current = { url: site.url, des: site.description };
  }
  console.log("SITE", site);
  if (!user) {
    navigate(0);
    return null;
  }

  if (!site.tags) site.tags = [];
  if (!site.materials) site.materials = [];
  if (!site.contentTypes) site.contentTypes = [];
  function sortOptions(all, selected) {
    all.sort((a, b) => {
      const isSelectedA = selected.includes(a.id);
      const isSelectedB = selected.includes(b.id);

      if (isSelectedA && !isSelectedB) {
        return -1;
      } else if (!isSelectedA && isSelectedB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  let allTags = [];

  data.posts.forEach((s) => (allTags = allTags.concat(s.tags)));

  allTags = Array.from(new Set(allTags));
  allTags = allTags.map((m) => {
    return { label: m, id: m };
  });
  //let allTags = user.websites[activeURL].tags;
  sortOptions(allTags, site.tags);
  sortOptions(allMaterials, site.materials);
  sortOptions(allContentTypes, site.contentTypes);

  const Wrapper = styled.div`
    display: flex;
    text-align: left;
    margin-bottom: 1em;
    .siteswitcher {
      cursor: pointer;
      padding: 0.3em 0.5em;
      background: rgba(28, 191, 115, 0.8);
      color: white;
      //display: flex;
      justify-content: space-between;
      align-items: center;
      //border: 1px solid gray;

      text-align: left;

      margin-bottom: 1em;
      border-radius: 4px;
      font-weight: 600;
      font-size: 0.8em;
      position: relative;

      @media screen and (max-width: ${"500px"}) {
        font-size: 1.2em;
      }

      @media screen and (max-width: 950px) {
        width: fit-content;
        font-size: 1em;
      }

      .options {
        position: absolute;
        background: white;
        top: calc(100% + 0.2em);
        width: 100%;
        left: 0;
        z-index: 999999;
        border: 1px solid grey;

        border-radius: 5px;

        .option {
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0.3em 0;
          color: black;
          font-weight: 400;
          font-size: 0.85em;
          //background: rgba(28, 191, 115, 0.4);

          //border: 1px solid red;

          span {
            display: flex;
            padding-right: 0.1em;
          }
          svg {
            margin: 0;
            font-size: 0.9em;
            margin-right: 0.4em;
            margin-left: 0.5em;
          }
        }
        .option:hover {
          background: rgba(28, 191, 115, 0.4);
        }
      }
      span {
        width: calc(100% - 1em);
        overflow-x: hidden;
      }

      svg {
        min-width: 1em;
        margin-left: 0.5em;
      }
    }
    .left {
      width: 25%;

      @media screen and (max-width: 950px) {
        display: none;
      }

      .holder {
        display: flex;
        flex-direction: column;
        padding: 1.7em;
        padding-top: 1em;
      }

      .nav {
        font-size: 0.7em;
        color: gray;
        margin-top: 0.5em;
        cursor: pointer;
        text-decoration: none;
      }

      .nav:hover {
        color: black;
        text-decoration: underline;
      }
    }

    .trades {
      margin-top: 1.5em;
    }
    .right {
      width: 75%;
      padding-right: 2em;
      margin-top: 1em;
      @media screen and (max-width: 950px) {
        width: 100%;
        padding-left: 1.5em;
        padding-right: 1.5em;
      }

      h1 {
        font-size: 1.2em;
        margin: 0;
        padding: 0;
        font-weight: 400;

        @media screen and (max-width: 950px) {
          font-size: 1.6em;
        }
      }

      .stats {
        color: black;
        display: flex;
        margin-top: 1em;

        @media screen and (max-width: 950px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .stat {
          width: 25%;
          border-left: 1px solid lightgray;
          padding-left: 0.7em;

          @media screen and (max-width: 950px) {
            width: 100%;
            margin-bottom: 1em;
          }
          .num {
            font-size: 1.3em;
            //color: gray;
            @media screen and (max-width: 950px) {
              font-size: 1.6em;
            }
          }
          .text {
            margin-top: 0.3em;
            display: flex;
            align-items: center;
            margin-bottom: 0.2em;
            .color {
              height: 0.45em;
              width: 0.45em;
              min-height: 0.45em;
              min-width: 0.45em;
              background: rgb(254, 199, 51);
              border-radius: 50%;
              @media screen and (max-width: 950px) {
                height: 0.6em;
                width: 0.6em;
                min-height: 0.6em;
                min-width: 0.6em;
              }
            }
            .label {
              margin-left: 0.6em;
              font-size: 0.7em;
              font-weight: 500;
              color: black;

              @media screen and (max-width: 950px) {
                font-size: 0.9em;
                margin-bottom: 0.2em;
              }
            }
          }
        }
      }
    }
  `;
  const SiteTab = ({}) => {
    const [logoLoading, setLogoLoading] = useState(false);
    let score = Math.ceil(site.score);
    let color = "black";
    if (score < 20) {
      color = "#E32636";
    } else if (score < 50) {
      color = "#FF7518"; //"#F28C28";
    } else if (score < 70) {
      color = "#F4C430";
    } else {
      color = "#1CAC78";
    }

    const Wrapper = styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 1em;
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      .left {
        display: flex;
        align-items: center;
        .imgHolder {
          position: relative;

          img {
            width: 2em;
            object-fit: contain;
            border-radius: 50%;
            /* border: 1px solid lightgray; */
          }

          svg {
            animation: rotate 0.7s linear infinite;
            font-size: 1.5em;
            color: gray;
          }
          .edit {
            cursor: pointer;
            position: absolute;
            bottom: 5px;
            right: -4px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            svg {
              font-size: 1em;
              font-weight: bold;
              color: rgba(28, 191, 115, 1);
              animation: none;
            }
          }
        }

        .textHolder {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: space-between;
          margin-left: 1em;
          h4 {
            padding: 0;
            margin: 0;
            font-size: 1em;
          }
          a {
            font-size: 0.5em;
            text-decoration: none;
            /* background: rgba(28, 191, 115, 1); */
            padding: 0.15em 0.6em;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${site.verified ? "rgba(28, 191, 115, 1)" : "#ed4337"};
            border: 1px solid
              ${site.verified ? "rgba(28, 191, 115, 1)" : "#ed4337"};
            /* font-weight: bold; */
          }
        }
      }

      .right {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0;

        .connectsHolder {
          display: none;
          margin-left: 1.5em;
          .connects {
            font-size: xxx-large;
            font-weight: bold;
            //color: ${color};
          }
          .label {
            svg {
              font-size: 1.2em;
              margin-left: 0.5em;
              color: rgba(28, 191, 115, 1);
            }
          }
        }

        .tradesHolder {
          margin-left: 1.5em;
          .trades {
            font-size: xx-large;
            font-weight: bold;
            color: ${color};
          }
          .label {
            margin-left: 0.5em;
            font-size: 0.9em;
          }
        }

        .scoreHolder {
          margin-left: 1.5em;
          .score {
            font-size: 2em;
            font-weight: bold;
            color: ${color};
          }
          .label {
            margin-left: 0.5em;
            font-size: 0.9em;
          }
        }

        .upScore {
          margin-left: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          aspect-ratio: 1/1;
          background: white;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px dashed rgba(28, 191, 115, 0.8);
          svg {
            font-size: 0.9em;
            border-radius: 50%;
            background: rgba(28, 191, 115, 0.8);
            padding: 0.2em;
          }
        }
      }
    `;
    return (
      <Wrapper className="main">
        <div className="left">
          <div className="imgHolder">
            {logoLoading ? (
              <AiOutlineLoading3Quarters />
            ) : (
              <>
                <img
                  src={
                    site.icon ? site.icon : "https://linktradr.com/favicon.ico"
                  }
                ></img>
                <div
                  className="edit"
                  onClick={async () => {
                    setLogoLoading(true);
                    let data = await updateIcon();
                    if (data) {
                      setUser(data);
                    }
                    setLogoLoading(false);
                  }}
                >
                  <IoReloadCircleOutline />
                </div>
              </>
            )}
          </div>
          <div className="textHolder">
            <h4>{formatLink(site.url)}</h4>
            <a href="/mysite/verify">
              {site.verified ? "Verified" : "Not verified"}
            </a>
          </div>
        </div>
        <div className="right">
          {/* <div className="connectsHolder">
            <span className="connects">{trades}</span>
            <span className="label">
              <RiCopperCoinFill></RiCopperCoinFill>
            </span>
          </div> */}
          <div className="scoreHolder">
            <span className="score">{score}</span>
            <span className="label">Authority</span>
          </div>
          {/* <div className="upScore">
            <IoArrowUp></IoArrowUp>
          </div> */}
        </div>
      </Wrapper>
    );
  };

  //   {
  //     "domain_authority": 22,
  //     "on_page_seo": 40,
  //     "technical_seo": 30,
  //     "links": 590497,
  //     "ssl": 10,
  //     "speed": 34
  // }
  const YourScore = ({}) => {
    const Wrapper = styled.div`
      margin-top: 1em;

      .header {
        display: flex;
        /* align-items: flex-end; */
        justify-content: space-between;
        margin-bottom: 1em;

        .left {
          display: flex;
          flex-direction: column;
          min-width: max-content;
        }
        span {
          color: grey;
          font-size: 0.7em;
          /* margin-bottom: 0.3em; */
          /* margin-left: 1em; */
        }
        .scoreHolder {
          margin-left: 1.5em;
          .score {
            font-size: 2em;
            font-weight: bold;
          }
          .label {
            color: black;
            margin-left: 0.5em;
            font-size: 0.9em;
          }
        }
      }

      .sub-heading {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .sub-title {
          font-size: 0.8em;
        }

        .not-right {
          font-size: 0.7em;
          color: black;
          text-align: right;
          margin-bottom: 0.5em;
        }

        strong {
          font-weight: 500;
          color: rgba(28, 191, 115, 1);
        }
      }

      /* .attributes {
        display: grid;

        .section {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 0.8em;
          }
          .value {
            font-size: 0.8em;
          }
        }
      } */

      .attributes {
        display: grid;
        grid-gap: 20px; /* Space between cards */
        grid-template-columns: repeat(
          auto-fit,
          minmax(150px, 1fr)
        ); /* Creates a responsive grid */
      }

      .section {
        background-color: #f0f0f0; //rgb(242, 245, 250); /* Light grey background for each card */
        padding: 10px; /* Spacing inside each card */
        border-radius: 5px; /* Rounded corners for the cards */
        display: flex;
        flex-direction: column;
        overflow: scroll;
      }

      .title {
        font-weight: bold; /* Makes the title bold */
      }

      .value {
        font-size: 2em;
      }

      /* Example Media Query for larger screens */
      @media (min-width: 768px) {
        .attributes {
          grid-template-columns: repeat(
            auto-fit,
            minmax(250px, 1fr)
          ); /* Adjusts the minimum card width for larger screens */
        }

        .title {
          font-size: 0.9em;
          font-weight: 600; /* Makes the title bold */
        }
        .value {
          font-size: 1.2em;
        }
      }
    `;
    console.log(site);

    function toTime(timestamp) {
      try {
        console.log(timestamp);
        const milliseconds =
          timestamp._seconds * 1000 + Math.round(timestamp._nanoseconds / 1e6);

        // Create Date object
        const date = new Date(milliseconds);

        const today = new Date();
        const isToday =
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear();

        if (isToday) {
          // If timestamp is today, return the time
          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // 12-hour format
          return `${hours}:${minutes} ${ampm}`;
        } else {
          // If timestamp is not today, return the date
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
          const year =
            date.getFullYear() !== today.getFullYear()
              ? `/${date.getFullYear()}`
              : "";
          return `${month}/${day} ${year}`;
        }
      } catch {
        return "";
      }
    }

    let score = Math.ceil(site.score);
    let color = "black";
    if (score < 20) {
      color = "#E32636";
    } else if (score < 50) {
      color = "#FF7518"; //"#F28C28";
    } else if (score < 70) {
      color = "#F4C430";
    } else {
      color = "#1CAC78";
    }

    return (
      <Wrapper>
        <div className="header">
          <div className="left">
            <h1>Your score</h1>
            <span>
              Last run on{" "}
              {site.scoreAttributes.run_date
                ? toTime(site.scoreAttributes.run_date)
                : new Date(2024, 2, 7).toDateString()}
            </span>
          </div>
          <div className="scoreHolder">
            <span className="score" style={{ color }}>
              {score}
            </span>
            <span className="label">Authority</span>
          </div>
        </div>
        <div className="sub-heading">
          <span className="sub-title">
            Here is the information we were able to gather about{" "}
            <strong>{formatLink(site.url)}</strong>
          </span>
          <a className="not-right" href={"/market?feedback=true"}>
            Something not right?
          </a>
        </div>

        <div className="attributes">
          <div className="section">
            <span className="title">Domain Authority</span>
            <span className="value">
              {site.scoreAttributes.domain_authority
                ? site.scoreAttributes.domain_authority + "%"
                : "N/A"}
            </span>
          </div>
          <div className="section">
            <span className="title">On-page SEO</span>
            <span className="value">
              {site.scoreAttributes.on_page_seo
                ? Math.ceil((site.scoreAttributes.on_page_seo / 70) * 100) + "%"
                : "N/A"}
            </span>
          </div>
          <div className="section">
            <span className="title">Technical SEO</span>
            <span className="value">
              {site.scoreAttributes.technical_seo
                ? { 10: "A+", 20: "B", 30: "C" }[
                    site.scoreAttributes.technical_seo
                  ]
                : "N/A"}
            </span>
          </div>
          <div className="section">
            <span className="title">Total Backlinks</span>
            <span className="value">{site.scoreAttributes.links ?? "N/A"}</span>
          </div>
          <div className="section">
            <span className="title">Speed</span>
            <span className="value">
              {site.scoreAttributes.speed
                ? site.scoreAttributes.speed + "%"
                : "N/A"}
            </span>
          </div>
          <div className="section">
            <span className="title">SSL</span>
            <span className="value">
              {site.scoreAttributes.ssl
                ? site.scoreAttributes.ssl == 10
                  ? "A+"
                  : "B"
                : "N/A"}
            </span>
          </div>
        </div>
      </Wrapper>
    );
  };
  const SiteSettings = ({}) => {
    const Wrapper = styled.div`
      text-align: left;

      .head-section {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;

        span {
          font-size: 0.65em;
          padding: 0.1em 0.5em;
          /* background: rgba(28, 191, 115, 0.8); */
          color: gray;
          border-radius: 15px;
          margin-left: 1em;
          border: 1px solid grey;
          cursor: pointer;
          transition: 0.5s;
        }

        span:hover {
          border: 1px solid black;
          color: black;
        }

        svg {
          animation: rotate 0.5s linear infinite;
          font-size: 1em;
          margin-left: 0.5em;
          color: gray;
        }
      }
      h1 {
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: 1.1em;
        font-weight: 600;
      }
      .section {
        margin-top: 1em;

        .head {
          display: flex;
          align-items: center;
          .icon {
            cursor: pointer;
            margin-left: 0.4em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.25em;
          }

          svg {
            color: rgba(28, 191, 115, 1);
            font-size: 0.9em;
            transition: 0.5s;
          }

          svg:hover {
            transform: translateY(-3px);
          }
        }

        h4 {
          font-size: 0.8em;
          font-weight: 400;
          margin: 0;
          padding: 0;
          margin-bottom: 0.4em;
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 0.7em;
          //border: 1px solid lightgray;
          border-radius: 4px;
          //padding: 0.5em 1em;
          color: gray;

          width: 70%;

          outline: none;
          @media screen and (max-width: 950px) {
            width: 97.5%;
            font-size: 16px;
          }
        }

        .optionHolder {
          width: 100%;
          display: flex;
          grid-gap: 0.5em;
          flex-wrap: wrap;
          .option {
            font-size: 0.7em;
            border: 1px solid grey;
            padding: 0.2em 0.5em;
            border-radius: 4px;
            width: fit-content;
            white-space: nowrap;
            transition: 0.5s;
            cursor: pointer;
            //border-radius: 20px;

            svg {
              transition: 0.5s;
            }
          }
          .selected {
            color: white;
            border: 1px solid rgba(28, 191, 115, 1);
            background: rgba(28, 191, 115, 0.8);
            svg {
              display: none;
            }
          }
          .option:hover {
            transform: translateY(-3px);
            color: white;
            border: 1px solid rgba(28, 191, 115, 1);
            background: rgba(28, 191, 115, 0.6);

            svg {
              color: white;
            }
          }
          .selected:hover {
            transform: translateY(-3px);
            border: 1px solid #ed4337;
            background: white;
            color: #ed4337;
          }
          .option-close {
            font-size: 0.7em;
            border: 1px solid grey;
            padding: 0.2em 0.5em;
            border-radius: 3px;
            width: fit-content;
            white-space: nowrap;
            transition: 0.5s;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0.2em 0.3em 0.2em 0.5em;
            //border-radius: 20px;
            svg {
              font-size: 0.9em;
              margin-left: 0.4em;
              color: grey;
            }
            svg:hover {
              color: red;
            }
          }

          .option-plus {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 0.7em;

            svg {
              margin-right: 0.3em;
              font-size: 0.8em;
              color: grey;
            }
          }
        }
      }
    `;

    const Options = ({ sKey, all }) => {
      return (
        <div className="optionHolder">
          {all.map((item) => (
            <span
              onClick={() => {
                let newSelected = site[sKey].slice();
                if (newSelected.includes(item.id)) {
                  newSelected = newSelected.filter((t) => t != item.id);
                } else {
                  newSelected.push(item.id);
                }

                let newUser = JSON.parse(JSON.stringify(user));
                newUser.websites[activeURL] = {
                  ...site,
                  [sKey]: newSelected,
                };

                setUser(newUser);
              }}
              className={
                "option option-plus" +
                (site[sKey].includes(item.id) ? " selected" : "")
              }
            >
              <FaPlus />
              <span>{item.label}</span>
            </span>
          ))}
        </div>
      );
    };

    const editDescription = () => {
      try {
        let des = document.querySelector("#description-text");
        if (des) {
          des.contentEditable = true;
          des.style.border = "1px solid gray";
          des.style.padding = "0.2em 0.5em";
          des.style.paddingRight = "0";
        }
      } catch {}
    };

    return (
      <Wrapper>
        <div className="head-section">
          <h1>Site Settings</h1>
          {saveLoading ? (
            <AiOutlineLoading3Quarters />
          ) : (
            <span
              onClick={async () => {
                let description = site.description;
                try {
                  let des = document.querySelector("#description-text");
                  if (des) {
                    description = des.textContent;
                  }
                } catch {}

                descriptionRef.current = {
                  ...descriptionRef.current,
                  des: description,
                };
                site.description = descriptionRef.current.des;
                setSaveLoading(true);
                await updateSiteSettings(
                  site.url,
                  site.tags,
                  site.materials,
                  site.contentTypes,
                  descriptionRef.current.des
                );
                setSaveLoading(false);
              }}
            >
              Save
            </span>
          )}
        </div>

        <SiteTab></SiteTab>
        <div className="section">
          <div className="head">
            <h4>Description</h4>
            <div className="icon" onClick={() => editDescription()}>
              {/* <LuPencil /> */}
              <CiEdit />
            </div>
          </div>
          <p id="description-text" onClick={() => editDescription()}>
            {descriptionRef.current.des}
          </p>
        </div>
        <div className="section">
          <h4>You are willing to provide</h4>
          <Options sKey={"contentTypes"} all={allContentTypes}></Options>
        </div>
        <div className="section">
          <h4>Provided materials</h4>
          <Options sKey={"materials"} all={allMaterials}></Options>
        </div>
        <div className="section">
          <h4>Category</h4>
          <Options sKey={"tags"} all={allTags}></Options>
        </div>
      </Wrapper>
    );
  };

  const Seo = ({}) => {
    const Wrapper = styled.div`
      text-align: left;
      /* border-bottom: 1px solid lightgray; */
      //border-right: 1px solid lightgray;
      padding: 1em 1.2em;
      h1 {
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: 1.1em;
        font-weight: 600;
      }
    `;

    return (
      <Wrapper>
        <h1>Your Score</h1>
      </Wrapper>
    );
  };

  const TotalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //height: 100vh;

    @media screen and (max-width: 500px) {
      margin-bottom: 2em;
    }
  `;

  const SiteSwitcher = ({ small = true }) => {
    const Wrapper = styled.span`
      display: ${small ? "none" : "flex"};
      //display: none;
      @media screen and (max-width: 950px) {
        display: flex;
      }
    `;
    return (
      <Wrapper
        className="siteswitcher"
        onClick={() => {
          setSiteSelectorOpen(!siteSelectorOpen);
        }}
      >
        <span>{formatLink(site.url)}</span>
        <div
          className="options"
          style={{ display: siteSelectorOpen ? "block" : "none" }}
        >
          {Object.keys(user.websites).map((s) => (
            <div
              className="option"
              onClick={() => {
                setActiveUrl(s);
              }}
            >
              <span>
                {" "}
                <FaCheck color={activeURL == s ? "black" : "white"} />
                {formatLink(s)}
              </span>
            </div>
          ))}

          <span
            className="option"
            onClick={() => {
              setShowNewSite(true);
            }}
          >
            {" "}
            <FaCheck color={"white"}></FaCheck>
            New site
          </span>
        </div>
        <FaCaretDown />
      </Wrapper>
    );
  };

  function getMetrics() {
    let res = { pendingOffers: 0, receivedOffers: 0, trades: 0 };

    try {
      for (let offerId in user.offers) {
        let o = user.offers[offerId];
        if (o[user.userId].url !== activeURL) continue;
        if (o.status === Status.Accepted) {
          res.trades += 1;
        } else if (o.status === Status.Pending) {
          if (o.sender === user.userId) {
            res.pendingOffers += 1;
          } else {
            res.receivedOffers = res.receivedOffers + 1;
          }
        }
      }
      return res;
    } catch {
      return res;
    }
  }
  return (
    <TotalWrapper>
      <div className="sub-holder">
        {showNewSite && (
          <NewSite
            close={() => {
              setShowNewSite(false);
            }}
          />
        )}
        <BHeader market={true} menu={true} />
        {!site.verified && <VerifySite />}
        <Wrapper>
          <div className="left">
            <div className="holder">
              <SiteSwitcher small={false} />
              <a
                className="nav"
                href="/mysite"
                style={{ color: !page && "black" }}
              >
                Home
              </a>
              <a
                className="nav"
                href="/mysite/offers"
                style={{ color: page == "offers" && "black" }}
              >
                Offers
              </a>
              <a
                className="nav"
                href="/mysite/settings"
                style={{ color: page == "settings" && "black" }}
              >
                Settings
              </a>
              <a
                className="nav"
                href="/mysite/messages"
                style={{ color: page == "messages" && "black" }}
              >
                Messages
              </a>
              {/* <a
              className="nav"
              href="/mysite/billing"
              style={{ color: page == "billing" && "black" }}
            >
              Billing
            </a> */}
              <a
                className="nav"
                href="/mysite/connects"
                style={{ color: page == "billing" && "black" }}
              >
                Connects
              </a>
              <a
                className="nav"
                href="/mysite/your-score"
                style={{ color: page == "billing" && "black" }}
              >
                Your Score
              </a>
            </div>
          </div>

          <div className="right">
            {page !== "messages" && <SiteSwitcher />}
            {(!page ||
              ![
                "settings",
                "offers",
                // "connects",
                "verify",
                "messages",
                "your-score",
              ].includes(page)) && (
              <>
                <h1>Dashboard</h1>
                <div className="stats">
                  <div className="stat">
                    <span className="num">{getMetrics().receivedOffers}</span>
                    <div className="text">
                      <div
                        className="color"
                        style={{ background: "lightgrey" }}
                      ></div>
                      <span className="label">Received Offers</span>
                    </div>
                  </div>
                  <div className="stat">
                    <span className="num">{user.connects}</span>
                    <div className="text">
                      <div
                        className="color"
                        style={{ background: " rgba(28, 191, 115, 1)" }}
                      ></div>
                      <a className="label" href="/mysite/connects">
                        Connects
                      </a>
                    </div>
                  </div>
                  <div className="stat">
                    <span className="num">{getMetrics().pendingOffers}</span>
                    <div className="text">
                      <div className="color"></div>
                      <span className="label">Pending Offers</span>
                    </div>
                  </div>
                  <div className="stat">
                    <span className="num">{getMetrics().trades}</span>
                    <div className="text">
                      <div className="color"></div>
                      <span className="label">Trades</span>
                    </div>
                  </div>
                </div>
                <div className="trades">
                  <Trades
                    user={user}
                    formatLink={formatLink}
                    //setSlideOut={setSlideOut}
                  />
                </div>
                <div className="trades">
                  <Offers user={user} />
                </div>
              </>
            )}
            {page == "settings" && <SiteSettings />}
            {page == "offers" && <Offers user={user} />}
            {page == "connects" && <StripePricingTable />}
            {page == "verify" && <Verify />}
            {page == "messages" && <Chat />}
            {page == "your-score" && <YourScore />}
          </div>
          {/* <div className="upper">
          <SiteTab score={score} trades={trades}></SiteTab>
        </div>
        <div className="lower">
          <Offers />
          <Trades></Trades>
          <SiteSettings></SiteSettings>
        </div> */}
        </Wrapper>
      </div>
      {/* <Footer></Footer> */}
    </TotalWrapper>
  );
};

export const mySiteLoader = async () => {
  let data = await getUser();
  let posts = await getWebsites();

  return { userInfo: data, posts };
};

export default MySite;
