import styled from "styled-components";
import BHeader from "../Global/BHeader";
import { FaAngleRight } from "react-icons/fa6";
import { RiCopperCoinFill } from "react-icons/ri";
import logo from "../logo.svg";
import Footer from "../Market/Footer";
import { useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import Post from "../Post";
import { getWebsites, getUser } from "../dataLayer/stroage";
import { useEffect } from "react";
import { useLoaderData, useNavigation } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { UserDataContext } from "../ProtectedRoute";
function Search({ data }) {
  let allPosts = data.posts;
  let [searchParams, setSearchParams] = useSearchParams();
  let term = searchParams.get("term");
  const { user } = useContext(UserDataContext);

  // don't show people their own posts
  if (user) {
    for (let url in user.websites) {
      allPosts = allPosts.filter((p) => p.url !== url);
    }
  }

  const [posts, setPosts] = useState(allPosts);
  const [filters, setFilters] = useState([]);
  const [filters2, setFilters2] = useState([]);
  console.log("filter", filters);
  let categories = [];

  console.log(allPosts);
  allPosts.forEach((p) => {
    if (showPost(p)) {
      categories = categories.concat(p.tags);
    }
  });

  categories = Array.from(new Set(categories));

  if (categories.length == 0) categories = ["No tags"];

  function showPost(p) {
    let pString = JSON.stringify(p);
    if (
      term &&
      term !== "" &&
      !pString.toLowerCase().includes(term.toLowerCase())
    )
      return false;

    for (let filter_id of filters) {
      if (filter_id === "No tags") console.log();
      else {
        if (!p.tags.includes(filter_id)) return false;
      }
    }

    let show = false;
    let flag = false;

    for (let filter_id of filters2) {
      if (
        filter_id === "low-authority" ||
        filter_id === "mid-authority" ||
        filter_id === "hi-authority"
      )
        flag = true;

      let score = Math.ceil(p.score);
      if (filter_id === "low-authority" && score < 20) show = true;
      else if (filter_id === "mid-authority" && score >= 20 && score < 70)
        show = true;
      else if (filter_id === "hi-authority" && score >= 70) show = true;
    }

    return flag ? show : true;
  }

  const Wrapper = styled.div`
    background: white;
    min-height: calc(100vh - 4em);
    color: rgb(34, 35, 37);
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;

    h1 {
      margin-top: 1.1em;
      padding-left: 3.5%;
      font-size: 1.3em;
    }

    .title {
      display: flex;
      :first-child {
        margin-right: 0.3em;
        font-weight: 200;
      }
      :nth-child(2) {
        padding-left: 0;
      }
    }
    .briefArea {
      padding: 0 3.5%;
      margin-bottom: 1em;
      display: flex;
    }

    .suggestionsArea {
      padding: 0 3.5%;
      width: 93%;
      margin-bottom: 1em;

      .label {
        display: flex;
        .tradeslabel {
          font-size: 0.7em;
          color: rgb(64, 65, 69);
          margin-right: 0.3em;
          font-weight: 200;
        }

        .topic {
          font-size: 1em;
          color: rgb(68, 110, 231);
        }
      }
      .postHolder {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        grid-gap: 0.7em;
      }
    }
  `;
  const Filters = () => {
    const [open, setOpen] = useState(null);

    function closeModal(e) {
      if (open) {
        let drop = document.querySelector(`#${open} > .options`);
        if (drop) {
          if (!drop.contains(e.target)) {
            let setNull = true;

            let filters = document.querySelectorAll(".brief");
            for (let f of filters) {
              if (f.contains(e.target)) {
                setNull = false;
                break;
              }
            }

            if (setNull) {
              console.log("setting nyll");
              setOpen(null);
              document.removeEventListener("mouseup", closeModal);
            }
          } else {
            console.log("fjkbnr");
          }
        }
      }
    }
    if (open) {
      document.addEventListener("mouseup", closeModal);
    }

    useEffect(() => {
      return () => {
        document.removeEventListener("mouseup", closeModal);
      };
    });

    const DropDown = ({
      id,
      title,
      options,
      filters,
      setFilters,
      type = "apply",
    }) => {
      const [selected, setSelected] = useState(filters);
      const Wrapper = styled.form`
        padding: 0.4% 1.2%;
        display: flex;
        align-items: center;
        border: 1px solid lightgrey;
        width: fit-content;
        border-radius: 10px;
        margin-right: 1em;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          width: 93%;
          padding: 2% 2%;
        }
        @media screen and (max-width: 800px) {
          padding: 2% 2%;
        }

        @media screen and (max-width: 500px) {
          padding: 0.5% 2%;
        }

        .apply {
          font-size: 0.6em;
          padding: 0.2em 0.7em;
          background: black;
          color: white;
          width: fit-content;
          border-radius: 20px;
          font-weight: 500;
          text-align: center;
          cursor: pointer;
        }

        .clear {
          font-size: 0.6em;
          margin-left: 0.8em;
          cursor: pointer;
        }

        .options {
          max-height: 30vh;
          overflow-y: scroll;
          position: absolute;
          background: white;
          top: 2.5em;
          left: 0;
          z-index: 99999999;
          border-radius: 5px;
          padding: 1em;
          padding-top: 0.5em;
          min-width: fit-content;
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: default;
          border: 1px solid lightgrey;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

          .subTitle {
            font-size: 0.7em;
            margin-bottom: 1em;
          }
          .spacer {
            height: 0.5em;
          }

          .option {
            font-size: 0.7em;
            font-weight: 600;
            margin-top: 1em;
            display: flex;
            align-items: center;

            input {
              margin-right: 0.7em;
              height: 1.5em;
              width: 1.5em;
              cursor: pointer;
              accent-color: rgb(12, 65, 39); //rgb(34, 35, 37);
            }
            input:hover {
              color: rgb(12, 65, 39);
              accent-color: rgb(12, 65, 39);
            }

            .label {
              cursor: default;
            }
            .subLabel {
              font-weight: 200;
              color: grey;
              margin-left: 1em;
              cursor: default;
            }
          }

          :last-child {
            margin-bottom: 0;
          }
        }
        .icon {
          height: 2em;
          aspect-ratio: 1/1;
          margin-right: 1em;
        }
        .text {
          margin-right: 1em;
          span {
            font-weight: bold;
            font-size: 0.8em;
          }

          @media screen and (max-width: 800px) {
            margin-right: 0em;
          }

          p {
            font-size: large;
            margin: 0;
            font-weight: 500;

            @media screen and (max-width: 800px) {
              font-size: medium;
            }
            @media screen and (max-width: 500px) {
              font-size: small;
            }
          }
        }
        .go {
          height: 1em;
          aspect-ratio: 1/1;
          margin-left: auto;
          color: lightgrey;
        }
      `;
      return (
        <Wrapper
          className="brief"
          id={id}
          onClick={(e) => {
            if (open == id) {
              let drop = document.querySelector(`#${open} > .options`);
              if (drop && !drop.contains(e.target)) {
                setOpen(null);
              }
            } else {
              setOpen(id);
            }
          }}
        >
          <div className="text">
            <p>{title}</p>
          </div>
          <div
            className="options"
            style={{ display: open == id ? "block" : "none" }}
          >
            <span
              className="apply"
              onClick={() => setFilters(Array.from(new Set(selected)))}
            >
              Apply
            </span>
            <span className="clear" onClick={() => setSelected([])}>
              clear
            </span>
            {options.map((section) => (
              <>
                {/* {section.title != "" && (
                  <>
                    <span className="subTitle">{section.title}</span>{" "}
                    <div className="spacer"></div>
                  </>
                )} */}
                {section.list.map((o) => (
                  <div className="option" id={o.id}>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected([...selected, o.id]);
                        } else {
                          setSelected(selected.filter((i) => i != o.id));
                        }
                      }}
                      checked={selected.includes(o.id)}
                    />

                    <span className="label">{o.label}</span>
                    <span className="subLabel">
                      {"subLabel" in o && o.subLabel}
                    </span>
                  </div>
                ))}
              </>
            ))}
          </div>
          <div className="go">
            <FaAngleDown />
          </div>
        </Wrapper>
      );
    };
    return (
      <div className="briefArea">
        <DropDown
          id={"category"}
          title={"Category"}
          setFilters={setFilters}
          filters={filters}
          options={[
            {
              title: "",
              number: "multiple",
              list: categories.map((c) => {
                return { label: c, id: c };
              }),
            },
          ]}
        />
        <DropDown
          id={"authority"}
          title={"Authority"}
          setFilters={setFilters2}
          filters={filters2}
          options={[
            {
              title: "Authority Score",
              number: "single",
              list: [
                { label: "Low", subLabel: "Under 20", id: "low-authority" },
                {
                  label: "Mid-range",
                  subLabel: "20 - 69",
                  id: "mid-authority",
                },
                { label: "Hi-end", subLabel: "70 & Above", id: "hi-authority" },
              ],
            },
            // {
            //   title: "Traffic",
            //   number: "single",
            //   list: [
            //     { label: "Low", id: "low" },
            //     { label: "Mid-range", id: "mid" },
            //     { label: "Hi-end", id: "hi" },
            //   ],
            // },
          ]}
        />
        {/* <DropDown
          id={"delivery-time"}
          title={"Delivery time"}
          options={[
            {
              title: "",
              number: "single",
              list: [
                { label: "Express 24H", id: "express" },
                { label: "Up to 3 days", id: "3D" },
                { label: "Up to 7 days", id: "7D" },
                { label: "Anytime", id: "anytime" },
              ],
            },
          ]}
        /> */}
      </div>
    );
  };

  // function useQuery() {
  //   const { search } = useLocation();
  //   return useMemo(() => new URLSearchParams(search), [search]);
  // }
  // let query = useQuery();
  // let term = query.get("term");

  let postsAvailable = allPosts.filter((p) => showPost(p)).length;
  return (
    <>
      <Wrapper>
        <BHeader market={false} />
        <div className="title">
          <h1>{!term ? "Search results" : "Results for"}</h1>
          <h1>{term}</h1>
        </div>
        <Filters></Filters>
        <div className="suggestionsArea">
          <div className="label">
            <h2 className="tradeslabel">{postsAvailable}+ sites available</h2>
          </div>
          <div className="postHolder">
            {posts.map((p, index) => (
              <>{showPost(p) && <Post postId={index} info={p}></Post>}</>
            ))}
          </div>
        </div>
      </Wrapper>
      <Footer></Footer>
    </>
  );
}
export const searchLoader = async () => {
  return { posts: await getWebsites(), userInfo: await getUser() };
};
export default Search;
