import Header from "./Header";
import Post from "../Post";
import Footer from "./Footer";
import styled, { css } from "styled-components";
import { FiSearch } from "react-icons/fi";
import { useEffect, useState, useRef } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { motion } from "framer-motion";
import scoreImg from "./scoreImg.png";
import suggestionImg from "./suggestion.svg";
import swapImg from "./swap.svg";
import linkImg from "./link.png";
import { useLocation, useParams } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
function Hero() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  function swap(node1, node2) {
    const afterNode2 = node2.nextElementSibling;
    const parent = node2.parentNode;
    node1.replaceWith(node2);
    parent.insertBefore(node1, afterNode2);
  }
  function right() {
    let postHolder = document.querySelector(".postholder");
    if (postHolder) {
      for (let i = 0; i < postHolder.childElementCount - 1; i++)
        swap(postHolder.childNodes[i], postHolder.childNodes[i + 1]);
    }
  }

  function left() {
    let postHolder = document.querySelector(".postholder");
    if (postHolder) {
      for (let i = postHolder.childElementCount - 1; i > 0; i--)
        swap(postHolder.childNodes[i - 1], postHolder.childNodes[i]);
    }
  }

  let Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow-y: hidden;
  `;

  const Intro = styled.div`
    display: flex;
    width: 100%;

    .left {
      padding: 0 5%;
      margin-top: 10vh;
      margin-bottom: 15vh;
      text-align: left;

      @media screen and (max-width: 700px) {
        margin-top: 5vh;
        margin-bottom: 3em;
      }

      width: 50%;
      @media screen and (max-width: 890px) {
        width: 80%;
      }
      @media screen and (max-width: 800px) {
        width: 90%;
      }
    }
    .right {
      width: 50%;
    }
    h1 {
      font-size: 2.1em;
      margin: 0.5em 0;
      white-space: nowrap;
      @media screen and (max-width: 800px) {
        white-space: wrap;
      }
      @media screen and (max-width: 700px) {
        font-size: 1.5em;
      }
    }

    .subTitleHolder {
    }
    .subTitle {
      font-size: 1em;
      padding-bottom: 2em;
    }
    .inputSection {
      margin-top: 1em;
      width: 80%;
      @media screen and (max-width: 700px) {
        width: 90%;
      }
      div {
        position: relative;
        input {
          width: 100%;
          font-size: 0.8em;
          padding: 0.9em 1.2em;
          border-radius: 50px;
          outline: none;
          border: none;
          border: 1px solid rgb(34, 35, 37);

          /* @media screen and (max-width: 600px) {
            font-size: 16px;
          } */
        }
        input::placeholder {
          color: lightgrey;
        }
        .search {
          display: none;
          visibility: hidden;
          position: absolute;
          background: rgb(28, 191, 115);
          padding: 0.4em;

          font-size: 1em;
          top: 0.3em;
          right: -1.5em;
          border-radius: 50%;
          display: grid;
          color: white;
        }

        .beta {
          position: absolute;
          background: rgb(28, 191, 115);
          padding: 0.4em 0.55em;

          font-size: 1em;
          top: 15%;
          right: -1.85em;
          display: grid;
          color: white;
          font-size: 0.8em;
          border-radius: 25px;
          text-decoration: none;
        }
      }
    }
  `;
  const Reviews = styled.div`
    width: 90%;
    background: rgba(245, 245, 245, 0.7);
    /* background: rgb(
      242,
      247,
      241
    ); //rgba(28, 191, 115,0.6); //rgb(12, 69, 42); //rgb(245, 245, 245); */
    height: 15vh;
    margin: 8vh 5%;
    border-radius: 5px;
    display: flex;

    @media screen and (max-width: 650px) {
      height: fit-content;
      margin: 2em 5%;
      border-radius: 10px;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1em 5%;
      text-align: left;

      @media screen and (max-width: 650px) {
        flex-direction: column;
        align-items: start;
      }

      .text {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        align-items: start;
        white-space: nowrap;
        padding: 0;
        width: fit-content;
        margin-right: 3em;
        @media screen and (max-width: 650px) {
          margin-bottom: 1em;
          text-align: center;
        }
        .title {
          margin: 0;
          font-size: xx-large;
          //text-align: left;
          text-align: inherit;
          @media screen and (max-width: 500px) {
            font-size: x-large;
          }
        }

        .subTitle {
          margin: 0;
          font-size: large;
          //text-align: left;
          text-align: inherit;
        }
      }
      img {
        height: 85%;
        border-radius: 20px;
        border: 1px solid rgba(211, 211, 211, 1);
        margin: 0 1em;
        //width: 40%;
        //object-fit: cover;
        //object-position: left top;
        //padding-right:5%;

        @media screen and (max-width: 650px) {
          display: none;
        }
      }
    }
    a {
      width: fit-content;
      height: fit-content;
      background: rgb(28, 191, 115);
      padding: 0.4em 0.55em;
      white-space: nowrap;

      font-size: 1em;
      display: flex;
      color: white;
      font-size: 0.8em;
      border-radius: 25px;
      text-decoration: none;
      @media screen and (max-width: 650px) {
        align-self: start;
      }
    }
  `;
  const Websites = styled.div`
    border-radius: 10px;
    margin: 0 5%;
    width: 90%;
    min-height: 29vh;
    position: relative;

    .swapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .control {
      height: 1.5em;
      aspect-ratio: 1/1;
      background: white;
      top: 28%;
      z-index: 9999;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      //color: rgb(12, 65, 39);
      color: grey;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9em;
    }

    .left {
      left: -2em;
      margin-right: 1em;
    }
    .right {
      right: -2em;
      margin-left: 1em;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }
    .resultsHeader {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 1em;
      pointer-events: none;
      user-select: none;

      span {
        display: grid;
        font-size: 1em;
        font-weight: bold;
        pointer-events: none;
        user-select: none;
        // margin-left: 3.9em;
      }
    }
    .postholder {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 0.7em;

      :nth-child(5) .post {
        @media screen and (max-width: 1300px) {
          display: none;
        }
      }

      :nth-child(4) .post {
        @media screen and (max-width: 1100px) {
          display: none;
        }
      }

      :nth-child(3) .post {
        @media screen and (max-width: 820px) {
          display: none;
        }
      }
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
      }
      :nth-child(2) .post {
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
    }
  `;
  const HowItWorks = styled.div`
    width: 90%;
    padding: 2vh 5%;
    text-align: left;
    position: relative;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    @media screen and (max-width: 800px) {
      width: 80%;
      //margin: 0% 5%;
      border-radius: 20px;
    }
    display: flex;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      width: 90%;
      /* margin: 0% 2.5%; */
    }
    .left {
      width: 45%;
      background: rgb(
        126,
        214,
        164
      ); //rgb(197,236,213);//rgba(28, 191, 115, 0.6); // rgb(242, 247, 241);
      border-radius: 5px;
      //border: 1px solid rgba(28, 191, 115, 1);
      //color: //rgba(28, 191, 115, 1);
      color: white;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 1em 0;

      h1 {
        font-size: 1.1em;
        //font-weight: 100;
        width: 95%;

        @media screen and (max-width: 800px) {
          font-size: 1em;
        }
      }

      img {
        height: 2.5em;
        z-index: 999;
        background: white;
        border-radius: 46%;
        padding: 0.5em;
      }

      span {
        font-size: 0.7em;
      }
      @media screen and (max-width: 800px) {
        /* height: 0vh; */
        width: 100%;
        padding: 1em 0em;
        margin-bottom: 2.5em;
      }
    }
    .right {
      width: 55%;
      @media screen and (max-width: 900px) {
        width: 90%;
        padding: 1.5em 1em;
        border-radius: 10px;
      }
      @media screen and (max-width: 800px) {
        background: rgb(242, 247, 241);
      }
    }
    .title {
      display: grid;
      font-size: 1em;
      font-weight: bold;
      pointer-events: none;
      user-select: none;
      margin-bottom: 1em;
      margin-left: 5%;
    }

    .textHolder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-left: 5%;

      .info {
        font-size: 0.8em;
        display: flex;
        //align-items: center;
        width: 90%;

        margin-bottom: 1em;

        .icon {
          height: 2em;
          aspect-ratio: 1/1;
          margin-right: 1em;
        }

        .text {
          h2 {
            font-size: 1em;
            font-weight: medium;
            margin: 0;
          }

          p {
            font-size: 0.9em;
            margin: 0;
          }
        }
      }
    }
  `;
  const Image = styled.div`
    position: relative;
    max-width: 700px;
    scale: 0.9;
    @media screen and (max-width: 880px) {
      display: none;
    }
  `;
  const UIArticle = ({ color, left, top, scale, step, nextLeft, nextTop }) => {
    const [reload, setReload] = useState(true);
    const UI = styled(motion.div)`
      /* scale: ${0.2}; */
      /* filter: blur(0.5px); */
      height: fit-content;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
      position: absolute;

      left: 50%;
      top: 50%;

      left: ${left};
      top: ${top};

      @media screen and (max-width: 1200px) {
        left: ${nextLeft};
        top: ${nextTop};
      }
      /* width: 175px; */
      width: 200px;
      height: auto;
      background: white;
      border-radius: 10px;
      /* scale: 0.7; */
      color: ${(props) => props.theme.text};
      padding: 0;
      margin: 0;

      #overlay {
        width: 90%;
        height: 100%;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ${(props) =>
          props.color &&
          css`
            background-color: rgba(
              ${props.color[0]},
              ${props.color[1]},
              ${props.color[2]},
              0.3
            );
            border-color: gray;
            cursor: not-allowed;
          `}
      }

      .photo {
        height: 100px;
        width: 100%;
        background: white;
        border-radius: 10px;
        margin-bottom: 5px;

        ${(props) =>
          props.color &&
          css`
            background-color: rgba(
              ${props.color[0]},
              ${props.color[1]},
              ${props.color[2]},
              0.9
            );
            border-color: gray;
            cursor: not-allowed;
          `}
      }

      .line {
        height: 15px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 25px;
        margin: 5px;
      }

      .text {
        width: 100%;

        .pubHolder {
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;
          #pubPhoto {
            width: 20px;
            ${(props) =>
              props.color &&
              css`
                background-color: rgba(
                  ${props.color[0]},
                  ${props.color[1]},
                  ${props.color[2]},
                  0.5
                );
                border-color: gray;
                cursor: not-allowed;
              `}
          }
          #publisher {
            width: 100%;
            ${(props) =>
              props.color &&
              css`
                background-color: rgba(
                  ${props.color[0]},
                  ${props.color[1]},
                  ${props.color[2]},
                  0.5
                );
                border-color: gray;
                cursor: not-allowed;
              `}
          }
        }
        #line1 {
          width: 90%;
        }
        #line2 {
          width: 80%;
        }
        #line3 {
          width: 60%;
        }
      }
    `;
    /* useEffect(() => {
      setTimeout(() => {
        setReload(!reload);
      }, 2000 * step);
    }); */

    return (
      <UI
        color={color}
        initial={{ scale: scale - 0.2 }} //left: "40%", top: "30%" }}
        animate={{ scale }}
        transition={{
          repeat: Infinity,
          duration: 2 + step,
          ease: "easeOut",
          type: "spring",
          repeatType: "mirror",
          delay: 2,
        }}

        /* initial={{ opacity: 0, scale: scale - 0.2 }}
        animate={{ opacity: 1, scale }}
        transition={{
          duration: 0.1 + step,
          ease: [0, 0.71, 0.2, 1.01],
          scale: {
            type: "spring",
            damping: 5,
            stiffness: 100,
            restDelta: 0.001,
            delay: 3,
            repeatType: "mirror",
            ease: "easeOut",
            repeat: Infinity,
          },
        }} */
        /* transition={{
          
          ease: "easeOut",
          type: "spring",
          duration: 3,
        }} */
      >
        <div id={"overlay"}>
          {/* <span>{step}</span> */}
          <div className="photo"></div>
          <div className="text">
            <div className="pubHolder">
              <div id={"pubPhoto"} className="line"></div>
              <div id={"publisher"} className="line"></div>
            </div>
            <div id={"line1"} className="line"></div>
            <div id={"line2"} className="line"></div>
            <div id={"line3"} className="line"></div>
          </div>
        </div>
      </UI>
    );
  };

  const Switcher = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 800px) {
      flex-direction: column-reverse;
    }
  `;

  function url() {
    let link = document.querySelector("#websiteInput");
    if (link) {
      return "&url=" + link.textContent;
    }
    return "";
  }

  const Input = () => {
    const [value, setValue] = useState("");

    return (
      <div>
        <input
          id="websiteInput"
          placeholder="Enter your website"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
        />
        <span className="search">
          <FiSearch />
        </span>
        <a className="beta" href={`/signup`}>
          Get beta access
        </a>
      </div>
    );
  };
  return (
    <Wrapper>
      {location.pathname === "/login" && <Login />}
      {location.pathname === "/signup" && <Register />}
      {location.pathname === "/reset-password" && <ResetPassword />}

      <Header></Header>
      <Intro>
        <div className="left">
          <h1>
            Grow organic website traffic,
            <br /> no cost involved
          </h1>
          <div className="subTitleHolder">
            <span className="subTitle">
              A new way of backlink building <span className="dash"> - </span>
            </span>
            <span className="subTitle">Peer to Peer link trading</span>
          </div>
          <div className="inputSection">
            <Input></Input>
          </div>
        </div>
        <Image className="right">
          <UIArticle
            color={[28, 191, 115]}
            top={"5%"}
            left={"0%"}
            nextTop={"-20%"}
            nextLeft={"0%"}
            scale={0.7}
            step={1}
          ></UIArticle>
          <UIArticle
            color={[28, 191, 115]}
            top={"40%"}
            left={"35%"}
            nextTop={"30%"}
            nextLeft={"20%"}
            scale={0.8}
            step={2}
          ></UIArticle>
          <UIArticle
            color={[28, 191, 115]}
            top={"25%"}
            left={"65%"}
            nextTop={"50%"}
            nextLeft={"55%"}
            scale={0.6}
            step={3}
          ></UIArticle>
          <UIArticle
            color={[28, 191, 115]}
            top={"60%"}
            left={"7%"}
            nextTop={"65%"}
            nextLeft={"-10%"}
            scale={0.5}
            step={4}
          ></UIArticle>
        </Image>
      </Intro>
      <Websites>
        <div className="resultsHeader">
          <span>Popular Sites</span>
        </div>
        <div className="swapper">
          <div className="left control" onClick={() => left()}>
            <FaAngleLeft></FaAngleLeft>
          </div>
          <div className="postholder">
            {[
              {
                type: "Blog",
                score: 40 + Math.floor(Math.random() * 60),
                url: "https://scribblyai.web.app/",
                description:
                  "Scribbly is an AI article generator for informative websites.",
                icon: "scribbly",
              },
              {
                type: "Product",
                score: 40 + Math.floor(Math.random() * 60),
                url: "https://cryptopay-18d87.web.app/",
                description:
                  "CryptoPay makes it easy to provide crypto cards to your users.",
                icon: "crypto",
              },
              {
                type: "Cooking",
                score: 40 + Math.floor(Math.random() * 60),
                url: "https://flavorfusion.com",
                description:
                  "Learn from chefs with Flavor Fusion's virtual classes.",
                icon: "cooking",
              },
              // {
              //   type: "Service",
              //   score: 40 + Math.floor(Math.random() * 60),
              //   link: "https://smarthealthtrack.com",
              //   description: "Monitor health metrics with Smart Health Tracker.",
              // },
              {
                type: "Educational",
                score: 40 + Math.floor(Math.random() * 60),
                url: "https://virtututor.com",
                description:
                  "Personalized online tutoring at Virtual Tutor Zone.",
                icon: "tutor",
              },
              {
                type: "Gaming",
                score: 40 + Math.floor(Math.random() * 60),
                url: "https://adquestworlds.com",
                description:
                  "Explore a fantasy universe in Adventure Quest Worlds.",
                icon: "game",
              },
            ].map((p, index) => (
              <Post postId={index} info={p} pointer={false}></Post>
            ))}
          </div>
          <div className="right control" onClick={() => right()}>
            <FaAngleRight></FaAngleRight>
          </div>
        </div>
      </Websites>
      <Switcher>
        <Reviews>
          <div>
            <div className="text">
              <h2 className="title">Get more organic traffic</h2>
              <h2 className="subTitle">No cost involved</h2>
            </div>
            <a href="/signup">Get beta access</a>
            {/* <img src={graph}></img> */}
          </div>
        </Reviews>
        <HowItWorks>
          <div className="left">
            <img src={linkImg}></img>
            <h1>
              Websites that rank #1 on Google have 3.8x more backlinks than the
              rest of the top 10 sites.
            </h1>
            <span>Source: mailchimp</span>
          </div>
          <div className="right">
            <span className="title">
              Boost traffic for free, its this easy!
            </span>
            <div className="textHolder">
              <div className="info">
                <img className="icon" src={scoreImg}></img>
                <div className="text">
                  <h2>Authority Score</h2>
                  <p>Get a score based on your website's rank and traffic</p>
                </div>
              </div>
              <div className="info">
                <img className="icon" src={suggestionImg}></img>
                <div className="text">
                  <h2>Suggestions</h2>
                  <p>
                    We intelligently match websites based on related content and
                    website score
                  </p>
                </div>
              </div>

              <div className="info">
                <img className="icon" src={swapImg}></img>
                <div className="text">
                  <h2>Trade</h2>
                  <p>
                    You exchange backlinks to improve your website's visibility
                    and position in search results
                  </p>
                </div>
              </div>
            </div>
          </div>
        </HowItWorks>
      </Switcher>
      <Footer></Footer>
    </Wrapper>
  );
}
export default Hero;
