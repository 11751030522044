import styled from "styled-components";
import { useMemo, useState, useRef, isValidElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { addBetaUser } from "../dataLayer/stroage";
import { transform } from "framer-motion";
function CreateOffering() {
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let source = query.get("source");
  let preUrl = query.get("url");
  const navigate = useNavigate();
  const Wrapper = styled.div`
    background: white;
    color: rgb(64, 65, 69);

    .bottom {
      display: flex;
      align-items: start;
      padding: 0 1.3em;

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      .leftSide {
        cursor: default;
        width: 40%;
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0.7em;
        h1 {
          font-size: 1.75em;
          @media screen and (max-width: 800px) {
            font-size: 1.7em;
            margin-bottom: 0.3em;
          }
        }

        span {
          font-size: 0.7em;
          color: grey;
          margin-bottom: 0.7em;
          @media screen and (max-width: 800px) {
            font-size: 1em;
          }
        }

        div {
          text-decoration: underline;
          //color: green;
          margin-top: 0.2em;
          font-size: 0.8em;
        }
      }
      .rightSide {
        width: 60%;
        display: flex;
        justify-content: start;
        height: 80vh;
        overflow-y: scroll;
        @media screen and (max-width: 800px) {
          width: 100%;
          height: auto;
        }
        form {
          width: 70%;
          margin-left: 10%;
          text-align: left;

          @media screen and (max-width: 800px) {
            width: 100%;
            height: auto;
            margin-left: 0;
          }

          p {
            margin: 0;
            padding: 0;
          }

          :first-child {
            margin-top: 2em;
          }
          :last-child {
            margin-bottom: 0.5em;
          }
          .section {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
            h2 {
              font-size: 0.9em;
              margin: 0;
              cursor: default;
            }
            p {
              margin: 0;
              padding: 0;
            }

            span {
              margin: 0;
              color: grey;
              font-size: 0.7em;
              margin-top: 0.3em;
              cursor: default;

              a {
                color: rgb(134, 172, 180);
                text-decoration: underline;
              }
            }

            .error {
              color: red;
              font-size: 0.6em;
            }

            input {
              font-size: medium;
              padding: 0.4em 0.5em;
              margin-top: 0.6em;
              outline: none;
            }
            textarea {
              font-size: medium;
              padding: 0.4em 0.5em;
              margin-top: 0.6em;
              outline: none;
              font-family: inherit;
            }
            input::placeholder {
              color: rgba(170, 170, 170, 1);
            }

            input[type="number"] {
              width: 3em;
            }

            input[type="text"] {
              height: 4em;
            }
            .otherLinks {
              margin-top: 0.3em;
              display: flex;
              flex-direction: column;
              margin-bottom: 2em;
            }
            .selector {
              padding: 0.155em 0.5em;
              border: 1px solid grey;
              border-radius: 2px;
              width: fit-content;
              margin-top: 0.3em;
              cursor: pointer;
              p {
                font-size: 0.7em;
                color: rgba(170, 170, 170, 1);
                margin-right: 0.5em;

                @media screen and (max-width: 600px) {
                  font-size: 0.9em;
                  padding: 0.15em 0.5em;
                }
              }
              .sel {
                display: flex;
                align-items: center;

                svg {
                  display: flex;
                  align-items: center;
                  font-size: 0.9em;
                  color: rgba(170, 170, 170, 1);
                }
              }
            }
          }
        }
      }
    }
  `;

  const Header = styled.div`
    margin: 0 2.5%;
    .header {
      display: flex;
      justify-content: space-between;
      padding: 0.9em 0.2em;
      border-bottom: 1px solid lightgrey;
      align-items: center;
      .title {
        font-weight: 200;
        font-size: 1.2em;
      }
      .exit {
        font-size: 0.7em;
        color: rgb(64, 65, 69);
        text-decoration: none;
      }
    }
  `;

  const Footer = styled.div`
    margin: 0 2.5%;
    .footer {
      display: flex;
      justify-content: end;
      padding: 0.47em 0.2em;
      border-top: 1px solid lightgrey;
      align-items: center;
      cursor: pointer;
      .continue {
        font-size: 0.7em;
        color: white;
        background: rgb(34, 35, 37);
        padding: 0.5em 1em;
        border-radius: 3px;
      }
    }
  `;

  const formRef = useRef({
    url: preUrl ? preUrl : "",
    email: "",
    category: null,
    description: "",
  });

  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }
  const isValidEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const [errors, setErrors] = useState({
    url: false,
    email: false,
    category: false,
    description: false,
  });

  function checkForm() {
    let { url, email, category, description } = formRef.current;
    let es = {
      url: false,
      email: false,
      category: false,
      description: false,
    };
    if (!isValidURL(url)) es.url = true;
    if (!isValidEmail(email)) es.email = true;
    if (category == null) es.category = true;
    if (description == "") es.description = true;

    if (!es.url && !es.email && !es.category && !es.description) {
      addBetaUser(formRef.current);
      navigate("/newbeta");
    }

    setErrors(es);
  }

  const Input = ({
    type,
    placeholder,
    id,
    initialValue = "",
    error = false,
    errorMessage = "",
  }) => {
    const [value, setValue] = useState(initialValue);
    const [showError, setShowError] = useState(error);
    return (
      <>
        {type != "textarea" ? (
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              formRef.current[id] = e.target.value;
              setShowError(false);
            }}
          />
        ) : (
          <textarea
            rows="3"
            cols="60"
            type="text"
            placeholder={placeholder}
            value={value}
            min={25}
            onChange={(e) => {
              setValue(e.target.value);
              formRef.current[id] = e.target.value;
              setShowError(false);
            }}
          ></textarea>
        )}
        {showError && <span className="error">{errorMessage}</span>}
      </>
    );
  };
  const [open, setOpen] = useState({ value: null, show: false });
  const DropDown = ({ options }) => {
    const Wrapper = styled.div`
      position: absolute;
      margin: 0;
      padding: 0.2em 0.3em;
      top: 1.6em;
      left: -0.5em;
      background: white;
      border-radius: 2px;
      border: 1px solid grey;

      :last-child {
        border-bottom: none;
      }
      div {
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 0.2em 0;

        border-bottom: 1px solid lightgrey;
        span {
          font-size: 0.7em;
        }
      }
    `;
    return (
      <>
        {open.show && (
          <Wrapper className="dropdown">
            {options.map((o) => (
              <div
                style={{ margin: 0 }}
                onClick={() => {
                  setOpen({ value: o.label, show: false });
                  formRef.current.category = o.value;
                  if (errors.category)
                    setErrors({ ...errors, category: false });
                }}
              >
                <span style={{ margin: 0 }}>{o.label}</span>
              </div>
            ))}
          </Wrapper>
        )}
      </>
    );
  };
  return (
    <Wrapper>
      <Header>
        <div className="header">
          <span className="title">linktradr</span>
          <a
            className="exit"
            href={source && source == "market" ? "/market" : "/"}
          >
            {source == "hero" ? "Back" : "Exit"}
          </a>
        </div>
      </Header>
      <div className="bottom">
        <div className="leftSide">
          <h1>Let the matching begin...</h1>
          <span>This is where you show off your website.</span>
          {/* <div>Launching Soon!</div> */}
        </div>
        <div className="rightSide">
          <form>
            <div className="section">
              <h2>What is your website url?</h2>
              {/* <span>This is the specific url where a link would reside</span> */}

              <Input
                type="url"
                placeholder="Example: linktradr.com/blog"
                initialValue={formRef.current.url}
                id="url"
                error={errors.url}
                errorMessage="Invalid Url"
              />
            </div>

            {source == "hero" && (
              <div className="section">
                <h2>What is your email?</h2>
                {/* <span>This is the specific url where a link would reside</span> */}
                <Input
                  type="email"
                  placeholder="JaneDoe@yourdomain.com"
                  id="email"
                  initialValue={formRef.current.email}
                  error={errors.email}
                  errorMessage="Invalid email address"
                />
              </div>
            )}
            <div className="section">
              <h2>Which category best fits your site?</h2>
              {/* <span>Choose at least one</span> */}
              <span></span>
              <div
                className="selector"
                onClick={(e) => {
                  let drop = document.querySelector(".dropdown");
                  if (drop && drop.contains(e.target)) {
                  } else if (drop) {
                    setOpen({ ...open, show: false });
                  } else {
                    setOpen({ ...open, show: true });
                  }
                }}
              >
                <div
                  className="sel"
                  style={{ margin: 0, position: "relative" }}
                >
                  <p style={{ color: open.value && "inherit" }}>
                    {open.value ? open.value : "Choose 1 category"}
                  </p>
                  <div
                    className="icon"
                    style={{
                      margin: 0,
                      transform: open.show
                        ? "rotate(180deg) translateY(-2px)"
                        : "translateY(1px)",
                    }}
                  >
                    <FaAngleDown style={{ margin: 0, marginTop: "0.1em" }} />
                  </div>
                  <DropDown
                    options={[
                      { label: "Blog", value: "blog" },
                      { label: "Product", value: "product" },
                    ]}
                  ></DropDown>
                </div>
              </div>
              {errors.category && (
                <span className="error">Please select a category</span>
              )}
            </div>
            <div className="section">
              <h2>Description</h2>
              <span>We use this to match your website!</span>

              <Input
                type="textarea"
                placeholder="linktradr is a platform for peer to peer link building!"
                id="description"
                initialValue={formRef.current.description}
                error={errors.description}
                errorMessage="Please enter a description"
              />
            </div>
            {source == "market" && (
              <div className="section">
                <h2>Connects</h2>
                <span>How many connects should a proposal need?</span>
                <input type="number" placeholder={10} max={99}></input>
              </div>
            )}
            {/* <div className="section">
              <h2>Other links</h2>
              <span>
                Not correct? <a>Re-scan</a>
              </span>
               <div className="otherLinks">
                <span>dkenjf.com</span>
                <span>dkenjf.com</span>
                <span>dkenjf.com</span>
                <span>dkenjf.com</span>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      <Footer>
        <div className="footer">
          <span className="continue" onClick={() => checkForm()}>
            Complete
          </span>
        </div>
      </Footer>
    </Wrapper>
  );
}

export default CreateOffering;
