import styled from "styled-components";
import BHeader from "../Global/BHeader";
import { FaAngleRight } from "react-icons/fa6";
import { RiCopperCoinFill } from "react-icons/ri";
import logo from "../logo.svg";
import Footer from "./Footer";
import Post from "../Post";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getUser, getWebsites, allTags } from "../dataLayer/stroage";
import { useEffect, useContext } from "react";
import { useLoaderData, useNavigate, Navigate } from "react-router-dom";
import { UserDataContext } from "../ProtectedRoute";
import VerifySite from "../Global/VerifySite";
import { formatLink } from "../Global/utils";
import Feedback from "./Feedback";
import { useSearchParams } from "react-router-dom";
function Market({ data }) {
  let [searchParams, setSearchParams] = useSearchParams();
  let feedback = searchParams.get("feedback");

  const navigate = useNavigate();
  const [showFeedback, setShowFeedback] = useState(feedback ? true : false);
  let posts = data.posts;
  console.log(posts);
  let { user, activeURL } = useContext(UserDataContext);
  console.log("ACTIVEURL", activeURL);
  let site = user.websites[activeURL];
  if (!user) {
    console.log("nav back", activeURL);
    navigate(0);
    return null;
  }

  console.log("USER", user);

  function setSuggestion(offset) {
    if (!user) {
      return "technology";
    }
    let suggestionTopic = "technology";
    // pick suggestion topic by todays day
    try {
      if (user.websites[activeURL].tags) {
        suggestionTopic =
          user.websites[activeURL].tags[
            (new Date().getDay() + offset) %
              user.websites[activeURL].tags.length
          ];
      }
    } catch {}

    let suggestionLabel = suggestionTopic;

    try {
      suggestionLabel = allTags
        .slice()
        .filter((t) => t.id == suggestionTopic)[0].label;
    } catch {}

    // filter by suggestion topic
    posts = data.posts.filter((p) => p.tags.includes(suggestionTopic));

    return suggestionLabel;
  }

  function getRandomElements(arr, num) {
    // Make a copy of the original array to avoid modifying it
    const copyArray = [...arr];

    // Check if the requested number is greater than the array length
    if (num > copyArray.length) {
      console.warn(
        "Requested number is greater than the array length. Returning all elements."
      );
      return copyArray;
    }

    // Shuffle the array
    for (let i = copyArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
    }

    // Return the first 'num' elements
    return copyArray.slice(0, num);
  }

  let suggestionLabel = setSuggestion(0);

  if (posts.length <= 2) {
    posts = getRandomElements(data.posts, 5);
    suggestionLabel = null;
  }

  // don't show people their own posts
  if (user) {
    for (let url in user.websites) {
      posts = posts.filter((p) => p.url !== url);
    }
  }

  const Wrapper = styled.div`
    background: white;
    height: 100vh;
    color: rgb(34, 35, 37);
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;

    h1 {
      margin-top: 1.1em;
      padding: 0 3.5%;
      font-size: 1.3em;

      .site-name {
        color: rgba(28, 191, 115, 1);
        margin: 0;
      }
    }
    .briefArea {
      /* padding: 0 3.5%; */
      margin-bottom: 1em;
      text-decoration: none;

      color: rgb(34, 35, 37);
      width: 48%;

      .brief {
        cursor: pointer;
        padding: 2% 2%;
        border: 1px solid lightgrey;
        border-radius: 10px;

        @media screen and (max-width: 1000px) {
          padding: 1.5% 2%;
        }

        a {
          color: black; //rgba(28, 191, 115, 1);
        }
      }
      .padding {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1000px) {
          width: 100%;
        }

        .connects {
          margin: 0;
          padding: 0;
          font-size: 2em;
          overflow-y: hidden;
          /* margin-right: 1em; */
          line-height: 48px;
          margin-right: 0.5em;
          margin-left: 0.2em;
          font-weight: 400;
        }
        .icon {
          height: 2em;
          aspect-ratio: 1/1;
          margin-right: 1em;
          margin-left: 0.2em;
        }

        .sicon {
          @media screen and (max-width: 500px) {
            margin-bottom: 0.65em;
          }
        }
        .text {
          span {
            font-weight: bold;
            font-size: 0.8em;
          }

          p {
            font-size: large;
            margin: 0;
          }
        }
        .go {
          height: 1em;
          aspect-ratio: 1/1;
          margin-left: auto;
          color: lightgrey;
        }
      }
    }

    .cardArea {
      padding: 0 3.5%;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1000px) {
        display: block;
      }

      .feedback {
        @media screen and (max-width: 1000px) {
          margin-top: 0.5em;
        }
      }
    }

    .suggestionsArea {
      padding: 0 3.5%;
      width: 93%;
      margin-bottom: 1em;

      .label {
        display: flex;
        flex-direction: column;

        .tradeslabel {
          font-size: 1em;
          color: rgb(64, 65, 69);
          margin-right: 0.3em;
          margin-bottom: 0.5em;
        }

        .topic {
          font-size: 1em;
          color: rgba(28, 191, 115, 1);
        }

        .titl {
          display: flex;
        }

        .add {
          font-size: 0.7em;
          color: grey;
          margin-bottom: 0.6em;

          a {
            color: rgba(28, 191, 115, 1);
          }
        }
      }
      .postHolder {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 0.7em;
      }
    }
  `;

  console.log(site);
  return (
    <Wrapper>
      <BHeader market={false} />
      {showFeedback && (
        <Feedback
          close={() => {
            setShowFeedback(false);
            if (searchParams.has("feedback")) {
              searchParams.delete("feedback");
              setSearchParams(searchParams);
            }
          }}
        />
      )}
      {!site.verified && <VerifySite />}

      <h1>
        Happy trading with{" "}
        <span className="site-name">{formatLink(activeURL).split(".")[0]}</span>
        .
      </h1>
      <div className="cardArea">
        <a className="briefArea" href="/mysite/connects">
          <div className="brief">
            <div className="padding">
              {/* <div className="icon">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                >
                  <path
                    d="m27.674 6.159.279.698-.115.046-.124.006-.04-.75Zm12.882 23.935a.748.748 0 1 1-1.498.027l1.498-.027Zm-15.261-4.172.606-.44-.606.44Zm-5.796 4.926-.741.102.742-.102Zm19.56-.727A97.74 97.74 0 0 0 39 28.22l1.496-.056c.025.626.045 1.27.059 1.93l-1.498.028Zm-.292-5.659a62.076 62.076 0 0 0-.428-3.718l1.482-.216c.173 1.178.32 2.446.438 3.808l-1.492.126Zm-1.12-7.392a29.173 29.173 0 0 0-1.055-3.544L37.993 13a30.664 30.664 0 0 1 1.112 3.727l-1.458.342ZM35 10.264c-.778-1.212-1.589-2.023-2.358-2.564l.857-1.23c.946.665 1.888 1.625 2.76 2.983L35 10.264Zm-5.479-3.627a5.04 5.04 0 0 0-1.174.11 3.635 3.635 0 0 0-.398.111h.002l.001-.001-.278-.698a128.92 128.92 0 0 1-.277-.699h.002l.005-.003a1.048 1.048 0 0 1 .05-.018 5.123 5.123 0 0 1 .57-.16 6.53 6.53 0 0 1 1.525-.144l-.028 1.502Zm-1.807.272a4.104 4.104 0 0 0-1.596.435l-.662-1.35a5.593 5.593 0 0 1 2.178-.585l.08 1.5Zm-4.142 2.822c-.596.933-1.06 2.076-1.3 3.393L20.8 12.85c.274-1.502.806-2.829 1.51-3.932l1.262.813Zm-1.377 7.097c.146 1.144.447 2.363.938 3.645l-1.397.536a17.214 17.214 0 0 1-1.026-3.994l1.485-.187Zm2.637 7.053a25.65 25.65 0 0 0 1.069 1.602l-1.213.878a27.19 27.19 0 0 1-1.13-1.695l1.274-.785Zm1.069 1.602c.48.664.892 1.332 1.243 1.998l-1.324.7a15.319 15.319 0 0 0-1.132-1.82l1.213-.878Zm2.745 6.477c.27 1.78.13 3.424-.294 4.785l-1.43-.453c.352-1.126.481-2.539.244-4.11l1.48-.222Zm-3.723 8.484c-1.596.273-3.108-.742-4.224-2.777l1.311-.722c.975 1.778 1.975 2.135 2.658 2.018l.255 1.481Zm-5.763-7.208a31.28 31.28 0 0 1-.402-2.286l1.483-.205c.11.788.239 1.512.383 2.175l-1.464.316Zm-.402-2.286a19.08 19.08 0 0 0-.294-1.617l1.46-.334c.123.538.23 1.119.317 1.746l-1.483.205Zm-1.413-4.566c-.709-1.137-1.465-1.452-2.054-1.433l-.051-1.502c1.267-.04 2.468.688 3.374 2.14l-1.269.795Zm-4.059.072a4.329 4.329 0 0 0-.451 1.374l-1.48-.233a5.83 5.83 0 0 1 .61-1.853l1.321.712ZM2.508 41.491l12.54-14.559 1.94 1.709-12.69 14.28-1.79-1.43Z"
                    fill="#232426"
                  ></path>
                  <path
                    d="m15 26.96 3.008-3.182 1.616 1.66-2.828 3.204-1.797-1.681Z"
                    fill="#fff"
                  ></path>
                  <path
                    d="m2.755 40.998 10.01-11.47 4.776-5.385a.624.624 0 0 1 .866-.099l1.07.933a.618.618 0 0 1 .015.868L4.68 42.675a.83.83 0 0 1-1.162.082l-.677-.579a.833.833 0 0 1-.086-1.18Z"
                    stroke="#232426"
                    stroke-width="1.005"
                    stroke-miterlimit="10"
                  ></path>
                  <path
                    d="m38.355 20.89 1.984 1.768a.036.036 0 0 0 .02-.01l2.608-.968c.026-.016.051.02.031.056l-1.09 2.948c.003.011.009.022.017.03l1.304 2.483c.014.027-.007.069-.039.067l-2.642.046-.03.017-1.805 2.501a.032.032 0 0 1-.013.02.031.031 0 0 1-.023.006.03.03 0 0 1-.025-.036l-.545-2.917a.047.047 0 0 0-.017-.03l-2.408-.92a.043.043 0 0 1-.021-.034.044.044 0 0 1 .016-.038l2.308-1.847a.062.062 0 0 0 .03-.018l.305-3.078c-.021-.048.013-.068.035-.045ZM46.852 16.337l-1.724.894a.02.02 0 0 0 .002.014l-.076 1.808c.003.02-.028.029-.047.01l-1.66-1.247a.071.071 0 0 0-.025.005l-2.013.333c-.021.004-.044-.018-.033-.037l.698-1.652-.003-.022-1.166-1.604a.019.019 0 0 1-.01-.012.02.02 0 0 1 .003-.016.025.025 0 0 1 .014-.01.024.024 0 0 1 .016.002l2.092.222a.04.04 0 0 0 .025-.004l1.277-1.32a.036.036 0 0 1 .029-.007.03.03 0 0 1 .013.006.024.024 0 0 1 .007.011l.593 1.791a.036.036 0 0 0 .003.022l1.965.783c.037-.004.04.02.02.03Z"
                    fill="#1BB16B"
                    stroke="#1BB16B"
                    stroke-width="0.419"
                    stroke-linejoin="bevel"
                  ></path>
                  <path
                    d="m25.134 31.428 1.838-.631a.025.025 0 0 0 0-.016l.43-1.956c0-.022.032-.026.046 0l1.356 1.686a.063.063 0 0 0 .025 0l2.005.036c.022 0 .039.028.025.047l-.998 1.662-.001.025.808 1.98c.004.003.007.008.007.014a.024.024 0 0 1-.021.024.02.02 0 0 1-.016-.004l-1.973-.658a.034.034 0 0 0-.024 0l-1.49 1.187a.032.032 0 0 1-.03.001.029.029 0 0 1-.016-.023l-.219-2.07a.044.044 0 0 0 .002-.024l-1.74-1.242c-.037-.003-.036-.031-.014-.038Z"
                    fill="#1BB16B"
                  ></path>
                  <path
                    d="m27.246 15.22.864-1.707a.02.02 0 0 0-.01-.01l-1.046-1.468c-.015-.014.005-.038.033-.034l2.122.014a.07.07 0 0 0 .017-.018l1.443-1.433c.015-.016.047-.012.05.01l.44 1.707.016.015 1.937.585a.02.02 0 0 1 .016.003.019.019 0 0 1 .007.014.024.024 0 0 1-.02.025l-1.848 1.042a.038.038 0 0 0-.018.018l-.236 1.783a.035.035 0 0 1-.02.022.03.03 0 0 1-.014.002.027.027 0 0 1-.013-.004l-1.582-1.066a.035.035 0 0 0-.016-.015l-2.087.527c-.028.025-.047.008-.035-.012Z"
                    fill="#1BB16B"
                    stroke="#1BB16B"
                    stroke-width="0.419"
                    stroke-linejoin="bevel"
                  ></path>
                </svg>
              </div> */}
              <h1 className="connects">{user.connects}</h1>
              <div className="text">
                <span> You have connects</span>
                <p>Running low? Get connects</p>
              </div>
              <div className="go">
                <FaAngleRight />
              </div>
            </div>
          </div>
        </a>
        <a onClick={() => setShowFeedback(true)} className="briefArea">
          <div className="brief feedback">
            <div className="padding">
              <div className="icon sicon">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 64 66"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                >
                  <path
                    d="M29.772 41.089a12.416 12.416 0 0 0-12.064 1.912 12.334 12.334 0 0 0-3.766 5.053 12.288 12.288 0 0 0-.772 6.247c.119-.07 11.228-8.714 16.602-13.212ZM21.941 64.496a12.425 12.425 0 0 0 10.884-1.928 12.348 12.348 0 0 0 3.661-4.325 12.3 12.3 0 0 0 1.339-5.498c-7.663 5.922-15.806 11.7-15.884 11.751Z"
                    fill="#1DBF73"
                    stroke="#1DBF73"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  ></path>
                  <path
                    d="m54.26 34.066 2.72-1.587-3.156-5.384a3.302 3.302 0 0 0 1.197-4.53 3.334 3.334 0 0 0-4.546-1.192l-3.16-5.392-5.088 2.967a3.305 3.305 0 0 1-1.196 4.53 3.33 3.33 0 0 1-4.546-1.193l-4.816 2.8 4.66 7.958"
                    stroke="#222325"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  ></path>
                  <path
                    d="M47.156 35.718c2.396-1.854 4.704-5.192 6.129-5.533 1.425-.341 1.418 1.617-.744 4.498-2.163 2.882-7.313 7.732-9.077 9.1"
                    stroke="#222325"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  ></path>
                  <path
                    d="M46.095 41.494c2.832-1.921 6.84-7.465 7.782-7.58.941-.115 1.187 2.318-3.625 8.121C45.44 47.84 21.942 64.5 21.942 64.5a12.405 12.405 0 0 1-5.894-3.8c-1.556-1.806-2.675-3.66-2.879-6.38 0 0 18.303-14.232 19.398-15.748 1.094-1.517 5.56-8.363 6.524-9.293.89-.857 2.4-.887 1.782 1.735-.617 2.622-2.676 6.935-2.676 6.935l.049.063s4.242-2.882 6.594-4.888c1.712-1.453 3.793-4.298 5.095-3.927 1.028.293.935 2.477-2.46 6.174a41.664 41.664 0 0 1-6.624 5.87"
                    stroke="#222325"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  ></path>
                  <path
                    d="m22.939 46.617 7.793 11.496-8.79 6.393s-4.02-.23-6.939-4.746c-.973-1.798-1.938-3.98-1.687-5.296l9.623-7.847Z"
                    fill="#222325"
                    stroke="#222325"
                    stroke-width="1.5"
                  ></path>
                  <path
                    d="m31.416 7.729.701.267.267-.701-.7-.267-.268.7ZM13.75 1l.267-.7-.7-.268-.268.701.701.267Zm15.526 12.351-.701-.267-.265.694.692.271.274-.698Zm-2.446 6.422.26-.704-.696-.258-.265.695.701.267Zm-2.142 5.623-.267.7.701.268.267-.701-.7-.267ZM19.29 23.34l-.701-.267-.267.7.7.268.268-.701Zm-6.87-2.617-.268.7.701.268.267-.701-.7-.267ZM7.02 18.667l-.7-.267-.267.7.7.268.267-.701Zm24.663-11.64L14.016.3l-.534 1.402L31.15 8.43l.534-1.402Zm-1.707 6.591 2.141-5.622-1.402-.534-2.141 5.622 1.402.534Zm1.956 4.422a4.186 4.186 0 0 0-2.384-5.387l-.547 1.396a2.686 2.686 0 0 1 1.53 3.457l1.401.534Zm-5.363 2.436a4.186 4.186 0 0 0 5.363-2.436l-1.402-.534a2.686 2.686 0 0 1-3.44 1.563l-.521 1.407Zm-1.18 5.187 2.14-5.623-1.4-.534-2.143 5.623 1.402.534Zm-6.367-1.622 5.398 2.056.534-1.402-5.398-2.056-.534 1.402Zm-2.127-4.744a2.926 2.926 0 0 1 1.693 3.776l1.402.534a4.426 4.426 0 0 0-2.561-5.712l-.534 1.402Zm-3.776 1.693a2.926 2.926 0 0 1 3.776-1.693l.534-1.402a4.426 4.426 0 0 0-5.711 2.56l1.401.535Zm-6.365-1.622 5.397 2.056.534-1.402-5.398-2.056-.533 1.402ZM13.048.733 6.32 18.4l1.401.534 6.73-17.667-1.403-.534Z"
                    fill="#222325"
                  ></path>
                </svg>
              </div>
              <div className="text">
                <span>Share your feedback</span>
                <p>Help us improve by sharing</p>
              </div>
              <div className="go">
                <FaAngleRight />
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className="suggestionsArea">
        <div className="label">
          <div className="titl">
            <h2 className="tradeslabel">
              {suggestionLabel ? "Try these sites about" : "Try these sites"}
            </h2>
            <h2 className="topic">{suggestionLabel}</h2>
          </div>
          {!suggestionLabel && (
            <span className="add">
              Add <a href="/mysite/settings">tags</a> to get personalized
              suggestions
            </span>
          )}
        </div>
        <div className="postHolder">
          {getRandomElements(posts, 5).map((p, index) => (
            <Post postId={index} info={p}></Post>
          ))}
        </div>
      </div>

      <div className="suggestionsArea">
        <div className="label">
          <h2 className="tradeslabel">{"Try something new"}</h2>
          {/* <h2 className="topic">{suggestionLabel}</h2> */}
        </div>
        <div className="postHolder">
          {getRandomElements(data.posts, 5).map((p, index) => (
            <Post postId={index} info={p}></Post>
          ))}
        </div>
      </div>
      {/* <div className="suggestionsArea">
        <div className="label">
          <h2 className="tradeslabel">Trades for you in</h2>
          <h2 className="topic">Bitcoin</h2>
        </div>
        <div className="postHolder">
          {posts.map((p, index) => (
            <Post
              postId={index}
              type={p.type}
              score={p.score}
              link={p.link}
              description={p.description}
              logo={p.logo}
              pointer={false}
            ></Post>
          ))}f
        </div>
      </div> */}
      <Footer></Footer>
    </Wrapper>
  );
}

export const marketLoader = async () => {
  return { posts: await getWebsites(), userInfo: await getUser() };
};
export default Market;
